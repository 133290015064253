// Auth Actions

export const AUTH_RESET = "AUTH_RESET"
export const AUTH_START = "AUTH_START"
export const AUTH_CHALLENGE = "AUTH_CHALLENGE"
export const AUTH_CHALLENGE_START = "AUTH_CHALLENGE_START"
export const AUTH_CHALLENGE_FAIL = "AUTH_CHALLENGE_FAIL"
export const AUTH_SUCCESS = "AUTH_SUCCESS"
export const AUTH_FAIL = "AUTH_FAIL"
export const AUTH_END = "AUTH_END"
export const REGISTRATION_START = "REGISTRATION_START"
export const REGISTRATION_SUCCESS = "REGISTRATION_SUCCESS"
export const REGISTRATION_FAIL = "REGISTRATION_FAIL"

// Layout actions

export const LOADING_ON = "LOADING_ON"
export const LOADING_OFF = "LOADING_OFF"
export const LOGIN_MODAL_VISIBILITY = "LOGIN_MODAL_VISIBILITY"
export const NOTIFICATION_ADD = "NOTIFICATION_ADD"
export const NOTIFICATION_EMPTY = "NOTIFICATION_EMPTY"

// Car Actions
export const SET_SELECTED_BRANDS = "SET_SELECTED_BRANDS"
export const SET_SELECTED_SEGMENTS = "SET_SELECTED_SEGMENTS"
export const SET_SELECTED_TECHNOLOGIES = "SET_SELECTED_TECHNOLOGIES"
export const SET_SELECTED_PRICE_RANGE = "SET_SELECTED_PRICE_RANGE"
export const SET_SELECTED_PRICE_RANGE_MAX = 100000
