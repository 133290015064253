import React from "react"
import { TitleData } from "../../rows/DynamicRow"
import Button from "./Button"
import Icon from "./Icon"

type Props = {
  type?: "h1" | "h2" | "h3" | "h4"
  data?: TitleData
  forceColor?: string
  children?
}

export const Title: React.FC<Props> = ({ type, data, children, forceColor }) => {
  let theTitle = children
  let subTitle = null
  let superTitle = null
  let containerClassName = "heading__container heading__container--left mb-xs"
  let textAlign: "left" | "center" | "right" = "left"
  let link = null

  if (data) {
    if (!data.title) return null
    type = data.type
    theTitle = data.title

    containerClassName = `heading__container heading__container--${data.align} mb-xs`
    textAlign = data.align

    subTitle = data.subTitle.title ? (
      <p className="heading-subtitle mb-none mt-xs" style={{ color: data.subTitle.color }}>
        {data.subTitle.title}
      </p>
    ) : null

    superTitle = data.superTitle.title ? (
      <p className="heading-supertitle mb-xs mt-none" style={{ color: data.superTitle.color }}>
        {data.superTitle.title == "logo:academy" ? (
          <Icon source="ecoverso.logos" name="ecoverso-academy" className="navsub__logo" svg style={{ fill: "#c712ad" }} />
        ) : data.superTitle.title == "logo:r-ace" ? (
          <Icon source="ecoverso.logos" name="ecoverso-race" className="navsub__logo" svg style={{ fill: "#00af56" }} />
        ) : data.superTitle.title == "logo:plus" ? (
          <Icon source="ecoverso.logos" name="ecoverso-plus" className="navsub__logo" svg style={{ fill: "#f4144e" }} />
        ) : (
          data.superTitle.title
        )}
      </p>
    ) : null

    link = data.link?.url ? <Button to="" data={{ link: data.link, type: "ghost" }} /> : null
    if (textAlign == "center") link = null
  }

  let title = null
  switch (type || "h1") {
    case "h1":
      title = <h1 className="heading heading-title mb-none" dangerouslySetInnerHTML={{ __html: theTitle }} style={forceColor ? { color: forceColor } : {}}></h1>
      break
    case "h2":
      title = <h2 className="heading heading-title mb-none" dangerouslySetInnerHTML={{ __html: theTitle }} style={forceColor ? { color: forceColor } : {}}></h2>
      break
    case "h3":
      title = <h3 className="heading heading-title mb-none" dangerouslySetInnerHTML={{ __html: theTitle }} style={forceColor ? { color: forceColor } : {}}></h3>
      break
    case "h4":
      title = <h4 className="heading heading-title mb-none" dangerouslySetInnerHTML={{ __html: theTitle }} style={forceColor ? { color: forceColor } : {}}></h4>
      break
  }

  return (
    <div className={containerClassName}>
      {textAlign == "right" ? (
        <React.Fragment>
          {link}
          <div style={{ textAlign }}>
            {superTitle}
            {title}
            {subTitle}
          </div>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <div style={{ textAlign }}>
            {superTitle}
            {title}
            {subTitle}
          </div>
          {link}
        </React.Fragment>
      )}
    </div>
  )
}

export default Title
