import { library } from "@fortawesome/fontawesome-svg-core"
import {} from "@fortawesome/free-brands-svg-icons"
import {
  faGraduationCap as fasGraduationCap,
  faCar as fasCar,
  faTrophy as fasTrophy,
  faDoNotEnter as fasDoNotEnter,
  faEngineWarning as fasEngineWarning,
  faCheckCircle as fasCheckCircle,
  faEuroSign as fasEuroSign,
} from "@fortawesome/pro-solid-svg-icons"
import {
  faInfoCircle as farInfoCircle,
  faQuestionCircle as farQuestionCircle,
  faClock as farClock,
  faCircle as farCircle,
  faCheckCircle as farCheckCircle,
  faEdit as farEdit,
} from "@fortawesome/pro-regular-svg-icons"
import {
  faInfoCircle as falInfoCircle,
  faCar as falCar,
  faPortalEnter as falPortalEnter,
  faSignInAlt as falSignInAlt,
  faHandPointDown as falHandPointDown,
  faHandPointRight as falHandPointRight,
  faHandPointLeft as falHandPointLeft,
} from "@fortawesome/pro-light-svg-icons"
import { faCars as fadCars, faHourglassHalf as fadHourglassHalf } from "@fortawesome/pro-duotone-svg-icons"

library.add(
  farInfoCircle,
  falInfoCircle,
  farQuestionCircle,
  fasGraduationCap,
  fasCar,
  falCar,
  fasTrophy,
  falPortalEnter,
  fasDoNotEnter,
  fasEngineWarning,
  fadCars,
  fasCheckCircle,
  fadHourglassHalf,
  farClock,
  fasEuroSign,
  falSignInAlt,
  falHandPointDown,
  falHandPointRight,
  falHandPointLeft,
  farCircle,
  farCheckCircle,
  farEdit
)
