import { identity } from "lodash"
import React, { useEffect, useState } from "react"

import awsconfig from "../../aws-exports"
import imageExist from "image-exists"

type Props = {
  className?: string
  identityId?: string
  url?: string
  alt?: string
}

export const Avatar: React.FC<Props> = ({ className, identityId, url, alt }) => {
  const avatarURL =
    url ||
    (identityId
      ? `https://${awsconfig.aws_user_files_s3_bucket}.s3-${awsconfig.aws_user_files_s3_bucket_region}.amazonaws.com/protected/${identityId}/avatar.small.jpg`
      : "")

  const [exists, setExists] = useState(false)

  useEffect(() => {
    if (avatarURL.indexOf("amazonaws") != -1) {
      imageExist(avatarURL, (itExists) => {
        setExists(itExists)
      })
    }
  })

  return exists ? <img src={avatarURL} className={className} alt={alt} /> : null
}

export default Avatar
