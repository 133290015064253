import React, { useState } from "react"
// import { Select, SelectOption, SelectGroup } from "@patternfly/react-core";
import { Select } from "antd"
const { Option, OptGroup } = Select

type tOption = { value: string | number; title?: string; disabled?: boolean; className?: string }
type tOptionGroup = { label: string; options: tOptions }
type tOptions = Array<tOption | tOptionGroup | string | number>

const MySelect: React.FC<{
  name: string
  onBlur: any
  onChange: any
  value: number | string | Array<number | string>
  mode?: "multiple" | "tags"
  disabled?: boolean
  isGrouped?: boolean
  placeholder?: String
  options: tOptions
}> = props => {
  // ------------------------------------------------------------------------------
  // Initialization

  props = {
    disabled: false,
    placeholder: "",
    isGrouped: false,
    options: [],
    ...props,
  }
  //console.log("Select Props: ", props);

  let emptySelected: undefined | [] = undefined
  switch (props.mode) {
    case "multiple":
    case "tags":
      emptySelected = []
      break
    default:
      emptySelected = undefined
      break
  }

  // ------------------------------------------------------------------------------

  const [selected, setSelected] = useState(props.value || emptySelected)

  const onChange = val => {
    setSelected(val)
    props.onChange({ target: { name: props.name, value: val } })
  }

  const mapOptions = (options: tOptions, prefix?: string) => {
    return options.map((option, index) => {
      if ((option as tOptionGroup).label !== undefined) {
        return (
          <OptGroup label={(option as tOptionGroup).label} key={"G" + index}>
            {mapOptions((option as tOptionGroup).options, "G" + index)}
          </OptGroup>
        )
      } else {
        return (
          <Option
            disabled={(option as tOption).disabled}
            key={prefix != undefined ? prefix + index : index}
            value={(option as tOption).value || option}
            className={(option as tOption).className}
          >
            {(option as tOption).title || (option as tOption).value || option}
          </Option>
        )
      }
    })
  }

  return (
    <Select
      allowClear
      onChange={onChange}
      showSearch
      value={selected}
      disabled={props.disabled}
      placeholder={props.placeholder}
      mode={props.mode}
    >
      {mapOptions(props.options)}
    </Select>
  )
}

export default MySelect
