import * as actionTypes from "./actionTypes"
import { BrandData, SegmentsData, TechnologyData } from "../../pages/auto"

// Synchronous Action Creators

export const setSelectedBrands = (selectedBrands: BrandData[]) => ({
  type: actionTypes.SET_SELECTED_BRANDS,
  selectedBrands,
})

export const setSelectedSegments = (selectedSegments: SegmentsData[]) => ({
  type: actionTypes.SET_SELECTED_SEGMENTS,
  selectedSegments,
})

export const setSelectedTechnologies = (selectedTechnologies: TechnologyData[]) => ({
  type: actionTypes.SET_SELECTED_TECHNOLOGIES,
  selectedTechnologies,
})
export const setSelectedPriceRange = (selectedPriceRange: [number, number]) => ({
  type: actionTypes.SET_SELECTED_PRICE_RANGE,
  selectedPriceRange,
})
