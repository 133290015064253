import React from "react"
import Flickity from "react-flickity-component"

export const Carousel = (props: {
  title?: string
  subtitle?: string
  more?: React.ReactNode
  withBackground?: boolean
  initialIndex?: number
  className?: string
  children: any
}) => {
  props = {
    withBackground: false,
    initialIndex: Math.min(1, props.children.length - 1),
    ...props,
  }

  // Options documentation: https://flickity.metafizzy.co/options.html
  const flickityOptions = {
    initialIndex: props.initialIndex,
    cellAlign: "center",
    //arrowShape: "M 5,50 L 25,25 L 90,25 L 90,75 L 25,75 Z",
    arrowShape: "M 61,25 L 65,25 L 35,50 L 65,75 L 61,75 L 31,50 Z",
    pageDots: false,
    // wrapAround: true,
  }

  return (
    <div className={"row row--full " + props.className}>
      {props.title ? (
        <div className="row row--center mb-none pb-s row--flex">
          <h2 className="heading heading-title mb-none">{props.title}</h2>
          {props.more}
        </div>
      ) : null}
      {props.subtitle ? (
        <div className="row row--center row--no-grid mb-none mt-none pt-none pb-m">
          <p className="mb-none">{props.subtitle}</p>
        </div>
      ) : null}
      <div className="row row--full row--inner row--no-grid row--no-overflow pb-xxxl">
        <Flickity
          className={"carousel-ecoverso"} // default ''
          elementType={"div"} // default 'div'
          options={flickityOptions} // takes flickity options {}
          disableImagesLoaded={false} // default false
          reloadOnUpdate // default false
          static // default false
        >
          {props.children}
        </Flickity>
      </div>
    </div>
  )
}

export default Carousel
