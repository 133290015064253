import React, { useState } from "react"

import { Link, LinkProps } from "../../i18n"
import { ButtonData } from "../../rows/DynamicRow"
import { Icon } from "."

type Props = LinkProps & {
  type?: "primary" | "secondary" | "ghost" | "white"
  title?: string
  data?: ButtonData
  external?: Boolean
  disabled?: Boolean
}

export const Button: React.FC<Props> = ({ type, title, data, className, external, disabled, ...linkProps }) => {
  // if to={null} force rendering without link
  const renderWithoutLink = linkProps.to == null

  if (data) {
    if (data.link == null) return null
    type = data.type
    title = data.link.title
    linkProps.to = data.link.url
    linkProps.target = data.link.target
  }

  // Save final link in a state so we can react on interpreted version from <Link>
  const [finalLink, setFinalLink] = useState(linkProps.to)

  const newClassName = className + " btn btn--" + (type || "primary") + (disabled ? " btn-" + (type || "primary") + "--disabled" : "")

  if (renderWithoutLink) {
    return (
      <div className={newClassName} onClick={linkProps.onClick as any}>
        {title}
        {external ? <Icon name="external-link" size="s" className="btn__icon" /> : null}
      </div>
    )
  }

  return (
    <Link {...linkProps} className={newClassName} finalLinkUpdateCallback={setFinalLink}>
      {title}
      {finalLink.indexOf("http") != -1 ? <Icon name="external-link" size="s" className="btn__icon" /> : null}
    </Link>
  )
}

export default Button
