import React, { createContext, useState } from "react"
import { Formik, Form, Field } from "formik"
import { buildYup } from "schema-to-yup"

import { ConfigProvider, Row, Col, Spin, Form as AForm, Button, Input, Radio, Checkbox, Tooltip } from "antd"
import { InfoCircleOutlined, UserOutlined } from "@ant-design/icons"
import { Loading } from "../../Layout/Components"
import Select from "./fields/Select"
import Dropzone from "react-dropzone"

import { Progress } from "antd"

import { registerLocale, setDefaultLocale } from "react-datepicker"
import it from "date-fns/locale/it"
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import { resolve, handleUploadsAndSubstituteToURLs } from "./utils"

import { addDays, parseISO, formatISO } from "date-fns"
import countries from "./data/countries"
import { useTranslation } from "../../i18n"
import { TFunction } from "i18next"

import _ from "lodash"
import { FormPropertiesData } from "./FormDataType"

registerLocale("it", it)
setDefaultLocale("it")

const FormGenerator: React.FC<{
  properties?: FormPropertiesData
  values?: any
  onChange?: Function
  isHorizontal?: boolean
  size?: "small" | "large"
  isDemo?: boolean
  debug?: boolean
  translate?: boolean
  submitButtonText?: string
  hideSubmitButton?: boolean
  onSubmit?: Function
  cancelButtonText?: string
  hideCancelButton?: boolean
  onCancel?: Function
  bindSubmitForm?: Function
  bindSetValues?: Function
  bindResertForm?: Function
  className?: string
  style?: any
  handleFileUploadWithAmplify?: boolean
  disabled?: boolean
}> = (props) => {
  props = {
    size: "large",
    submitButtonText: "Submit",
    hideSubmitButton: false,
    cancelButtonText: "Cancel",
    hideCancelButton: false,
    debug: false,
    translate: true,
    handleFileUploadWithAmplify: true,
    disabled: false,
    ...props,
  }

  // Setup translation
  const [translator] = useTranslation()
  const t = (toBeTranslated) => {
    return props.translate ? translator(toBeTranslated) : toBeTranslated
  }

  // This is "mostly" a JSON/Yup Schema + custom types/fields
  // Referencese
  //    - https://github.com/kristianmandrup/schema-to-yup
  //    - https://json-schema.org/understanding-json-schema/
  //    - https://reactdatepicker.com/
  //
  // Supported types (json/yup schema, more than official json schema):
  //    - array, boolean, date, number, object, string
  //
  // Custom types handle here and stripped befor processing
  //    - row: to grup fields on the same row (like object, but does not create object, flattened in conversion)
  //    - fieldType: "select" - for array, number, string
  //       - variant:
  //         'single' | 'typeahead' for number/string
  //         'checkbox'  | 'typeaheadmulti' for array
  //    - date/datetime: same as date (both considered string from a validation point)
  //
  //    fieldProperties: this object will be expanded as properties in the fields

  // ------------------------------------------------------------------------------------------------
  // Example
  const JSDemo: FormPropertiesData = {
    username: {
      type: "string",
      title: "Username",
      description: "Insert your chosen username",
      extra: "Extra description that stays there",
      fieldProperties: {
        placeholder: "username...",
        addonBefore: "Before",
        addonAfter: "After",
        prefix: <UserOutlined />,
        suffix: (
          <Tooltip title="Extra information">
            <InfoCircleOutlined style={{ color: "rgba(0,0,0,.45)" }} />
          </Tooltip>
        ),
      },
      pattern: "^[a-zA-Z].*$",
      required: true,
      errMessages: {
        required: "The username is required",
        pattern: "Should stard with a letter.",
      },
    },
    age: {
      title: "Age",
      type: "number",
      pattern: "[0-9]*",
      //required: true
      errMessages: {
        number: "Must be a number",
        pattern: "Must be a number",
      },
    },
    upload: {
      type: "file",
      title: "Carica qui il preventivo di un concessionario della vettura che desideri, e che eventualmente attesti il valore del tuo usato",
      description:
        "Se non hai un preventivo scritto e proprio non riesci a fartelo fare nei prossimi giorni, puoi caricare il pdf ottenibile dal configuratore del sito internet della casa costruttrice.",
      dropZoneProperties: {
        multiple: false,
        maxSize: 2048000,
      },
    },
    gender: {
      title: "Gender",
      type: "string",
      fieldType: "select",
      fieldProperties: {
        placeholder: "Select...",
        options: [{ label: "known genders", options: [{ value: "Male" }, { value: "Female" }] }, { value: "unknown" }],
      },
      // default: "Male",
      required: true,
    },
    interestedIn: {
      title: "Interested in...",
      type: "string",
      fieldType: "radio",
      inline: true,
      options: [{ value: "Male" }, { value: "Female" }],
      required: true,
    },
    country: {
      title: "Country",
      description: "Select the country you reside in",
      type: "string",
      fieldType: "select",
      fieldProperties: {
        variant: "typeahead",
        placeholderText: "Select...",
        options: countries.map((c) => ({ value: c.code, title: c.emoji + " " + c.name })),
      },
      default: "Italy",
      required: true,
      nullable: true,
      errMessages: {
        required: "E' necessario specificare una nazione",
      },
    },
    favoriteCountries: {
      title: "Favorite countries",
      description: "Select your favourite countries",
      type: "array",
      items: {
        type: "string",
      },
      fieldType: "select",
      fieldProperties: {
        mode: "tags",
        placeholder: "Select...",
        options: countries.map((c) => ({ value: c.code, title: c.emoji + " " + c.name })),
      },
      // default: ["Italy"],
      required: true,
    },
    visitedCountries: {
      title: "Visited countries",
      description: "Select your visited countries",
      type: "array",
      items: {
        type: "string",
      },
      fieldType: "checkbox",
      options: countries.slice(0, 7).map((c) => ({ value: c.code, title: c.emoji + " " + c.name })),
      default: ["Andorra"],
      nullable: true,
      required: true,
      minItems: 3,
    },
    foodChoices: {
      title: "Food choices",
      type: "array",
      items: {
        type: "string",
      },
      fieldType: "checkbox",
      inline: true,
      options: ["Vegetarian", "Omnivore", "Vegan", "Fructarian"],
      nullable: true,
      required: true,
      default: ["Omnivore"],
      min: 2,
      errMessages: {
        min: "Min 2 choices",
      },
    },
    row0: {
      type: "row",
      properties: {
        birthdate: {
          title: "Birth date",
          description: "Your birth date",
          type: "date",
          required: true,
          nullable: true,
          //default: new Date(),
          fieldProperties: {
            dateFormat: "dd/MM/yyyy",
            maxDate: addDays(new Date(), -1),
          },
        },
        partyDateTime: {
          title: "Party date & time",
          type: "datetime",
          required: false,
          nullable: true,
          //default: new Date(),
          fieldProperties: {
            showTimeSelect: true,
            timeFormat: "p",
            timeIntervals: 15,
            dateFormat: "Pp",
          },
        },
      },
    },
    row1: {
      type: "row",
      properties: {
        firstName: {
          title: "First Name",
          description: "Your name",
          type: "string",
          default: "John",
          required: true,
        },
        lastName: {
          title: "Last Name",
          description: "Your surname",
          type: "string",
          default: "Doe (disabled)",
          disabled: true,
        },
      },
    },
    contacts: {
      type: "object",
      properties: {
        phone: {
          title: "Phone",
          type: "string",
          default: "+39 (disabled)",
          disabled: true,
        },
      },
    },
    rowConfirmEmail: {
      type: "row",
      properties: {
        email: {
          title: "Email",
          type: "string",
          format: "email",
          fieldProperties: {
            placeholder: "✉️ Insert email...",
          },
          required: true,
          errMessages: {
            required: "The email is required",
          },
        },
        emailConfirm: {
          title: "Confirm Email",
          type: "string",
          format: "email",
          fieldProperties: {
            placeholder: "✉️ Confirm email...",
          },
          required: true,
          errMessages: {
            required: "The email confirmation is required",
          },
          customValidators: [
            {
              type: "shouldBeEqual",
              field1: "email",
              field2: "emailConfirm",
              errMessage: "The 2 email addresses should be equal",
            },
          ],
        },
      },
    },
    html: {
      type: "html",
      html: "<h4>Test</h4><hr/>",
    },
    notes: {
      title: "Notes",
      type: "string",
      fieldType: "textarea",
      required: true,
    },
    privacy: {
      title: "Privacy no bottom margin with class",
      className: "mb-none",
      type: "boolean",
      oneOf: [true],
      required: true,
      errMessages: {
        oneOf: "E' necessario accettare la privacy",
      },
      default: true,
    },
    privacy2: {
      title: "Privacy",
      description: "Spuntare per accettare la privacy",
      type: "boolean",
      oneOf: [true],
      required: true,
      errMessages: {
        oneOf: "E' necessario accettare la privacy",
      },
      default: true,
    },
  }
  // ------------------------------------------------------------------------------------------------

  const JS = {
    title: "Form",
    type: "object",
    properties: props.isDemo ? JSDemo : props.translate ? translateSchema(props.properties, translator) : props.properties,
  }

  const defaultValues = extractDefaultValues(JS)
  let { schema, config, customValidators } = convertToYupJsonSchema(JS)
  //console.log("Converted: ", schema, config)
  //console.log("Build Yup: ", buildYup(schema, config))
  //console.log("Default Values: ", defaultValues)
  //console.log("Prop Values: ", props.values)
  const initialValues = { ...defaultValues, ...props.values }
  const [storedValues, setStoredValues] = useState({} as any)
  const [uploadProgress, setUploadProgress] = useState(0)
  const [isCustomSubmitting, setCustomSubmitting] = useState(false)

  const layout = props.isHorizontal
    ? {
        rowLayout: {
          labelCol: { span: 5 },
          wrapperCol: { span: 19 },
        },
        controlsLayout: {
          wrapperCol: { offset: 5, span: 19 },
          style: { marginTop: 15 },
        },
        rowGutter: [15, 10],
      }
    : {
        rowLayout: {
          labelCol: { span: 24 },
          wrapperCol: { span: 24 },
        },
        controlsLayout: {
          wrapperCol: { offset: 0, span: 24 },
          style: { marginTop: 15 },
        },
        rowGutter: [15, 0],
      }

  return (
    <React.Fragment>
      <ConfigProvider componentSize={props.size}>
        <Formik
          initialValues={initialValues}
          validate={(values) => {
            const errors = {}
            customValidators.forEach((validator) => {
              switch (validator.type) {
                case "shouldBeEqual":
                  if (resolve(validator.field1, values) !== resolve(validator.field2, values)) {
                    errors[validator.field2.split(".").slice(-1)[0]] = validator.errMessage
                  }
                  break
                case "function":
                  if (!validator.function(resolve(validator.fieldName, values))) {
                    errors[validator.fieldName] = validator.errMessage
                  }
                  break
              }
            })
            if (props.onChange) {
              // if props.onChange is set send updates only when values change
              if (!_.isEqual(values, storedValues)) {
                setStoredValues(values)
                props.onChange(values)
              }
            }
            return errors
          }}
          validationSchema={buildYup(schema, config)}
          onSubmit={async (values, { setSubmitting }) => {
            setCustomSubmitting(true)
            if (props.handleFileUploadWithAmplify) {
              values = await handleUploadsAndSubstituteToURLs(values, setUploadProgress)
            }
            if (props.onSubmit) await props.onSubmit(values, setCustomSubmitting)
          }}
        >
          {(formProps) => {
            // Bind "SubmitForm" to parent
            if (props.bindSubmitForm) props.bindSubmitForm(formProps.submitForm)
            if (props.bindSetValues) props.bindSetValues(formProps.setValues)
            if (props.bindResertForm) props.bindResertForm(formProps.handleReset)

            return (
              <Form
                className={(props.isHorizontal ? "ant-form ant-form-horizontal" : "ant-form") + (props.className ? " " + props.className : "")}
                style={props.style}
              >
                <Row gutter={layout.rowGutter}>
                  {generateFields(JS.properties, layout.rowLayout, props)}
                  {!props.hideSubmitButton || !props.hideCancelButton || props.debug ? (
                    <Col span={24}>
                      <AForm.Item {...layout.controlsLayout}>
                        {props.hideSubmitButton ? null : (
                          <Button type="primary" htmlType="submit" disabled={isCustomSubmitting || props.disabled} style={{ marginRight: 10 }}>
                            {t(props.submitButtonText)}
                            {isCustomSubmitting ? <Loading size="small" /> : null}
                          </Button>
                        )}
                        {props.hideCancelButton ? null : (
                          <Button htmlType="button" disabled={isCustomSubmitting || props.disabled} onClick={() => props.onCancel()}>
                            {t(props.cancelButtonText)}
                          </Button>
                        )}
                        {props.debug ? (
                          <Button
                            type="link"
                            onClick={() => {
                              console.log(formProps.values)
                            }}
                          >
                            Values to console
                          </Button>
                        ) : null}
                      </AForm.Item>
                      {uploadProgress != 0 && uploadProgress != 100 ? <Progress percent={uploadProgress} status="active" /> : null}
                    </Col>
                  ) : null}
                  {/* <div style={{ marginTop: 10 }}>{JSON.stringify(formProps, null, 2)}</div> */}
                </Row>
              </Form>
            )
          }}
        </Formik>
        {props.debug ? (
          <pre style={{ marginTop: 10, borderWidth: 1, padding: 10, display: "block" }}>
            {JSON.stringify(schema, null, 2)}
            {JSON.stringify(config, null, 2)}
            Default Values: {JSON.stringify(defaultValues, null, 2)}
            Input Values: {JSON.stringify(props.values, null, 2)}
            Initial Values: {JSON.stringify(initialValues, null, 2)}
            {/* {JSON.stringify(customValidators, null, 2)} */}
          </pre>
        ) : null}
      </ConfigProvider>
    </React.Fragment>
  )
}

const generateFields = (FieldsList, layout, formProps: any, span: number = 24, keyPrefix = "") => {
  return Object.keys(FieldsList).map((key) => {
    let toBeReturned
    switch (FieldsList[key].type) {
      case "number":
      case "string":
        // Render simple string fields or a select
        toBeReturned = (
          <Field name={keyPrefix + key} key={key}>
            {(fieldProps) => {
              return (
                <AForm.Item
                  {...layout}
                  className={FieldsList[key].className}
                  label={FieldsList[key].title}
                  name={keyPrefix + key}
                  required={FieldsList[key].required}
                  validateStatus={(fieldProps.form.submitCount > 0 || fieldProps.meta.touched) && fieldProps.meta.error ? "error" : null}
                  help={
                    (fieldProps.form.submitCount > 0 || fieldProps.meta.touched) && fieldProps.meta.error
                      ? fieldProps.meta.error || FieldsList[key].description
                      : FieldsList[key].description
                  }
                  extra={FieldsList[key].extra}
                >
                  <>
                    {FieldsList[key].fieldType === undefined ? (
                      <Input
                        {...fieldProps.field}
                        value={fieldProps.field.value || ""}
                        disabled={FieldsList[key].disabled || formProps.disabled}
                        {...FieldsList[key].fieldProperties}
                        onChange={(e) => {
                          fieldProps.field.onChange({
                            target: { name: keyPrefix + key, value: FieldsList[key].onChange ? FieldsList[key].onChange(e.target.value) : e.target.value },
                          })
                        }}
                      />
                    ) : null}
                    {FieldsList[key].fieldType === "select" ? (
                      <Select {...fieldProps.field} {...FieldsList[key].fieldProperties} disabled={FieldsList[key].disabled || formProps.disabled}></Select>
                    ) : null}
                    {FieldsList[key].fieldType === "radio" ? (
                      <Radio.Group
                        onChange={(e) => {
                          fieldProps.field.onChange({ target: { name: keyPrefix + key, value: e.target.value } })
                        }}
                        disabled={FieldsList[key].disabled || formProps.disabled}
                        value={fieldProps.field.value}
                      >
                        {FieldsList[key].options.map((opt) => (
                          <Radio
                            key={key + "-" + (opt.value || opt)}
                            value={opt.value}
                            style={FieldsList[key].inline ? {} : { display: "block", height: "30px", lineHeight: "30px" }}
                          >
                            {opt.title || opt.value || opt}
                          </Radio>
                        ))}
                      </Radio.Group>
                    ) : null}
                    {FieldsList[key].fieldType === "textarea" ? (
                      <Input.TextArea
                        {...fieldProps.field}
                        onChange={(e) => fieldProps.field.onChange(e)}
                        disabled={FieldsList[key].disabled || formProps.disabled}
                        allowClear
                        {...FieldsList[key].fieldProperties}
                      ></Input.TextArea>
                    ) : null}
                    {/* <div style={{ marginTop: 10, fontSize: 9 }}>{JSON.stringify(fieldProps, null, 2)}</div> */}
                  </>
                </AForm.Item>
              )
            }}
          </Field>
        )
        break
      case "array":
        toBeReturned = (
          <Field name={keyPrefix + key} key={key} type={FieldsList[key].fieldType} multiple={true}>
            {(fieldProps) => {
              return (
                <AForm.Item
                  {...layout}
                  className={FieldsList[key].className}
                  label={FieldsList[key].title}
                  name={keyPrefix + key}
                  required={FieldsList[key].required}
                  validateStatus={(fieldProps.form.submitCount > 0 || fieldProps.meta.touched) && fieldProps.meta.error ? "error" : null}
                  help={
                    (fieldProps.form.submitCount > 0 || fieldProps.meta.touched) && fieldProps.meta.error
                      ? fieldProps.meta.error || FieldsList[key].description
                      : FieldsList[key].description
                  }
                  extra={FieldsList[key].extra}
                >
                  <>
                    {FieldsList[key].fieldType === "select" ? <Select {...fieldProps.field} {...FieldsList[key].fieldProperties}></Select> : null}
                    {FieldsList[key].fieldType === "checkbox"
                      ? FieldsList[key].options.map((opt) => (
                          <Checkbox
                            key={key + "-" + (opt.value || opt)}
                            // label={opt.title || opt.value || opt}
                            defaultChecked={fieldProps.field.value?.indexOf(opt.value || opt) !== -1}
                            onChange={(e) => {
                              let newValue = e.target.checked
                                ? [...fieldProps.field.value, opt.value || opt]
                                : fieldProps.field.value.filter((v) => v !== (opt.value || opt))
                              //fieldProps.field.onBlur({ target: { name: key, value: newValue } })
                              fieldProps.field.onChange({ target: { name: key, value: newValue } })
                            }}
                            style={FieldsList[key].inline ? {} : { display: "block", height: "30px", lineHeight: "30px", marginLeft: 0 }}
                            disabled={FieldsList[key].disabled || formProps.disabled}
                          >
                            {opt.title || opt.value || opt}
                          </Checkbox>
                        ))
                      : null}
                    {/* <div style={{ marginTop: 10, fontSize: 9 }}>{JSON.stringify(fieldProps, null, 2)}</div> */}
                  </>
                </AForm.Item>
              )
            }}
          </Field>
        )
        break
      case "date":
      case "datetime":
        toBeReturned = (
          <Field name={keyPrefix + key} key={key}>
            {(fieldProps) => {
              const val = fieldProps.field.value ? parseISO(fieldProps.field.value) : null
              return (
                <AForm.Item
                  {...layout}
                  className={FieldsList[key].className}
                  label={FieldsList[key].title}
                  name={keyPrefix + key}
                  required={FieldsList[key].required}
                  validateStatus={(fieldProps.form.submitCount > 0 || fieldProps.meta.touched) && fieldProps.meta.error ? "error" : null}
                  help={
                    (fieldProps.form.submitCount > 0 || fieldProps.meta.touched) && fieldProps.meta.error
                      ? fieldProps.meta.error || FieldsList[key].description
                      : FieldsList[key].description
                  }
                  extra={FieldsList[key].extra}
                >
                  <>
                    <DatePicker
                      {...fieldProps.field}
                      className={"ant-input antd-react-datepicker" + (fieldProps.meta.touched && fieldProps.meta.error ? " pf-c-form-control-invalid" : "")}
                      name={keyPrefix + key}
                      selected={val}
                      value={val}
                      onChange={(v: Date) =>
                        fieldProps.field.onChange({
                          target: {
                            name: keyPrefix + key,
                            value: v ? (FieldsList[key].type === "date" ? formatISO(v).slice(0, 10) : formatISO(v).slice(0, 19).replace("T", " ")) : null,
                          },
                        })
                      }
                      disabled={FieldsList[key].disabled || formProps.disabled}
                      {...FieldsList[key].fieldProperties}
                    />
                  </>
                </AForm.Item>
              )
            }}
          </Field>
        )
        break
      case "boolean":
        toBeReturned = (
          <Field name={keyPrefix + key} key={key} type="checkbox">
            {(fieldProps) => {
              return (
                <AForm.Item
                  {...layout}
                  className={FieldsList[key].className}
                  label={FieldsList[key].fieldType === "radio" ? FieldsList[key].title : null}
                  name={keyPrefix + key}
                  required={FieldsList[key].required}
                  validateStatus={(fieldProps.form.submitCount > 0 || fieldProps.meta.touched) && fieldProps.meta.error ? "error" : null}
                  help={
                    (fieldProps.form.submitCount > 0 || fieldProps.meta.touched) && fieldProps.meta.error
                      ? fieldProps.meta.error || FieldsList[key].description
                      : FieldsList[key].description
                  }
                  extra={FieldsList[key].extra}
                >
                  <>
                    {FieldsList[key].fieldType === undefined ? (
                      <Checkbox
                        className={FieldsList[key].required ? "checkbox-single-required" : "checkbox-single"}
                        {...fieldProps.field}
                        defaultChecked={!!fieldProps.field.value || false}
                        onChange={(e) => {
                          fieldProps.field.onChange({ target: { name: e.target.name, value: e.target.checked ? 1 : 0 } })
                        }}
                        value={!!fieldProps.field.value || false}
                        disabled={FieldsList[key].disabled || formProps.disabled}
                      >
                        <span className="label">{FieldsList[key].title}</span>
                      </Checkbox>
                    ) : null}
                    {FieldsList[key].fieldType === "radio" ? (
                      <Radio.Group
                        onChange={(e) => {
                          fieldProps.field.onChange({ target: { name: e.target.name, value: e.target.value } })
                        }}
                        disabled={FieldsList[key].disabled || formProps.disabled}
                        value={fieldProps.field.value}
                      >
                        {FieldsList[key].options.map((opt) => (
                          <Radio
                            key={key + "-" + (opt.value || opt)}
                            value={opt.value}
                            style={FieldsList[key].inline ? {} : { display: "block", height: "30px", lineHeight: "30px" }}
                          >
                            {opt.title || opt.value || opt}
                          </Radio>
                        ))}
                      </Radio.Group>
                    ) : null}
                  </>
                </AForm.Item>
              )
            }}
          </Field>
        )
        break
      case "file":
        toBeReturned = (
          <Field name={keyPrefix + key} key={key} type="checkbox">
            {(fieldProps) => {
              return (
                <AForm.Item
                  {...layout}
                  className={FieldsList[key].className}
                  label={FieldsList[key].title}
                  name={keyPrefix + key}
                  required={FieldsList[key].required}
                  validateStatus={(fieldProps.form.submitCount > 0 || fieldProps.meta.touched) && fieldProps.meta.error ? "error" : null}
                  help={
                    (fieldProps.form.submitCount > 0 || fieldProps.meta.touched) && fieldProps.meta.error
                      ? fieldProps.meta.error || FieldsList[key].description
                      : FieldsList[key].description
                  }
                  extra={FieldsList[key].extra}
                >
                  <Dropzone
                    onDrop={(acceptedFiles) => {
                      fieldProps.field.onChange({
                        target: {
                          name: keyPrefix + key,
                          value: acceptedFiles,
                        },
                      })
                    }}
                    {...FieldsList[key].dropZoneProperties}
                    disabled={FieldsList[key].disabled || formProps.disabled}
                  >
                    {({ getRootProps, getInputProps, isDragActive, isDragReject, acceptedFiles, fileRejections }) => (
                      <section>
                        <div {...getRootProps()} className="form-dropzone" style={isDragReject ? { borderColor: "red" } : {}}>
                          <input {...getInputProps()} />
                          {isDragActive
                            ? "Rilascia qui i file"
                            : FieldsList[key].dropZoneProperties?.multiple == null || FieldsList[key].dropZoneProperties?.multiple == true
                            ? "Trascina qui uno o più file, o fai click per selezionarli"
                            : "Trascina qui un file, o fai click per selezionarlo"}
                        </div>
                        <ul className="form-dropzone-list">
                          {acceptedFiles.map((f, i) => (
                            <li key={i}>{f.name}</li>
                          ))}
                          {fileRejections.map((f, i) => (
                            <li key={i} style={{ color: "red" }}>
                              {f.file.name}:{" "}
                              {f.errors.map((e, i) => (
                                <span key={i}>{e.message} </span>
                              ))}
                            </li>
                          ))}
                        </ul>
                      </section>
                    )}
                  </Dropzone>
                </AForm.Item>
              )
            }}
          </Field>
        )
        break
      case "object":
        return generateFields(FieldsList[key].properties, layout, formProps, undefined, key + ".")
      case "row":
        // Render multiple fields in a row (2, 3, 4, 6, 8 or 12) by changing the span
        let spanPerField = 24 / Object.keys(FieldsList[key].properties).length
        return generateFields(FieldsList[key].properties, layout, formProps, spanPerField, keyPrefix)
      case "component":
        return <React.Fragment key={key}>{FieldsList[key].component}</React.Fragment>
      case "html":
        return <div className={"ant-col ant-col-" + span} key={key} dangerouslySetInnerHTML={{ __html: FieldsList[key].html }}></div>
      default:
        return ""
    }

    // Add span to fields (whole row 24 default)
    return (
      <Col span={span} key={key}>
        {toBeReturned}
      </Col>
    )
  })
}

const convertToYupJsonSchema = (formJson) => {
  const schemaJson: any = {}
  const cfgJson = { errMessages: {} }
  let validatorsArray: any[] = []

  Object.keys(formJson).forEach((key) => {
    switch (key) {
      case "properties":
        // Process each property recursively
        schemaJson["properties"] = {}
        Object.keys(formJson["properties"]).forEach((property) => {
          switch (formJson["properties"][property].type) {
            case "row":
              Object.keys(formJson["properties"][property].properties).forEach((propertyNested) => {
                let { schema, config, customValidators } = convertToYupJsonSchema(formJson["properties"][property].properties[propertyNested])
                schemaJson["properties"][propertyNested] = schema
                cfgJson.errMessages[propertyNested] = config.errMessages
                validatorsArray = [...validatorsArray, ...customValidators]
              })
              break
            case "component":
            case "html":
              // Types not compatible with ValidationSchema converted through https://github.com/kristianmandrup/schema-to-yup
              break
              //  console.log("File: ", convertToYupJsonSchema(formJson["properties"][property]))

              break
            case "file":
            default:
              let { schema, config, customValidators } = convertToYupJsonSchema(formJson["properties"][property])

              // Also check for arrays... like property="test[0]"
              // const matchArray = /([a-zA-Z_][a-zA-Z0-9_]*)\[([0-9]+)\]/gm
              // let m
              // if ((m = matchArray.exec(property))) {
              //   if (schemaJson["properties"][m[1]] == null) schemaJson["properties"][m[1]] = []
              //   if (cfgJson.errMessages[m[1]] == null) cfgJson.errMessages[m[1]] = []

              //   schemaJson["properties"][m[1]][m[2]] = schema
              //   validatorsArray = [...validatorsArray, ...customValidators]
              //   cfgJson.errMessages[m[1]][m[2]] = config.errMessages
              // } else {
              schemaJson["properties"][property] = schema
              validatorsArray = [...validatorsArray, ...customValidators]

              // Flatten error messages if inside an object
              if (formJson["properties"][property].type === "object") {
                cfgJson.errMessages = { ...cfgJson.errMessages, ...config.errMessages }
              } else {
                cfgJson.errMessages[property] = config.errMessages
              }

              // Consider File as Array
              if (schema.type == "file") {
                schema.type = "array"
              }

            // }
          }
        })

        break
      case "default":
      case "disabled":
      case "title":
      case "description":
      case "extra":
      case "placeholder":
      case "horizontal":
      case "inline":
      case "fieldProperties":
      case "fieldType":
      case "options":
        // Avoid processing (not compatible)
        break
      case "type":
        switch (formJson[key]) {
          case "date":
          case "datetime":
            schemaJson[key] = "string"
            break
          default:
            schemaJson[key] = formJson[key]
        }
        break
      case "customValidators":
        validatorsArray = [...validatorsArray, ...formJson[key]]
        break
      case "errMessages":
        cfgJson.errMessages = { ...cfgJson.errMessages, ...formJson[key] }
        break
      default:
        // Not processed, keep it
        schemaJson[key] = formJson[key]
    }
  })

  //console.log({ schema: schemaJson, config: cfgJson, customValidators: validatorsArray })
  return { schema: schemaJson, config: cfgJson, customValidators: validatorsArray }
}

export const extractDefaultValues = (formJson) => {
  const newJson = {}
  // If we are at the leave return it
  if (formJson.default !== undefined) return formJson.default
  if (formJson.properties === undefined) return ""

  // Process each property recursively
  Object.keys(formJson["properties"]).forEach((property) => {
    switch (formJson["properties"][property].type) {
      case "row":
        Object.keys(formJson["properties"][property].properties).forEach((propertyNested) => {
          newJson[propertyNested] = extractDefaultValues(formJson["properties"][property].properties[propertyNested])
        })
        break
      case "component":
      case "html":
        break
      default:
        const matchArray = /([a-zA-Z_][a-zA-Z0-9_]*)\[([0-9]+)\]/gm
        // Also check for arrays... like property="test[0]"
        let m
        if ((m = matchArray.exec(property))) {
          if (newJson[m[1]] == null) newJson[m[1]] = []
          newJson[m[1]][m[2]] = extractDefaultValues(formJson["properties"][property])
        } else {
          newJson[property] = extractDefaultValues(formJson["properties"][property])
        }
    }
  })

  return newJson
}

export const translateSchema = (node, t: TFunction, translateAll: Boolean = false) => {
  const newSchema = {}

  // If it's an array go throug all values (so also check if they are objects)
  if (Array.isArray(node)) {
    return node.map((e) => translateSchema(e, t))
  }

  // If it's an Object, go through all keys
  if (typeof node === "object" && node !== null) {
    // if object represents a component. Skip.
    if (node.type === "component" || node.type === "html") return node

    Object.keys(node).forEach((key) => {
      if (Array.isArray(node[key])) {
        // if key is an array (reiterate)
        newSchema[key] = node[key].map((e) => translateSchema(e, t))
      } else if (typeof node[key] === "object" && node[key] !== null) {
        // if key is an object (reiterate)
        newSchema[key] = translateSchema(node[key], t, key === "errMessages") // if error messages translate them all.
      } else {
        // OK, it's not an object/array, check the key name
        switch (key) {
          case "title":
          case "description":
          case "placeholder":
            newSchema[key] = t(node[key])
            //console.log(`${key}: ${node[key]} -> ${newSchema[key]}`);
            break
          // case "html":
          // case "component":
          //   newSchema[key] = node[key];
          //   break;
          default:
            // Copy the value or translate it if translateAll
            newSchema[key] = translateAll ? t(node[key]) : node[key]
          //if (translateAll) console.log(`${key}: ${node[key]} -> ${newSchema[key]}`);
        }
      }
    })

    //console.log(newSchema);
    return newSchema
  }

  // else return the value (we do not translate unidentified fields)
  return node
}

export default FormGenerator
