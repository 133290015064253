module.exports = {
  path: `${__dirname}/../../static/locales`,
  //  languages: [`it`, `en`],
  //  languageCodes: { it: "IT", en: "EN" },
  languages: [`it`],
  languageCodes: { it: "IT" },
  defaultLanguage: `it`,
  redirect: true,
  siteUrl: require("../../siteUrl"),

  // you can pass any i18next options
  // pass following options to allow message content as a key
  i18nextOptions: {
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    keySeparator: false,
    nsSeparator: false,
  },
}
