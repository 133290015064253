import React from "react"
import Icon from "./Icon"

export type StepProps = {
  title: string
  borderColor?: string
  transparent?: boolean
  active?: boolean
  disabled?: boolean
  unclickable?: boolean
  onClick?: Function
  icon?: "enter" | "do-not-enter" | "warning" | "cars" | "check" | "time" | "euro" | "edit"
  children?: any
}

const Step: React.FC<StepProps> = (props) => {
  props = {
    transparent: true,
    borderColor: "blue",
    active: false,
    disabled: false,
    unclickable: false,
    ...props,
  }

  if (props.disabled) {
    props.borderColor = "#AAA"
  }

  let stepIcon
  switch (props.icon) {
    case "edit":
      stepIcon = <Icon name="far:edit" color={props.borderColor} />
      break
    case "time":
      stepIcon = <Icon name="far:clock" color={props.borderColor} />
      break
    case "cars":
      stepIcon = <Icon name="fad:cars" color={props.borderColor} />
      break
    case "check":
      stepIcon = <Icon name="fas:check-circle" color={props.borderColor} />
      break
    case "do-not-enter":
      stepIcon = <Icon name="fas:do-not-enter" color={"red"} />
      break
    case "warning":
      stepIcon = <Icon name="fas:engine-warning" color={"orange"} />
      break
    case "euro":
      stepIcon = <Icon name="fas:euro-sign" color={props.borderColor} style={{ marginRight: "0.7rem", marginLeft: "0.2rem" }} />
      break
    default:
    case "enter":
      stepIcon = <Icon name="fal:portal-enter" color={props.borderColor} />
      break
  }

  return (
    <div
      className={"steps__step" + (props.active ? " active" : "") + (props.unclickable ? " unclickable" : "")}
      style={{ opacity: props.disabled ? 0.7 : 0.9 }}
      onClick={(e) => {
        if (props.onClick) {
          props.onClick(e)
        }
      }}
    >
      <div className="sarrow-box">
        <div className="sarrow-outside" style={{ backgroundColor: props.borderColor }}></div>
        <div className="sarrow-inside" style={{ opacity: props.transparent && !props.active ? 0.94 : 1 }}>
          <div className="sarrow-title">{props.title}</div>
          <div className="sarrow-content">{props.children}</div>
          <div className="sarrow-icon">{stepIcon}</div>
        </div>
      </div>
    </div>
  )
}

const Steps: React.FC<{ steps: StepProps[] }> = ({ steps }) => {
  return (
    <div className="steps__container">
      {/* <Step title="1. Partecipa" borderColor="green" icon="check">
        Sei iscritto a questo gruppo
      </Step>
      <Step title="2. Scegli" active icon="cars" unclickable>
        Compila il form in pagina per proseguire
      </Step>
      <Step title="3. Conferma" disabled icon="time">
        Lo step di conferma sarà accessibile fra 4 giorni
      </Step> */}
      {/* <Step title="Registrati" active borderColor="green" icon="enter">
        [-5 gg]<b>Registrati gratis e senza impegno</b>!
      </Step>
      <Step title="Scopri" borderColor="blue" icon="cars">
        [Loghi] <b>Sconti</b> e i <b>fornitori</b> del tuo gruppo d'acquisto
      </Step>
      <Step title="Acquista e risparmia" borderColor="green" icon="time">
        [15/02 &gt; 15/03] <b>Acquista</b> la tua nuova auto <b>risparmiando in media ulteriori 1000€</b>
        <sup>*</sup>
  </Step>*/}

      {steps.map((step, i) => (
        <Step {...step} key={i} />
      ))}
    </div>
  )
}

export default Steps
