module.exports = {
  test: { it: "/ecoverso/", en: "/en/ecoversorum/" },
  posts: { it: "/blog/", en: "/en/blog/" },
  cars: { it: "/auto/", en: "/en/cars/" },
  groups: { it: "/gruppi-di-acquisto/", en: "/en/buying-groups/" },
  press: { it: "/rassegna-stampa/", en: "/en/press-release/" },
  testimonials: { it: "/testimonianze/", en: "/en/testimonials/" },
  faq: { it: "/faq/", en: "/en/faq/" },
  privacy: { it: "/privacy/", en: "/en/privacy/" },
  facebook: { it: "https://www.facebook.com/wegoecoverso", en: "https://www.facebook.com/wegoecoverso" },
  twitter: { it: "https://twitter.com/ecoverso", en: "https://twitter.com/ecoverso" },
  instagram: {
    it: "https://www.instagram.com/wegoecoverso/",
    en: "https://www.instagram.com/wegoecoverso/",
  },
  youtube: {
    it: "https://www.youtube.com/c/Ecoverso",
    en: "https://www.youtube.com/c/Ecoverso",
  },
}
