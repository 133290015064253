import { Organization, WithContext, Thing } from "schema-dts"

export const sdEcoversoOrganization: Organization = {
  "@type": "Organization",
  name: "Ecoverso",
  url: "{siteUrl}",
  logo: "{siteUrl}/public/img/ecoverso.logo-avatar.svg",
}

export const sdEcoversoPublisher: Organization = {
  "@type": "Organization",
  name: "Ecoverso",
  url: "{siteUrl}",
  logo: "{siteUrl}/public/img/ecoverso.logo.h60.png",
}

export const addContext = (data: Thing): WithContext<Thing> => {
  return { ...(data as { [x: string]: any }), "@context": "https://schema.org" } as WithContext<Thing>
}
