import React, { useEffect } from "react"
import { useComponentWillMount } from "../utils/customHooks"

export const Overlay = () => {
  useComponentWillMount(() => {
    // When the modal is shown, we want a fixed body
    const scrollY = window.scrollY
    document.body.style.position = "fixed"
    document.body.style.top = `-${scrollY}px`
  })

  useEffect(() => {
    return () => {
      // When the modal is hidden, we want to remain at the top of the scroll position
      const scrollY = document.body.style.top
      document.body.style.position = ""
      document.body.style.top = ""
      window.scrollTo(0, parseInt(scrollY || "0") * -1)
    }
  }, [])

  return <div className="modal-background"></div>
}

export default Overlay
