import React, { useEffect, useContext, useReducer, useState } from "react"

import { Thing, WithContext } from "schema-dts"
import { SeoContext, structuredDataType } from "./"

let dataUID = 0

type Props = {
  data: WithContext<Thing> | Thing
  property?: string
  children?: any
}

const seoReducer = (state, newValue) => {
  return { ...state, ...newValue }
}

/**
 *
 * Tags that will be replaced:
 *
 *    {siteUrl} - the website root url
 *    {pageUrl} - the current page full url
 *
 * @param data
 */
export const StructuredData: React.FC<Props> = ({ data, property, children }) => {
  const { addData: parentAddData } = useContext(SeoContext)
  const [schema, addData] = useReducer(seoReducer, [])
  const [id, setId] = useState(null)

  useEffect(() => {
    // Genera un id unovico
    setId(dataUID++)
  }, [])

  useEffect(() => {
    // Procedi solo se l'id univoco è già stato generato
    if (id != null) {
      const upsertData = {}

      // Add properties to data if there are Childre in the context
      const enrichedData = { ...(data as { [x: string]: any }) }
      Object.keys(schema).forEach((key) => {
        const subData: { property: string; data: Thing } = schema[key]
        if (!enrichedData[subData.property]) enrichedData[subData.property] = subData.data
        else if (!Array.isArray(enrichedData[subData.property]))
          enrichedData[subData.property] = [enrichedData[subData.property], subData.data]
        else enrichedData[subData.property] = [...enrichedData[subData.property], subData.data]
      })

      if (property) {
        upsertData[id] = { property, data: enrichedData }
      } else {
        upsertData[id] = enrichedData
      }

      parentAddData(upsertData)
      //console.log(id, upsertData)
    }
  }, [schema, id])

  if (children) {
    return <SeoContext.Provider value={{ schema, addData }}>{children}</SeoContext.Provider>
  }

  return null
}

export default StructuredData
