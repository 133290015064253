import * as actionType from "../actions/actionTypes"
import { INotification } from "../../Layout/Notifications"

const initialState: {
  loading: Boolean
  loginModalVisibility: Boolean
  notifications: INotification[]
} = {
  loading: false,
  loginModalVisibility: false,
  notifications: [],
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionType.LOADING_ON:
      return {
        ...state,
        loading: true,
      }
    case actionType.LOADING_OFF:
      return {
        ...state,
        loading: false,
      }
    case actionType.LOGIN_MODAL_VISIBILITY:
      return {
        ...state,
        loginModalVisibility: action.visible,
      }
    case actionType.NOTIFICATION_ADD:
      return {
        ...state,
        notifications: state.notifications.concat(action.notification),
      }
    case actionType.NOTIFICATION_EMPTY:
      return {
        ...state,
        notifications: [],
      }
  }

  return state
}

export default reducer
