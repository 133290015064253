import React from "react"

export const Loading = (props: { active?: boolean; fixed?: boolean; children?; size?: "normal" | "small" }) => {
  props = {
    active: true,
    fixed: false,
    size: "normal",
    ...props,
  }

  return (
    <React.Fragment>
      {props.active ? (
        <div className={"loading" + (props.fixed ? " loading--fixed" : "")}>
          {props.size == "small" ? (
            <img src="/img/ecoverso.spinner.gif" style={{ height: "2.4rem" }} />
          ) : (
            <img src="/img/ecoverso.spinner.gif" />
          )}
        </div>
      ) : null}
      {props.children}
    </React.Fragment>
  )
}

export default Loading
