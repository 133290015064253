import React from "react"

import { Router as ReachRouter } from "@reach/router"
import { useI18next } from "."

export const Router = ({ basepath, children, ...props }: { basepath?: string; children: any; [x: string]: any }) => {
  const { language, defaultLanguage } = useI18next()

  // Add language to basepath if needed
  const languageBasePath = language != defaultLanguage ? "/" + language : ""
  if (basepath || languageBasePath) {
    props.basepath = languageBasePath + (basepath || "")
  }

  return <ReachRouter {...props}>{children}</ReachRouter>
}

export default Router
