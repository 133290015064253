import React from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image/withIEPolyfill"
import { GatsbyImageProps } from "gatsby-image"
import { IconData, LinkData } from "../../rows/DynamicRow"
import Icon from "./Icon"
import { Link } from "../../i18n"

export type ImageData = { altText: string; imageFile: { childImageSharp?: any; publicURL: any } }

export type ImageTypes =
  | "fullwidth"
  | "square-styled"
  | "cover"
  | "contain"
  | "inside-arrow"
  | "inside-arrow-styled"
  | "inside-arrow-full-styled"
  | "above-arrow-square-styled"
  | "below-arrow-styled"
// https://www.gatsbyjs.com/plugins/gatsby-image/#gatsby-image-props
export type ImageProps = GatsbyImageProps & {
  objectFit?: `fill` | `contain` | `cover` | `contain` | `none` | `scale-down`
  objectPosition?: string
  file?: { altText: string; imageFile: { childImageSharp?: any; publicURL: any } }
  type?: ImageTypes
  src?: string
  styledColor?: string
  icon?: IconData
  link?: LinkData
}

export const Image: React.FC<ImageProps> = ({ type, file, ...props }) => {
  // console.log("Image props before: ", props)

  // A file object is passed and is null, no image
  if (file === null) return null

  if (file) {
    props.alt = file.altText
    if (file.imageFile.childImageSharp?.fluid) props.fluid = file.imageFile.childImageSharp.fluid
    if (file.imageFile.childImageSharp?.fixed) props.fluid = file.imageFile.childImageSharp.fixed
    // if neither fluid or fixed is defined then use non optimized image at publicUrl
    if (!props.fluid && !props.fixed) props.src = file.imageFile.publicURL || undefined
  }

  let theImage = null

  if (type) {
    switch (type) {
      case "cover":
        props = applyCover(props)
        theImage = <FinalImage {...props} />
        break
      case "contain":
        props = applyContain(props)
        theImage = <FinalImage {...props} />
        break
      case "fullwidth":
        props.style = { ...props.style, width: "100%" }
        theImage = <FinalImage {...props} />
        break
      case "square-styled":
        theImage = (
          <div className="image--square">
            <div className="image--square__image-box">
              <FinalImage {...props} className="image--square__image" />
            </div>
          </div>
        )
        break
      case "inside-arrow":
        theImage = (
          <div className="image--inside-arrow">
            <div className="image--inside-arrow__arrow-box">
              <div className="image--inside-arrow__arrow-outside"></div>
              <div className="image--inside-arrow__arrow-inside"></div>
            </div>
            <div className="image--inside-arrow__image-box">
              <FinalImage {...props} className="image--inside-arrow__image" />
            </div>
          </div>
        )
        break
      case "inside-arrow-styled":
        theImage = (
          <div className="image--inside-arrow">
            <div className="image--inside-arrow__arrow-box">
              <div className="image--inside-arrow__arrow-outside" style={{ backgroundColor: props.styledColor }}></div>
              <div className="image--inside-arrow__arrow-inside"></div>
            </div>
            <div className="image--inside-arrow__image-box">
              <FinalImage {...props} className="image--inside-arrow__image" />
            </div>
            {props.icon?.icon ? (
              <div className="image--inside-arrow__icon-box image__icon-box" style={{ color: props.styledColor }}>
                <Icon icon={props.icon} />
              </div>
            ) : null}
          </div>
        )
        break
      case "inside-arrow-full-styled":
        theImage = (
          <div className="image--inside-arrow">
            <div className="image--inside-arrow__arrow-box">
              <div className="image--inside-arrow__arrow-outside" style={{ backgroundColor: props.styledColor }}></div>
              {/* <div className="image--inside-arrow__arrow-inside"></div> */}
            </div>
            <div className="image--inside-arrow__image-box">
              <FinalImage {...props} className="image--inside-arrow__image" />
            </div>
            {props.icon?.icon ? (
              <div className="image--inside-arrow__icon-box image__icon-box" style={{ color: props.styledColor }}>
                <Icon icon={props.icon} />
              </div>
            ) : null}
          </div>
        )
        break
      case "above-arrow-square-styled":
        theImage = (
          <div className="image--above-arrow">
            <div className="image--above-arrow__arrow-box">
              <div className="image--above-arrow__arrow-outside" style={{ backgroundColor: props.styledColor }}></div>
              <div className="image--above-arrow__arrow-inside"></div>
            </div>
            <div className="image--above-arrow__image-box">
              <FinalImage {...props} className="image--above-arrow__image" />
            </div>
            {props.icon?.icon ? (
              <div className="image--above-arrow__icon-box image__icon-box" style={{ color: props.styledColor }}>
                <Icon icon={props.icon} />
              </div>
            ) : null}
          </div>
        )
        break
      case "below-arrow-styled":
        theImage = (
          <div className="image--below-arrow">
            <div className="image--below-arrow__image-box">
              <FinalImage {...props} className="image--below-arrow__image" />
            </div>
            <div className="image--below-arrow__arrow-box">
              <div className="image--below-arrow__arrow-outside" style={{ backgroundColor: props.styledColor }}></div>
              <div className="image--below-arrow__arrow-inside">
                <div className="image--below-arrow__image-box--inside">
                  <FinalImage {...props} className="image--below-arrow__image" />
                </div>
              </div>
            </div>
            {props.icon?.icon ? (
              <div className="image--below-arrow__icon-box image__icon-box" style={{ color: props.styledColor }}>
                <Icon icon={props.icon} />
              </div>
            ) : null}
          </div>
        )
        break
    }
  }

  // console.log("Image props after: ", props)

  if (props.link) {
    return (
      <Link to={props.link.url} target={props.link.target} className="image__with-link-holder">
        {theImage}
        {props.icon?.icon ? (
          <div className="image__with-link-icon" style={{ backgroundColor: props.styledColor }}>
            <Icon icon={props.icon} />
          </div>
        ) : null}
      </Link>
    )
  }

  return theImage
}

const applyCover = (props) => {
  if (props.src) {
    props.style = { ...props.style, objectFit: "cover" }
  } else {
    props.objectFit = "cover"
    props.objectPosition = "50% 50%"
  }
  return props
}

const applyContain = (props) => {
  if (props.src) {
    props.style = { ...props.style, objectFit: "contain" }
  } else {
    props.objectFit = "contain"
    props.objectPosition = "50% 50%"
  }
  return props
}

const FinalImage = (props: ImageProps) => {
  if (props.src) {
    return <img src={props.src} alt={props.alt} style={props.style} className={props.className as string} />
  } else {
    return <Img {...props} />
  }
}

export default Image

// Query fragment to include in graphql
export const query = graphql`
  fragment imageData on Wordpress_MediaItem {
    altText
    mediaItemUrl
    imageFile {
      childImageSharp {
        fluid(maxWidth: 1080) {
          ...GatsbyImageSharpFluid
        }
      }
      publicURL
    }
  }
`
