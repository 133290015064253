import React from "react"
import { addDays } from "date-fns"
import countries from "../../Layout/Forms/data/countries"

import { Tooltip } from "antd"
import { InfoCircleOutlined, UserOutlined } from "@ant-design/icons"
import { Link } from "../../i18n"
import * as site from "../../sitemap"
import { FormFieldTypeData } from "../../Layout/Forms/FormDataType"
import { faLoveseat } from "@fortawesome/pro-solid-svg-icons"
import { capitalizeWords } from "../../Layout/Forms/utils"

export const firstName: FormFieldTypeData = {
  title: "Nome",
  type: "string",
  nullable: true,
  required: true,
  errMessages: {
    required: "Il Nome è richiesto.",
  },
  onChange: (v) => capitalizeWords(v),
}

export const lastName: FormFieldTypeData = {
  title: "Cognome",
  type: "string",
  nullable: true,
  required: true,
  errMessages: {
    required: "Il Cognome è richiesto.",
  },
  onChange: (v) => capitalizeWords(v),
}

export const birthDate: FormFieldTypeData = {
  title: "Data di nascita",
  type: "date",
  nullable: true,
  fieldProperties: {
    dateFormat: "dd/MM/yyyy",
    maxDate: addDays(new Date(), -1),
  },
  errMessages: { required: "E' necessario indicare la data di nascita" },
}

export const gender: FormFieldTypeData = {
  title: "Sesso",
  type: "string",
  fieldType: "select",
  nullable: true,
  fieldProperties: {
    variant: "single",
    placeholderText: "Select...",
    options: [
      { value: "Maschio", title: "Maschio" },
      { value: "Femmina", title: "Femmina" },
      { value: "Altro", title: "Altro" },
    ],
  },
  errMessages: { required: "E' necessario indicare il sesso" },
}

export const displayName: FormFieldTypeData = {
  title: "Display name",
  description: "Con che nome vuoi comparire sul sito?",
  type: "string",
  pattern: "^[a-zA-Z].*$",
  required: true,
  errMessages: {
    required: "Il Display Name è richiesto.",
    pattern: "Il Display Name deve iniziare con una lettera.",
  },
}

export const nominativo: FormFieldTypeData = {
  title: "Nome",
  type: "string",
  pattern: "^[a-zA-Z].*$",
  required: true,
  errMessages: {
    required: "Il nome è richiesto.",
    pattern: "Il nome deve iniziare con una lettera.",
  },
}

export const email: FormFieldTypeData = {
  type: "string",
  title: "Email",
  format: "email",
  required: true,
  errMessages: {
    required: "La mail è richiesta",
  },
  onChange: (v) => v.toLowerCase(),
}

export const mobile: FormFieldTypeData = {
  type: "string",
  title: "Cellulare",
  pattern: "^\\+?[0-9]{8,16}$",
  nullable: true,
  errMessages: { required: "E' necessario indicare il cellulare", pattern: "Per favore indicare un numero valido" },
}

export const message: FormFieldTypeData = {
  title: "Messaggio",
  type: "string",
  fieldType: "textarea",
  required: true,
  fieldProperties: {
    autoSize: { minRows: 4, maxRows: 8 },
  },
}

export const address1: FormFieldTypeData = {
  type: "string",
  title: "Indirizzo",
  nullable: true,
  errMessages: { required: "E' necessario indicare l'indirizzo" },
}

export const birthPlace: FormFieldTypeData = {
  type: "string",
  title: "Luogo di nascita",
  nullable: true,
  errMessages: { required: "E' necessario indicare il luogo di nascita" },
}

export const city: FormFieldTypeData = {
  type: "string",
  title: "Città",
  nullable: true,
  errMessages: { required: "E' necessario indicare la città" },
  onChange: (v) => capitalizeWords(v),
}

export const zipCode: FormFieldTypeData = {
  type: "string",
  title: "CAP",
  pattern: "^[0-9]{5}$",
  nullable: true,
  errMessages: { required: "E' necessario indicare il CAP", pattern: "Indicare un CAP corretto" },
}

export const state: FormFieldTypeData = {
  type: "string",
  title: "Provincia (sigla)",
  pattern: "^([A-Za-z]{2}|[rR][oO][mM][aA])$",
  nullable: true,
  errMessages: { required: "E' necessario indicare la provincia", pattern: "Indicare la provincia con la sigla" },
  onChange: (v) => v.toUpperCase(),
}

export const country: FormFieldTypeData = {
  title: "Nazione",
  description: "Selezionare la nazione di residenza",
  type: "string",
  fieldType: "select",
  fieldProperties: {
    variant: "typeahead",
    placeholderText: "Select...",
    options: countries.map((c) => ({ value: c.code, title: c.emoji + " " + c.name })),
  },
  //default: ["Italy"],
  required: true,
  nullable: true,
  errMessages: {
    required: "E' necessario specificare una nazione",
  },
}

export const declaredOrigin: FormFieldTypeData = {
  type: "string",
  title: "Come hai conosciuto ecoverso?",
  // description: "Selezionare l'opzione che meglio si adatta alla tua esperienza",
  required: true,
  nullable: true,
  errMessages: {
    required: "E' necessario selezionare una risposta",
  },
  fieldType: "select",
  fieldProperties: {
    variant: "typeahead",
    placeholderText: "Seleziona...",
    options: [
      { value: "PP - Passaparola", title: "Passaparola" },
      {
        label: "Motori di ricerca",
        options: [
          { value: "MR - Google", title: "Google" },
          { value: "MR - Altro motore di ricerca", title: "Altro motore di ricerca" },
        ],
      },
      { value: "Articolo Online", title: "Articolo Online" },
      { value: "Articolo Cartaceo", title: "Articolo Cartaceo" },
      { value: "Servizio TV", title: "Servizio TV" },
      { value: "Trasmissione Radio", title: "Trasmissione Radio" },
      {
        label: "Social Network",
        options: [
          { value: "SN - Facebook", title: "Facebook" },
          { value: "SN - Instagram", title: "Instagram" },
          { value: "SN - Twitter", title: "Twitter" },
          { value: "SN - YouTube", title: "YouTube" },
          { value: "SN - Altro Social Network", title: "Altro Social Network" },
        ],
      },
      { value: "Forum Online", title: "Forum Online" },
      { value: "GAS (Gruppo d'Acquisto Solidale)", title: "GAS (Gruppo d'Acquisto Solidale)" },
      { value: "Convenzione (con aziende, enti e associazioni)", title: "Convenzione (con aziende, enti e associazioni)" },
      { value: "Fiere", title: "Fiere" },
      { value: "Raduni", title: "Raduni" },
      { value: "Adesivo su auto ibrida/elettrica", title: "Adesivo su auto ibrida/elettrica" },
      { value: "Altro", title: "Altro" },
    ],
  },
}

export const discountCode: FormFieldTypeData = {
  type: "string",
  title: "Codice promozionale",
  nullable: true,
}
export const discountCodeInfo: FormFieldTypeData = {
  type: "component",
  component: (
    <p className="info mb-none">Se hai un codice promozionale inseriscilo qui. Ti darà diritto al 50% di sconto sulla quota associativa ad Ecoverso.</p>
  ),
}

export const readPrivacy: FormFieldTypeData = {
  type: "component",
  component: (
    <p className="mb-none">
      Leggi la{" "}
      <Link to={site.privacy.it} target="_blank">
        Privacy policy
      </Link>
    </p>
  ),
}

export const newsletterInfo: FormFieldTypeData = {
  type: "component",
  component: <p className="info mb-none">Ci piacerebbe aggiornarti periodicamente sui nostri gruppi d’acquisto, corsi ed eventi.</p>,
}

export const acptNewsletter: FormFieldTypeData = {
  title: "Desidero iscrivermi alla newsletter",
  className: "mb-xxs",
  type: "string",
  fieldType: "radio",
  inline: true,
  options: [
    { value: true, title: "Si" },
    { value: false, title: "No" },
  ],
  required: true,
  errMessages: {
    required: "E' necessario selezionare una risposta",
  },
}

export const marketingInfo: FormFieldTypeData = {
  type: "component",
  component: (
    <p className="info mb-none">
      Ci piacerebbe raccontarti la nostra storia e inviarti offerte speciali. Nessun altro al di fuori di noi potrà inviarti comunicazioni commerciali.
    </p>
  ),
}

export const acptMarketing: FormFieldTypeData = {
  title: "Accetto il trattamento dei miei dati per finalità di marketing",
  className: "mb-xxs",
  type: "string",
  fieldType: "radio",
  inline: true,
  options: [
    { value: true, title: "Si" },
    { value: false, title: "No" },
  ],
  required: true,
  errMessages: {
    required: "E' necessario selezionare una risposta",
  },
}

export const profilingInfo: FormFieldTypeData = {
  type: "component",
  component: (
    <p className="info mb-none">Ci piacerebbe capire quali sono le tue preferenze per offrirti servizi sempre migliori e proposte più interessanti per te.</p>
  ),
}

export const acptProfiling: FormFieldTypeData = {
  title: "Accetto il trattamento dei miei dati per finalità di profilazione",
  type: "string",
  fieldType: "radio",
  inline: true,
  options: [
    { value: true, title: "Si" },
    { value: false, title: "No" },
  ],
  required: true,
  errMessages: {
    required: "E' necessario selezionare una risposta",
  },
}

export const statuteInfo: FormFieldTypeData = {
  type: "component",
  component: (
    <p>
      Associantodi a Ecoverso, accetti lo Statuto della nostra associazione. Puoi leggere tutti i dettagli{" "}
      <Link to="termini-e-condizioni" target="_blank">
        a questo link
      </Link>
      .
    </p>
  ),
}

export const acptStatute: FormFieldTypeData = {
  title: "Accetto lo statuto dell'associazione Ecoverso",
  type: "boolean",
  oneOf: [true],
  required: true,
  errMessages: {
    required: "E' necessario accettare lo statuto per associarsi",
    oneOf: "E' necessario accettare lo statuto per associarsi",
  },
}

export const loginCode: FormFieldTypeData = {
  type: "string",
  title: "Codice",
  required: true,
  errMessages: {
    required: "È necessario inserire il codice ricevuto via mail",
  },
}

export const taxCode: FormFieldTypeData = {
  type: "string",
  title: "Codice Fiscale",
  required: true,
  errMessages: {
    required: "È necessario inserire il codice fiscale",
  },
  customValidators: [
    {
      type: "function",
      fieldName: "taxCode",
      function: (v) => {
        const regex =
          /^(?:[A-Z][AEIOU][AEIOUX]|[B-DF-HJ-NP-TV-Z]{2}[A-Z]){2}(?:[\dLMNP-V]{2}(?:[A-EHLMPR-T](?:[04LQ][1-9MNP-V]|[15MR][\dLMNP-V]|[26NS][0-8LMNP-U])|[DHPS][37PT][0L]|[ACELMRT][37PT][01LM]|[AC-EHLMPR-T][26NS][9V])|(?:[02468LNQSU][048LQU]|[13579MPRTV][26NS])B[26NS][9V])(?:[A-MZ][1-9MNP-V][\dLMNP-V]{2}|[A-M][0L](?:[1-9MNP-V][\dLMNP-V]|[0L][1-9MNP-V]))[A-Z]$/i
        const result = v.match(regex)
        return result != null
      },
      errMessage: "Il formato del codice fiscale non è corretto",
    },
  ],
}
