import * as actionTypes from "./actionTypes"
import * as actions from "./index"
import { Auth, Hub } from "aws-amplify"
import { DangerIcon } from "../../Layout/Notifications"

let cognitoUser: any
export type cognitoAttributes = {
  email?: string
  given_name?: string
  family_name?: string
  "custom:declaredOrigin"?: string
  "custom:discountCode"?: string
  "custom:acptNewsletter"?: string
  "custom:acptMarketing"?: string
  "custom:acptProfiling"?: string
}

export const authReset = () => {
  return {
    type: actionTypes.AUTH_RESET,
  }
}

export const authStart = (email?: string) => {
  return {
    type: actionTypes.AUTH_START,
    email,
  }
}

export const authChallenge = (cognitoUser) => {
  return {
    type: actionTypes.AUTH_CHALLENGE,
    cognitoUser,
  }
}

export const authChallengeStart = () => {
  return {
    type: actionTypes.AUTH_CHALLENGE_START,
  }
}

export const authChallengeFail = (softFailMessage?: string) => {
  return {
    type: actionTypes.AUTH_CHALLENGE_FAIL,
    errorMessage: softFailMessage,
  }
}

export const authSuccess = (token, payload) => {
  // Only on browsers push email and userId to google tag manager dataLayer
  if (typeof window !== "undefined") {
    //@ts-ignore
    window.dataLayer = window.dataLayer || []
    //@ts-ignore
    window.dataLayer.push({
      email: payload.signInUserSession?.idToken?.payload?.email,
      userId: payload.signInUserSession?.idToken?.payload?.userId,
      acptNewsletter: payload.signInUserSession?.idToken?.payload?.acptNewsletter,
      acptMarketing: payload.signInUserSession?.idToken?.payload?.acptMarketing,
      acptProfiling: payload.signInUserSession?.idToken?.payload?.acptProfiling,
    })
  }

  return {
    type: actionTypes.AUTH_SUCCESS,
    error: null,
    token,
    payload: {
      role: payload.signInUserSession?.idToken?.payload?.["cognito:groups"] ?? [],
      userData: payload.signInUserSession?.idToken?.payload || {},
      ...payload,
    },
  }
}

export const authFail = (error: any, skipNotification?: boolean) => {
  return (dispatch) => {
    // If present read the "error_description" parameter from URL
    function getParameterByName(name) {
      const url = window.location.href
      name = name.replace(/[[]]/g, "\\$&")
      var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
        results = regex.exec(url)
      if (!results) return null
      if (!results[2]) return ""
      return decodeURIComponent(results[2].replace(/\+/g, " "))
    }

    if (getParameterByName("error_description")) {
      error = getParameterByName("error_description")
    }

    // Register failure to state
    dispatch({
      type: actionTypes.AUTH_FAIL,
      error: error,
      errorMessage: error.message ? error.message : JSON.stringify(error),
    })

    if (!skipNotification) {
      dispatch(
        actions.notificationAdd({
          message: "Authentication failed",
          description: error.message ? error.message : JSON.stringify(error),
          variant: "DangerIcon",
          duration: 0,
        })
      )
    }
  }
}

export const authEnd = () => {
  return {
    type: actionTypes.AUTH_END,
  }
}

export const federatedSignIn = () => {
  return (dispatch) => {
    dispatch(authStart())
    Auth.federatedSignIn()
  }
}

export const passwordlessSignUp = (email: string, attributes?: cognitoAttributes) => {
  return async (dispatch) => {
    const password = getRandomString(30) + "aA!"
    //console.log("Password: ", password)
    dispatch(registrationStart(email))
    try {
      console.log(attributes)
      await Auth.signUp({ username: email, password, attributes })
      dispatch(passwordlessSignIn(email, true))

      // Send event to GTM
      window?.dataLayer?.push({
        event: "new-user-registered",
        declaredOrigin: attributes["custom:declaredOrigin"],
        discountCode: attributes["custom:discountCode"],
        acptNewsletter: attributes["custom:acptNewsletter"],
        acptMarketing: attributes["custom:acptMarketing"],
        acptProfiling: attributes["custom:acptProfiling"],
      })
    } catch (error) {
      console.log("Error signing up", error)
      dispatch(registrationFail(error))
    }
  }
}

export const passwordSignUp = (username: string, password: string, attributes?: cognitoAttributes) => {
  return async (dispatch) => {
    dispatch(registrationStart(username))
    try {
      await Auth.signUp({ username, password, attributes })
    } catch (error) {
      console.log("Error signing up", error)
      dispatch(registrationFail(error))
    }
  }
}

export const passwordlessSignIn = (email: string, skipNotification?: boolean) => {
  return async (dispatch) => {
    dispatch(authStart(email))
    try {
      cognitoUser = await Auth.signIn(email)
      dispatch(authChallenge(cognitoUser))
    } catch (error) {
      console.log("Error signing in", error)
      dispatch(authFail(error, skipNotification))
    }
  }
}

export const passwordlessChallenge = (answer: string, skipNotification?: boolean) => {
  return async (dispatch) => {
    dispatch(authChallengeStart())
    // Send the answer to the User Pool
    // This will throw an error if it’s the 3rd wrong answer
    try {
      cognitoUser = await Auth.sendCustomChallengeAnswer(cognitoUser, answer)
    } catch (error) {
      console.log("Error signing in challenge", error)
      dispatch(authFail(error, skipNotification))
      return
    }

    // It we get here, the answer was sent successfully,
    // but it might have been wrong (1st or 2nd time)
    // So we should test if the user is authenticated now
    try {
      // This will throw an error if the user is not yet authenticated:
      await Auth.currentSession()
      checkLogin(dispatch)
    } catch (error) {
      console.log("Apparently the user did not enter the right code: ", error)
      dispatch(authChallengeFail("The code is incorrect. Please try again."))
    }
  }
}

export const checkIfLoggedIn = () => {
  return (dispatch) => {
    // Listener agli eventi di autenticazione, NECESSARIO per il federated Sign In

    // Hub.listen("auth", ({ payload: { event, data } }) => {
    //   switch (event) {
    //     case "signIn":
    //     case "signUp":
    //       checkLogin(dispatch)
    //       break
    //     case "signOut":
    //       dispatch(authEnd())
    //       break
    //     case "customOAuthState":
    //       dispatch(authSuccess(null, { customState: data }))
    //       break
    //     case "signIn_failure":
    //       dispatch(authFail(data))
    //       break
    //   }
    // })

    checkLogin(dispatch)
  }
}

export const refreshAuthToken = () => {
  return async (dispatch) => {
    console.log("Refreshing Auth Token")
    dispatch(authSuccess(null, await Auth.currentAuthenticatedUser({ bypassCache: true })))
  }
}

export const logout = () => {
  return async (dispatch) => {
    try {
      await Auth.signOut()
      dispatch(authEnd())
    } catch (error) {
      console.log("Error signing out: ", error)
    }
  }
}

export const registrationStart = (email) => {
  return {
    type: actionTypes.REGISTRATION_START,
    email,
  }
}

export const registrationFail = (error) => {
  return {
    type: actionTypes.REGISTRATION_FAIL,
    error: error,
    errorCode: error.code,
    errorMessage: error.message ? error.message : JSON.stringify(error),
  }
}

//--------------------------------------------------------------------------------------------------------
// Utilities

export const checkLogin = (dispatch) => {
  Auth.currentAuthenticatedUser()
    .then((user) => {
      //console.log("Info: ", user)
      //console.log("Info: ", JSON.stringify(user));
      dispatch(authSuccess(null, user))
      // Fethc Identity Pool identityId
      Auth.currentUserCredentials().then(async (data) => {
        //console.log("Auth: ", data)
        // Check if Identity Pool identityId is already stored in Cognito userpool attribute
        if (user.attributes["custom:identityId"] !== data.identityId) {
          //console.log("Updating identity id...")
          const result = await Auth.updateUserAttributes(user, {
            "custom:identityId": data.identityId,
          })
          dispatch(refreshAuthToken())
        }
      })
    })
    .catch((e) => {
      //console.log("AuthError:", e)
      Auth.currentCredentials()
      // .then((d) => console.log("Credentials :", d))
      // .catch((e) => console.log("Error: ", e))
    })
}

function getRandomString(bytes: number) {
  const randomValues = new Uint8Array(bytes)
  window.crypto.getRandomValues(randomValues)
  return Array.from(randomValues).map(intToHex).join("")
}

function intToHex(nr: number) {
  return nr.toString(16).padStart(2, "0")
}
