/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const deleteAffiliation = /* GraphQL */ `
  mutation DeleteAffiliation($id: String!) {
    deleteAffiliation(id: $id) {
      id
      idUser
      idOrganization
      affiliationCode
      affiliationType
      startDate
      endDate
      subscriptionDiscountAmount
      subscriptionDiscountPercent
      ethicoinBalance
      createdDate
      createdBy
      modifiedDate
      modifiedBy
      crmId
      crmLastModified
    }
  }
`;
export const createAffiliation = /* GraphQL */ `
  mutation CreateAffiliation($createAffiliationInput: CreateAffiliationInput!) {
    createAffiliation(createAffiliationInput: $createAffiliationInput) {
      id
      idUser
      idOrganization
      affiliationCode
      affiliationType
      startDate
      endDate
      subscriptionDiscountAmount
      subscriptionDiscountPercent
      ethicoinBalance
      createdDate
      createdBy
      modifiedDate
      modifiedBy
      crmId
      crmLastModified
    }
  }
`;
export const updateAffiliation = /* GraphQL */ `
  mutation UpdateAffiliation($updateAffiliationInput: UpdateAffiliationInput!) {
    updateAffiliation(updateAffiliationInput: $updateAffiliationInput) {
      id
      idUser
      idOrganization
      affiliationCode
      affiliationType
      startDate
      endDate
      subscriptionDiscountAmount
      subscriptionDiscountPercent
      ethicoinBalance
      createdDate
      createdBy
      modifiedDate
      modifiedBy
      crmId
      crmLastModified
    }
  }
`;
export const deleteBuyingGroup = /* GraphQL */ `
  mutation DeleteBuyingGroup($id: String!) {
    deleteBuying_group(id: $id) {
      id
      idOrganization
      Organizer {
        id
        name
        organizationType
        isLocation
        isPlaceHolder
        country
        state
        city
        zipCode
        address1
        VATNumber
        SDICode
        PECEmail
        brands
        notificationsEmail
        notifyOnEachSell
        createdDate
        createdBy
        modifiedDate
        modifiedBy
        crmId
        crmLastModified
      }
      name
      description
      uidCMS
      pubblicationStartDate
      registrationStartDate
      participationStartDate
      choosingStartDate
      registrationEndDate
      confirmationStartDate
      resellerFlowStartDate
      pubblicationEndDate
      participationEndDate
      choosingEndDate
      confirmationEndDate
      resellerFlowEndDate
      maxChoosableVariants
      uniteChooseAndConfirm
      declination
      templateSuffix
      isOnlyUpselling
      GoodsOrServices {
        id
        idBuyingGroup
        idGoodOrService
        createdDate
        createdBy
        modifiedDate
        modifiedBy
        crmId
        crmLastModified
      }
      Resellers {
        id
        idBuyingGroup
        idReseller
        createdDate
        createdBy
        modifiedDate
        modifiedBy
        crmId
        crmLastModified
      }
      GoodOrServiceVariants {
        id
        idGoodOrService
        idBuyingGroup
        code
        name
        description
        maxQuantity
        discountAmountEach
        commissionAmountEach
        discountPercent
        commissionPercent
        createdDate
        createdBy
        modifiedDate
        modifiedBy
        crmId
        crmLastModified
      }
      UsersInBuyingGroup {
        id
        idBuyingGroup
        idUser
        registrationDate
        participationDate
        choosingDate
        notInterestInBuying
        confirmationDate
        definitelyNotInterestedInBuying
        lastEmaiNotificationDate
        disableNotifications
        createdDate
        createdBy
        modifiedDate
        modifiedBy
        crmId
        crmLastModified
      }
      ChosedVariants {
        id
        idBuyingGroup
        idUser
        idVariant
        idReseller
        quantity
        dateChosed
        userChoosingCustomValues
        dateConfirmed
        userConfirmationCustomValues
        dateFinalization
        resellerSellingCustomValues
        totalSoldAmount
        dateCanceled
        cancellationPhase
        cancellationReason
        dateDelivered
        dateBilled
        commisionAmount
        createdDate
        createdBy
        modifiedDate
        modifiedBy
        crmId
        crmLastModified
      }
      createdDate
      createdBy
      modifiedDate
      modifiedBy
      crmId
      crmLastModified
    }
  }
`;
export const createBuyingGroup = /* GraphQL */ `
  mutation CreateBuyingGroup(
    $createBuying_groupInput: CreateBuying_groupInput!
  ) {
    createBuying_group(createBuying_groupInput: $createBuying_groupInput) {
      id
      idOrganization
      Organizer {
        id
        name
        organizationType
        isLocation
        isPlaceHolder
        country
        state
        city
        zipCode
        address1
        VATNumber
        SDICode
        PECEmail
        brands
        notificationsEmail
        notifyOnEachSell
        createdDate
        createdBy
        modifiedDate
        modifiedBy
        crmId
        crmLastModified
      }
      name
      description
      uidCMS
      pubblicationStartDate
      registrationStartDate
      participationStartDate
      choosingStartDate
      registrationEndDate
      confirmationStartDate
      resellerFlowStartDate
      pubblicationEndDate
      participationEndDate
      choosingEndDate
      confirmationEndDate
      resellerFlowEndDate
      maxChoosableVariants
      uniteChooseAndConfirm
      declination
      templateSuffix
      isOnlyUpselling
      GoodsOrServices {
        id
        idBuyingGroup
        idGoodOrService
        createdDate
        createdBy
        modifiedDate
        modifiedBy
        crmId
        crmLastModified
      }
      Resellers {
        id
        idBuyingGroup
        idReseller
        createdDate
        createdBy
        modifiedDate
        modifiedBy
        crmId
        crmLastModified
      }
      GoodOrServiceVariants {
        id
        idGoodOrService
        idBuyingGroup
        code
        name
        description
        maxQuantity
        discountAmountEach
        commissionAmountEach
        discountPercent
        commissionPercent
        createdDate
        createdBy
        modifiedDate
        modifiedBy
        crmId
        crmLastModified
      }
      UsersInBuyingGroup {
        id
        idBuyingGroup
        idUser
        registrationDate
        participationDate
        choosingDate
        notInterestInBuying
        confirmationDate
        definitelyNotInterestedInBuying
        lastEmaiNotificationDate
        disableNotifications
        createdDate
        createdBy
        modifiedDate
        modifiedBy
        crmId
        crmLastModified
      }
      ChosedVariants {
        id
        idBuyingGroup
        idUser
        idVariant
        idReseller
        quantity
        dateChosed
        userChoosingCustomValues
        dateConfirmed
        userConfirmationCustomValues
        dateFinalization
        resellerSellingCustomValues
        totalSoldAmount
        dateCanceled
        cancellationPhase
        cancellationReason
        dateDelivered
        dateBilled
        commisionAmount
        createdDate
        createdBy
        modifiedDate
        modifiedBy
        crmId
        crmLastModified
      }
      createdDate
      createdBy
      modifiedDate
      modifiedBy
      crmId
      crmLastModified
    }
  }
`;
export const updateBuyingGroup = /* GraphQL */ `
  mutation UpdateBuyingGroup(
    $updateBuying_groupInput: UpdateBuying_groupInput!
  ) {
    updateBuying_group(updateBuying_groupInput: $updateBuying_groupInput) {
      id
      idOrganization
      Organizer {
        id
        name
        organizationType
        isLocation
        isPlaceHolder
        country
        state
        city
        zipCode
        address1
        VATNumber
        SDICode
        PECEmail
        brands
        notificationsEmail
        notifyOnEachSell
        createdDate
        createdBy
        modifiedDate
        modifiedBy
        crmId
        crmLastModified
      }
      name
      description
      uidCMS
      pubblicationStartDate
      registrationStartDate
      participationStartDate
      choosingStartDate
      registrationEndDate
      confirmationStartDate
      resellerFlowStartDate
      pubblicationEndDate
      participationEndDate
      choosingEndDate
      confirmationEndDate
      resellerFlowEndDate
      maxChoosableVariants
      uniteChooseAndConfirm
      declination
      templateSuffix
      isOnlyUpselling
      GoodsOrServices {
        id
        idBuyingGroup
        idGoodOrService
        createdDate
        createdBy
        modifiedDate
        modifiedBy
        crmId
        crmLastModified
      }
      Resellers {
        id
        idBuyingGroup
        idReseller
        createdDate
        createdBy
        modifiedDate
        modifiedBy
        crmId
        crmLastModified
      }
      GoodOrServiceVariants {
        id
        idGoodOrService
        idBuyingGroup
        code
        name
        description
        maxQuantity
        discountAmountEach
        commissionAmountEach
        discountPercent
        commissionPercent
        createdDate
        createdBy
        modifiedDate
        modifiedBy
        crmId
        crmLastModified
      }
      UsersInBuyingGroup {
        id
        idBuyingGroup
        idUser
        registrationDate
        participationDate
        choosingDate
        notInterestInBuying
        confirmationDate
        definitelyNotInterestedInBuying
        lastEmaiNotificationDate
        disableNotifications
        createdDate
        createdBy
        modifiedDate
        modifiedBy
        crmId
        crmLastModified
      }
      ChosedVariants {
        id
        idBuyingGroup
        idUser
        idVariant
        idReseller
        quantity
        dateChosed
        userChoosingCustomValues
        dateConfirmed
        userConfirmationCustomValues
        dateFinalization
        resellerSellingCustomValues
        totalSoldAmount
        dateCanceled
        cancellationPhase
        cancellationReason
        dateDelivered
        dateBilled
        commisionAmount
        createdDate
        createdBy
        modifiedDate
        modifiedBy
        crmId
        crmLastModified
      }
      createdDate
      createdBy
      modifiedDate
      modifiedBy
      crmId
      crmLastModified
    }
  }
`;
export const deleteCategory = /* GraphQL */ `
  mutation DeleteCategory($id: String!) {
    deleteCategory(id: $id) {
      id
      idParentCategory
      name
      description
      isPublic
      createdDate
      createdBy
      modifiedDate
      modifiedBy
      crmId
      crmLastModified
    }
  }
`;
export const createCategory = /* GraphQL */ `
  mutation CreateCategory($createCategoryInput: CreateCategoryInput!) {
    createCategory(createCategoryInput: $createCategoryInput) {
      id
      idParentCategory
      name
      description
      isPublic
      createdDate
      createdBy
      modifiedDate
      modifiedBy
      crmId
      crmLastModified
    }
  }
`;
export const updateCategory = /* GraphQL */ `
  mutation UpdateCategory($updateCategoryInput: UpdateCategoryInput!) {
    updateCategory(updateCategoryInput: $updateCategoryInput) {
      id
      idParentCategory
      name
      description
      isPublic
      createdDate
      createdBy
      modifiedDate
      modifiedBy
      crmId
      crmLastModified
    }
  }
`;
export const deleteCategoryHasGoodOrService = /* GraphQL */ `
  mutation DeleteCategoryHasGoodOrService($id: String!) {
    deleteCategory_has_good_or_service(id: $id) {
      id
      idCategory
      idGoodOrService
      createdDate
      createdBy
      modifiedDate
      modifiedBy
      crmId
      crmLastModified
    }
  }
`;
export const createCategoryHasGoodOrService = /* GraphQL */ `
  mutation CreateCategoryHasGoodOrService(
    $createCategory_has_good_or_serviceInput: CreateCategory_has_good_or_serviceInput!
  ) {
    createCategory_has_good_or_service(
      createCategory_has_good_or_serviceInput: $createCategory_has_good_or_serviceInput
    ) {
      id
      idCategory
      idGoodOrService
      createdDate
      createdBy
      modifiedDate
      modifiedBy
      crmId
      crmLastModified
    }
  }
`;
export const updateCategoryHasGoodOrService = /* GraphQL */ `
  mutation UpdateCategoryHasGoodOrService(
    $updateCategory_has_good_or_serviceInput: UpdateCategory_has_good_or_serviceInput!
  ) {
    updateCategory_has_good_or_service(
      updateCategory_has_good_or_serviceInput: $updateCategory_has_good_or_serviceInput
    ) {
      id
      idCategory
      idGoodOrService
      createdDate
      createdBy
      modifiedDate
      modifiedBy
      crmId
      crmLastModified
    }
  }
`;
export const deleteChosedVariant = /* GraphQL */ `
  mutation DeleteChosedVariant($id: String!) {
    deleteChosed_variant(id: $id) {
      id
      idBuyingGroup
      idUser
      idVariant
      idReseller
      User {
        id
        username
        identityId
        idAffiliation
        idOrganization
        email
        emailVerificationCode
        emailVerificationDate
        emailVerified
        displayName
        firstName
        lastName
        gender
        birthDate
        birthPlace
        roles
        mobile
        mobileVerificationCode
        mobileVerificationDate
        mobileVerified
        country
        state
        city
        zipCode
        address1
        taxCode
        avatarUrl
        declaredOrigin
        discountCode
        acptProfiling
        updtProfiling
        acptMarketing
        updtMarketing
        acptNewsletter
        updtNewsletter
        blockProcessing
        updtProcessing
        acptStatute
        updtStatute
        subscribedUntil
        subscribedSince
        stripeCustomerId
        firstLoginDate
        lastLoginDate
        createdDate
        createdBy
        modifiedDate
        modifiedBy
        crmId
        crmLastModified
        toBeDeletedAfter
      }
      BuyingGroup {
        id
        idOrganization
        name
        description
        uidCMS
        pubblicationStartDate
        registrationStartDate
        participationStartDate
        choosingStartDate
        registrationEndDate
        confirmationStartDate
        resellerFlowStartDate
        pubblicationEndDate
        participationEndDate
        choosingEndDate
        confirmationEndDate
        resellerFlowEndDate
        maxChoosableVariants
        uniteChooseAndConfirm
        declination
        templateSuffix
        isOnlyUpselling
        createdDate
        createdBy
        modifiedDate
        modifiedBy
        crmId
        crmLastModified
      }
      Variant {
        id
        idGoodOrService
        idBuyingGroup
        code
        name
        description
        maxQuantity
        discountAmountEach
        commissionAmountEach
        discountPercent
        commissionPercent
        createdDate
        createdBy
        modifiedDate
        modifiedBy
        crmId
        crmLastModified
      }
      Reseller {
        id
        name
        organizationType
        isLocation
        isPlaceHolder
        country
        state
        city
        zipCode
        address1
        VATNumber
        SDICode
        PECEmail
        brands
        notificationsEmail
        notifyOnEachSell
        createdDate
        createdBy
        modifiedDate
        modifiedBy
        crmId
        crmLastModified
      }
      quantity
      dateChosed
      userChoosingCustomValues
      dateConfirmed
      userConfirmationCustomValues
      dateFinalization
      resellerSellingCustomValues
      totalSoldAmount
      dateCanceled
      cancellationPhase
      cancellationReason
      dateDelivered
      dateBilled
      commisionAmount
      UserInBuyingGroup {
        id
        idBuyingGroup
        idUser
        registrationDate
        participationDate
        choosingDate
        notInterestInBuying
        confirmationDate
        definitelyNotInterestedInBuying
        lastEmaiNotificationDate
        disableNotifications
        createdDate
        createdBy
        modifiedDate
        modifiedBy
        crmId
        crmLastModified
      }
      createdDate
      createdBy
      modifiedDate
      modifiedBy
      crmId
      crmLastModified
    }
  }
`;
export const createChosedVariant = /* GraphQL */ `
  mutation CreateChosedVariant(
    $createChosed_variantInput: CreateChosed_variantInput!
  ) {
    createChosed_variant(
      createChosed_variantInput: $createChosed_variantInput
    ) {
      id
      idBuyingGroup
      idUser
      idVariant
      idReseller
      User {
        id
        username
        identityId
        idAffiliation
        idOrganization
        email
        emailVerificationCode
        emailVerificationDate
        emailVerified
        displayName
        firstName
        lastName
        gender
        birthDate
        birthPlace
        roles
        mobile
        mobileVerificationCode
        mobileVerificationDate
        mobileVerified
        country
        state
        city
        zipCode
        address1
        taxCode
        avatarUrl
        declaredOrigin
        discountCode
        acptProfiling
        updtProfiling
        acptMarketing
        updtMarketing
        acptNewsletter
        updtNewsletter
        blockProcessing
        updtProcessing
        acptStatute
        updtStatute
        subscribedUntil
        subscribedSince
        stripeCustomerId
        firstLoginDate
        lastLoginDate
        createdDate
        createdBy
        modifiedDate
        modifiedBy
        crmId
        crmLastModified
        toBeDeletedAfter
      }
      BuyingGroup {
        id
        idOrganization
        name
        description
        uidCMS
        pubblicationStartDate
        registrationStartDate
        participationStartDate
        choosingStartDate
        registrationEndDate
        confirmationStartDate
        resellerFlowStartDate
        pubblicationEndDate
        participationEndDate
        choosingEndDate
        confirmationEndDate
        resellerFlowEndDate
        maxChoosableVariants
        uniteChooseAndConfirm
        declination
        templateSuffix
        isOnlyUpselling
        createdDate
        createdBy
        modifiedDate
        modifiedBy
        crmId
        crmLastModified
      }
      Variant {
        id
        idGoodOrService
        idBuyingGroup
        code
        name
        description
        maxQuantity
        discountAmountEach
        commissionAmountEach
        discountPercent
        commissionPercent
        createdDate
        createdBy
        modifiedDate
        modifiedBy
        crmId
        crmLastModified
      }
      Reseller {
        id
        name
        organizationType
        isLocation
        isPlaceHolder
        country
        state
        city
        zipCode
        address1
        VATNumber
        SDICode
        PECEmail
        brands
        notificationsEmail
        notifyOnEachSell
        createdDate
        createdBy
        modifiedDate
        modifiedBy
        crmId
        crmLastModified
      }
      quantity
      dateChosed
      userChoosingCustomValues
      dateConfirmed
      userConfirmationCustomValues
      dateFinalization
      resellerSellingCustomValues
      totalSoldAmount
      dateCanceled
      cancellationPhase
      cancellationReason
      dateDelivered
      dateBilled
      commisionAmount
      UserInBuyingGroup {
        id
        idBuyingGroup
        idUser
        registrationDate
        participationDate
        choosingDate
        notInterestInBuying
        confirmationDate
        definitelyNotInterestedInBuying
        lastEmaiNotificationDate
        disableNotifications
        createdDate
        createdBy
        modifiedDate
        modifiedBy
        crmId
        crmLastModified
      }
      createdDate
      createdBy
      modifiedDate
      modifiedBy
      crmId
      crmLastModified
    }
  }
`;
export const updateChosedVariant = /* GraphQL */ `
  mutation UpdateChosedVariant(
    $updateChosed_variantInput: UpdateChosed_variantInput!
  ) {
    updateChosed_variant(
      updateChosed_variantInput: $updateChosed_variantInput
    ) {
      id
      idBuyingGroup
      idUser
      idVariant
      idReseller
      User {
        id
        username
        identityId
        idAffiliation
        idOrganization
        email
        emailVerificationCode
        emailVerificationDate
        emailVerified
        displayName
        firstName
        lastName
        gender
        birthDate
        birthPlace
        roles
        mobile
        mobileVerificationCode
        mobileVerificationDate
        mobileVerified
        country
        state
        city
        zipCode
        address1
        taxCode
        avatarUrl
        declaredOrigin
        discountCode
        acptProfiling
        updtProfiling
        acptMarketing
        updtMarketing
        acptNewsletter
        updtNewsletter
        blockProcessing
        updtProcessing
        acptStatute
        updtStatute
        subscribedUntil
        subscribedSince
        stripeCustomerId
        firstLoginDate
        lastLoginDate
        createdDate
        createdBy
        modifiedDate
        modifiedBy
        crmId
        crmLastModified
        toBeDeletedAfter
      }
      BuyingGroup {
        id
        idOrganization
        name
        description
        uidCMS
        pubblicationStartDate
        registrationStartDate
        participationStartDate
        choosingStartDate
        registrationEndDate
        confirmationStartDate
        resellerFlowStartDate
        pubblicationEndDate
        participationEndDate
        choosingEndDate
        confirmationEndDate
        resellerFlowEndDate
        maxChoosableVariants
        uniteChooseAndConfirm
        declination
        templateSuffix
        isOnlyUpselling
        createdDate
        createdBy
        modifiedDate
        modifiedBy
        crmId
        crmLastModified
      }
      Variant {
        id
        idGoodOrService
        idBuyingGroup
        code
        name
        description
        maxQuantity
        discountAmountEach
        commissionAmountEach
        discountPercent
        commissionPercent
        createdDate
        createdBy
        modifiedDate
        modifiedBy
        crmId
        crmLastModified
      }
      Reseller {
        id
        name
        organizationType
        isLocation
        isPlaceHolder
        country
        state
        city
        zipCode
        address1
        VATNumber
        SDICode
        PECEmail
        brands
        notificationsEmail
        notifyOnEachSell
        createdDate
        createdBy
        modifiedDate
        modifiedBy
        crmId
        crmLastModified
      }
      quantity
      dateChosed
      userChoosingCustomValues
      dateConfirmed
      userConfirmationCustomValues
      dateFinalization
      resellerSellingCustomValues
      totalSoldAmount
      dateCanceled
      cancellationPhase
      cancellationReason
      dateDelivered
      dateBilled
      commisionAmount
      UserInBuyingGroup {
        id
        idBuyingGroup
        idUser
        registrationDate
        participationDate
        choosingDate
        notInterestInBuying
        confirmationDate
        definitelyNotInterestedInBuying
        lastEmaiNotificationDate
        disableNotifications
        createdDate
        createdBy
        modifiedDate
        modifiedBy
        crmId
        crmLastModified
      }
      createdDate
      createdBy
      modifiedDate
      modifiedBy
      crmId
      crmLastModified
    }
  }
`;
export const deleteCommission = /* GraphQL */ `
  mutation DeleteCommission($id: String!) {
    deleteCommission(id: $id) {
      id
      idAffiliation
      idCategory
      startDate
      endDate
      percent
      amountEach
      createdDate
      createdBy
      modifiedDate
      modifiedBy
      crmId
      crmLastModified
    }
  }
`;
export const createCommission = /* GraphQL */ `
  mutation CreateCommission($createCommissionInput: CreateCommissionInput!) {
    createCommission(createCommissionInput: $createCommissionInput) {
      id
      idAffiliation
      idCategory
      startDate
      endDate
      percent
      amountEach
      createdDate
      createdBy
      modifiedDate
      modifiedBy
      crmId
      crmLastModified
    }
  }
`;
export const updateCommission = /* GraphQL */ `
  mutation UpdateCommission($updateCommissionInput: UpdateCommissionInput!) {
    updateCommission(updateCommissionInput: $updateCommissionInput) {
      id
      idAffiliation
      idCategory
      startDate
      endDate
      percent
      amountEach
      createdDate
      createdBy
      modifiedDate
      modifiedBy
      crmId
      crmLastModified
    }
  }
`;
export const deleteEthicoinTransaction = /* GraphQL */ `
  mutation DeleteEthicoinTransaction($id: String!) {
    deleteEthicoin_transaction(id: $id) {
      id
      idAffiliation
      entryDate
      amount
      reason
      createdDate
      createdBy
      modifiedDate
      modifiedBy
      crmId
      crmLastModified
    }
  }
`;
export const createEthicoinTransaction = /* GraphQL */ `
  mutation CreateEthicoinTransaction(
    $createEthicoin_transactionInput: CreateEthicoin_transactionInput!
  ) {
    createEthicoin_transaction(
      createEthicoin_transactionInput: $createEthicoin_transactionInput
    ) {
      id
      idAffiliation
      entryDate
      amount
      reason
      createdDate
      createdBy
      modifiedDate
      modifiedBy
      crmId
      crmLastModified
    }
  }
`;
export const updateEthicoinTransaction = /* GraphQL */ `
  mutation UpdateEthicoinTransaction(
    $updateEthicoin_transactionInput: UpdateEthicoin_transactionInput!
  ) {
    updateEthicoin_transaction(
      updateEthicoin_transactionInput: $updateEthicoin_transactionInput
    ) {
      id
      idAffiliation
      entryDate
      amount
      reason
      createdDate
      createdBy
      modifiedDate
      modifiedBy
      crmId
      crmLastModified
    }
  }
`;
export const createFormSubmission = /* GraphQL */ `
  mutation CreateFormSubmission(
    $createForm_submissionInput: CreateForm_submissionInput!
  ) {
    createForm_submission(
      createForm_submissionInput: $createForm_submissionInput
    ) {
      id
      idUser
      jsonData
      formName
      result
      sourceIp
      identityId
      referer
      userAgent
      createdDate
      createdBy
    }
  }
`;
export const deleteGoodOrService = /* GraphQL */ `
  mutation DeleteGoodOrService($id: String!) {
    deleteGood_or_service(id: $id) {
      id
      idGoodOrServiceType
      GoodOrServiceType {
        id
        name
        slug
        description
        userChoosingCustomFieldsDef
        userConfirmationCustomFieldsDef
        resellerSellingCustomFieldsDef
        isGood
        isService
        createdDate
        createdBy
        modifiedDate
        modifiedBy
        crmId
        crmLastModified
      }
      name
      slug
      description
      idVendor
      GoodsOrServicesInBuyingGroups {
        id
        idBuyingGroup
        idGoodOrService
        createdDate
        createdBy
        modifiedDate
        modifiedBy
        crmId
        crmLastModified
      }
      createdDate
      createdBy
      modifiedDate
      modifiedBy
      crmId
      crmLastModified
    }
  }
`;
export const createGoodOrService = /* GraphQL */ `
  mutation CreateGoodOrService(
    $createGood_or_serviceInput: CreateGood_or_serviceInput!
  ) {
    createGood_or_service(
      createGood_or_serviceInput: $createGood_or_serviceInput
    ) {
      id
      idGoodOrServiceType
      GoodOrServiceType {
        id
        name
        slug
        description
        userChoosingCustomFieldsDef
        userConfirmationCustomFieldsDef
        resellerSellingCustomFieldsDef
        isGood
        isService
        createdDate
        createdBy
        modifiedDate
        modifiedBy
        crmId
        crmLastModified
      }
      name
      slug
      description
      idVendor
      GoodsOrServicesInBuyingGroups {
        id
        idBuyingGroup
        idGoodOrService
        createdDate
        createdBy
        modifiedDate
        modifiedBy
        crmId
        crmLastModified
      }
      createdDate
      createdBy
      modifiedDate
      modifiedBy
      crmId
      crmLastModified
    }
  }
`;
export const updateGoodOrService = /* GraphQL */ `
  mutation UpdateGoodOrService(
    $updateGood_or_serviceInput: UpdateGood_or_serviceInput!
  ) {
    updateGood_or_service(
      updateGood_or_serviceInput: $updateGood_or_serviceInput
    ) {
      id
      idGoodOrServiceType
      GoodOrServiceType {
        id
        name
        slug
        description
        userChoosingCustomFieldsDef
        userConfirmationCustomFieldsDef
        resellerSellingCustomFieldsDef
        isGood
        isService
        createdDate
        createdBy
        modifiedDate
        modifiedBy
        crmId
        crmLastModified
      }
      name
      slug
      description
      idVendor
      GoodsOrServicesInBuyingGroups {
        id
        idBuyingGroup
        idGoodOrService
        createdDate
        createdBy
        modifiedDate
        modifiedBy
        crmId
        crmLastModified
      }
      createdDate
      createdBy
      modifiedDate
      modifiedBy
      crmId
      crmLastModified
    }
  }
`;
export const deleteGoodOrServiceInBuyingGroup = /* GraphQL */ `
  mutation DeleteGoodOrServiceInBuyingGroup($id: String!) {
    deleteGood_or_service_in_buying_group(id: $id) {
      id
      idBuyingGroup
      idGoodOrService
      BuyingGroup {
        id
        idOrganization
        name
        description
        uidCMS
        pubblicationStartDate
        registrationStartDate
        participationStartDate
        choosingStartDate
        registrationEndDate
        confirmationStartDate
        resellerFlowStartDate
        pubblicationEndDate
        participationEndDate
        choosingEndDate
        confirmationEndDate
        resellerFlowEndDate
        maxChoosableVariants
        uniteChooseAndConfirm
        declination
        templateSuffix
        isOnlyUpselling
        createdDate
        createdBy
        modifiedDate
        modifiedBy
        crmId
        crmLastModified
      }
      GoodOrService {
        id
        idGoodOrServiceType
        name
        slug
        description
        idVendor
        createdDate
        createdBy
        modifiedDate
        modifiedBy
        crmId
        crmLastModified
      }
      Variants {
        id
        idGoodOrService
        idBuyingGroup
        code
        name
        description
        maxQuantity
        discountAmountEach
        commissionAmountEach
        discountPercent
        commissionPercent
        createdDate
        createdBy
        modifiedDate
        modifiedBy
        crmId
        crmLastModified
      }
      createdDate
      createdBy
      modifiedDate
      modifiedBy
      crmId
      crmLastModified
    }
  }
`;
export const createGoodOrServiceInBuyingGroup = /* GraphQL */ `
  mutation CreateGoodOrServiceInBuyingGroup(
    $createGood_or_service_in_buying_groupInput: CreateGood_or_service_in_buying_groupInput!
  ) {
    createGood_or_service_in_buying_group(
      createGood_or_service_in_buying_groupInput: $createGood_or_service_in_buying_groupInput
    ) {
      id
      idBuyingGroup
      idGoodOrService
      BuyingGroup {
        id
        idOrganization
        name
        description
        uidCMS
        pubblicationStartDate
        registrationStartDate
        participationStartDate
        choosingStartDate
        registrationEndDate
        confirmationStartDate
        resellerFlowStartDate
        pubblicationEndDate
        participationEndDate
        choosingEndDate
        confirmationEndDate
        resellerFlowEndDate
        maxChoosableVariants
        uniteChooseAndConfirm
        declination
        templateSuffix
        isOnlyUpselling
        createdDate
        createdBy
        modifiedDate
        modifiedBy
        crmId
        crmLastModified
      }
      GoodOrService {
        id
        idGoodOrServiceType
        name
        slug
        description
        idVendor
        createdDate
        createdBy
        modifiedDate
        modifiedBy
        crmId
        crmLastModified
      }
      Variants {
        id
        idGoodOrService
        idBuyingGroup
        code
        name
        description
        maxQuantity
        discountAmountEach
        commissionAmountEach
        discountPercent
        commissionPercent
        createdDate
        createdBy
        modifiedDate
        modifiedBy
        crmId
        crmLastModified
      }
      createdDate
      createdBy
      modifiedDate
      modifiedBy
      crmId
      crmLastModified
    }
  }
`;
export const updateGoodOrServiceInBuyingGroup = /* GraphQL */ `
  mutation UpdateGoodOrServiceInBuyingGroup(
    $updateGood_or_service_in_buying_groupInput: UpdateGood_or_service_in_buying_groupInput!
  ) {
    updateGood_or_service_in_buying_group(
      updateGood_or_service_in_buying_groupInput: $updateGood_or_service_in_buying_groupInput
    ) {
      id
      idBuyingGroup
      idGoodOrService
      BuyingGroup {
        id
        idOrganization
        name
        description
        uidCMS
        pubblicationStartDate
        registrationStartDate
        participationStartDate
        choosingStartDate
        registrationEndDate
        confirmationStartDate
        resellerFlowStartDate
        pubblicationEndDate
        participationEndDate
        choosingEndDate
        confirmationEndDate
        resellerFlowEndDate
        maxChoosableVariants
        uniteChooseAndConfirm
        declination
        templateSuffix
        isOnlyUpselling
        createdDate
        createdBy
        modifiedDate
        modifiedBy
        crmId
        crmLastModified
      }
      GoodOrService {
        id
        idGoodOrServiceType
        name
        slug
        description
        idVendor
        createdDate
        createdBy
        modifiedDate
        modifiedBy
        crmId
        crmLastModified
      }
      Variants {
        id
        idGoodOrService
        idBuyingGroup
        code
        name
        description
        maxQuantity
        discountAmountEach
        commissionAmountEach
        discountPercent
        commissionPercent
        createdDate
        createdBy
        modifiedDate
        modifiedBy
        crmId
        crmLastModified
      }
      createdDate
      createdBy
      modifiedDate
      modifiedBy
      crmId
      crmLastModified
    }
  }
`;
export const deleteGoodOrServiceType = /* GraphQL */ `
  mutation DeleteGoodOrServiceType($id: String!) {
    deleteGood_or_service_type(id: $id) {
      id
      name
      slug
      description
      userChoosingCustomFieldsDef
      userConfirmationCustomFieldsDef
      resellerSellingCustomFieldsDef
      isGood
      isService
      GoodsOrServices {
        id
        idGoodOrServiceType
        name
        slug
        description
        idVendor
        createdDate
        createdBy
        modifiedDate
        modifiedBy
        crmId
        crmLastModified
      }
      createdDate
      createdBy
      modifiedDate
      modifiedBy
      crmId
      crmLastModified
    }
  }
`;
export const createGoodOrServiceType = /* GraphQL */ `
  mutation CreateGoodOrServiceType(
    $createGood_or_service_typeInput: CreateGood_or_service_typeInput!
  ) {
    createGood_or_service_type(
      createGood_or_service_typeInput: $createGood_or_service_typeInput
    ) {
      id
      name
      slug
      description
      userChoosingCustomFieldsDef
      userConfirmationCustomFieldsDef
      resellerSellingCustomFieldsDef
      isGood
      isService
      GoodsOrServices {
        id
        idGoodOrServiceType
        name
        slug
        description
        idVendor
        createdDate
        createdBy
        modifiedDate
        modifiedBy
        crmId
        crmLastModified
      }
      createdDate
      createdBy
      modifiedDate
      modifiedBy
      crmId
      crmLastModified
    }
  }
`;
export const updateGoodOrServiceType = /* GraphQL */ `
  mutation UpdateGoodOrServiceType(
    $updateGood_or_service_typeInput: UpdateGood_or_service_typeInput!
  ) {
    updateGood_or_service_type(
      updateGood_or_service_typeInput: $updateGood_or_service_typeInput
    ) {
      id
      name
      slug
      description
      userChoosingCustomFieldsDef
      userConfirmationCustomFieldsDef
      resellerSellingCustomFieldsDef
      isGood
      isService
      GoodsOrServices {
        id
        idGoodOrServiceType
        name
        slug
        description
        idVendor
        createdDate
        createdBy
        modifiedDate
        modifiedBy
        crmId
        crmLastModified
      }
      createdDate
      createdBy
      modifiedDate
      modifiedBy
      crmId
      crmLastModified
    }
  }
`;
export const deleteOrganization = /* GraphQL */ `
  mutation DeleteOrganization($id: String!) {
    deleteOrganization(id: $id) {
      id
      name
      organizationType
      isLocation
      isPlaceHolder
      country
      state
      city
      zipCode
      address1
      VATNumber
      SDICode
      PECEmail
      brands
      notificationsEmail
      notifyOnEachSell
      ResellerIn {
        id
        idBuyingGroup
        idReseller
        createdDate
        createdBy
        modifiedDate
        modifiedBy
        crmId
        crmLastModified
      }
      OrganizedBuyingGroups {
        id
        idOrganization
        name
        description
        uidCMS
        pubblicationStartDate
        registrationStartDate
        participationStartDate
        choosingStartDate
        registrationEndDate
        confirmationStartDate
        resellerFlowStartDate
        pubblicationEndDate
        participationEndDate
        choosingEndDate
        confirmationEndDate
        resellerFlowEndDate
        maxChoosableVariants
        uniteChooseAndConfirm
        declination
        templateSuffix
        isOnlyUpselling
        createdDate
        createdBy
        modifiedDate
        modifiedBy
        crmId
        crmLastModified
      }
      ManagingUsers {
        id
        username
        identityId
        idAffiliation
        idOrganization
        email
        emailVerificationCode
        emailVerificationDate
        emailVerified
        displayName
        firstName
        lastName
        gender
        birthDate
        birthPlace
        roles
        mobile
        mobileVerificationCode
        mobileVerificationDate
        mobileVerified
        country
        state
        city
        zipCode
        address1
        taxCode
        avatarUrl
        declaredOrigin
        discountCode
        acptProfiling
        updtProfiling
        acptMarketing
        updtMarketing
        acptNewsletter
        updtNewsletter
        blockProcessing
        updtProcessing
        acptStatute
        updtStatute
        subscribedUntil
        subscribedSince
        stripeCustomerId
        firstLoginDate
        lastLoginDate
        createdDate
        createdBy
        modifiedDate
        modifiedBy
        crmId
        crmLastModified
        toBeDeletedAfter
      }
      createdDate
      createdBy
      modifiedDate
      modifiedBy
      crmId
      crmLastModified
    }
  }
`;
export const createOrganization = /* GraphQL */ `
  mutation CreateOrganization(
    $createOrganizationInput: CreateOrganizationInput!
  ) {
    createOrganization(createOrganizationInput: $createOrganizationInput) {
      id
      name
      organizationType
      isLocation
      isPlaceHolder
      country
      state
      city
      zipCode
      address1
      VATNumber
      SDICode
      PECEmail
      brands
      notificationsEmail
      notifyOnEachSell
      ResellerIn {
        id
        idBuyingGroup
        idReseller
        createdDate
        createdBy
        modifiedDate
        modifiedBy
        crmId
        crmLastModified
      }
      OrganizedBuyingGroups {
        id
        idOrganization
        name
        description
        uidCMS
        pubblicationStartDate
        registrationStartDate
        participationStartDate
        choosingStartDate
        registrationEndDate
        confirmationStartDate
        resellerFlowStartDate
        pubblicationEndDate
        participationEndDate
        choosingEndDate
        confirmationEndDate
        resellerFlowEndDate
        maxChoosableVariants
        uniteChooseAndConfirm
        declination
        templateSuffix
        isOnlyUpselling
        createdDate
        createdBy
        modifiedDate
        modifiedBy
        crmId
        crmLastModified
      }
      ManagingUsers {
        id
        username
        identityId
        idAffiliation
        idOrganization
        email
        emailVerificationCode
        emailVerificationDate
        emailVerified
        displayName
        firstName
        lastName
        gender
        birthDate
        birthPlace
        roles
        mobile
        mobileVerificationCode
        mobileVerificationDate
        mobileVerified
        country
        state
        city
        zipCode
        address1
        taxCode
        avatarUrl
        declaredOrigin
        discountCode
        acptProfiling
        updtProfiling
        acptMarketing
        updtMarketing
        acptNewsletter
        updtNewsletter
        blockProcessing
        updtProcessing
        acptStatute
        updtStatute
        subscribedUntil
        subscribedSince
        stripeCustomerId
        firstLoginDate
        lastLoginDate
        createdDate
        createdBy
        modifiedDate
        modifiedBy
        crmId
        crmLastModified
        toBeDeletedAfter
      }
      createdDate
      createdBy
      modifiedDate
      modifiedBy
      crmId
      crmLastModified
    }
  }
`;
export const updateOrganization = /* GraphQL */ `
  mutation UpdateOrganization(
    $updateOrganizationInput: UpdateOrganizationInput!
  ) {
    updateOrganization(updateOrganizationInput: $updateOrganizationInput) {
      id
      name
      organizationType
      isLocation
      isPlaceHolder
      country
      state
      city
      zipCode
      address1
      VATNumber
      SDICode
      PECEmail
      brands
      notificationsEmail
      notifyOnEachSell
      ResellerIn {
        id
        idBuyingGroup
        idReseller
        createdDate
        createdBy
        modifiedDate
        modifiedBy
        crmId
        crmLastModified
      }
      OrganizedBuyingGroups {
        id
        idOrganization
        name
        description
        uidCMS
        pubblicationStartDate
        registrationStartDate
        participationStartDate
        choosingStartDate
        registrationEndDate
        confirmationStartDate
        resellerFlowStartDate
        pubblicationEndDate
        participationEndDate
        choosingEndDate
        confirmationEndDate
        resellerFlowEndDate
        maxChoosableVariants
        uniteChooseAndConfirm
        declination
        templateSuffix
        isOnlyUpselling
        createdDate
        createdBy
        modifiedDate
        modifiedBy
        crmId
        crmLastModified
      }
      ManagingUsers {
        id
        username
        identityId
        idAffiliation
        idOrganization
        email
        emailVerificationCode
        emailVerificationDate
        emailVerified
        displayName
        firstName
        lastName
        gender
        birthDate
        birthPlace
        roles
        mobile
        mobileVerificationCode
        mobileVerificationDate
        mobileVerified
        country
        state
        city
        zipCode
        address1
        taxCode
        avatarUrl
        declaredOrigin
        discountCode
        acptProfiling
        updtProfiling
        acptMarketing
        updtMarketing
        acptNewsletter
        updtNewsletter
        blockProcessing
        updtProcessing
        acptStatute
        updtStatute
        subscribedUntil
        subscribedSince
        stripeCustomerId
        firstLoginDate
        lastLoginDate
        createdDate
        createdBy
        modifiedDate
        modifiedBy
        crmId
        crmLastModified
        toBeDeletedAfter
      }
      createdDate
      createdBy
      modifiedDate
      modifiedBy
      crmId
      crmLastModified
    }
  }
`;
export const deletePayment = /* GraphQL */ `
  mutation DeletePayment($id: String!) {
    deletePayment(id: $id) {
      id
      idUser
      stripeSessionId
      paymentTime
      amount_subtotal
      amount_total
      currency
      payment_status
      mode
      stripeCustomerId
      stripeSubscriptionId
      Subscriptions {
        id
        idUser
        stripeSubscriptionId
        stripeCustomerId
        startDate
        endDate
        status
        cancel_at_period_end
        canceledAt
        stripeProductId
        stripePriceId
        amount
        createdDate
        createdBy
        modifiedDate
        modifiedBy
        crmId
        crmLastModified
      }
      User {
        id
        username
        identityId
        idAffiliation
        idOrganization
        email
        emailVerificationCode
        emailVerificationDate
        emailVerified
        displayName
        firstName
        lastName
        gender
        birthDate
        birthPlace
        roles
        mobile
        mobileVerificationCode
        mobileVerificationDate
        mobileVerified
        country
        state
        city
        zipCode
        address1
        taxCode
        avatarUrl
        declaredOrigin
        discountCode
        acptProfiling
        updtProfiling
        acptMarketing
        updtMarketing
        acptNewsletter
        updtNewsletter
        blockProcessing
        updtProcessing
        acptStatute
        updtStatute
        subscribedUntil
        subscribedSince
        stripeCustomerId
        firstLoginDate
        lastLoginDate
        createdDate
        createdBy
        modifiedDate
        modifiedBy
        crmId
        crmLastModified
        toBeDeletedAfter
      }
      createdDate
      createdBy
      modifiedDate
      modifiedBy
      crmId
      crmLastModified
    }
  }
`;
export const createPayment = /* GraphQL */ `
  mutation CreatePayment($createPaymentInput: CreatePaymentInput!) {
    createPayment(createPaymentInput: $createPaymentInput) {
      id
      idUser
      stripeSessionId
      paymentTime
      amount_subtotal
      amount_total
      currency
      payment_status
      mode
      stripeCustomerId
      stripeSubscriptionId
      Subscriptions {
        id
        idUser
        stripeSubscriptionId
        stripeCustomerId
        startDate
        endDate
        status
        cancel_at_period_end
        canceledAt
        stripeProductId
        stripePriceId
        amount
        createdDate
        createdBy
        modifiedDate
        modifiedBy
        crmId
        crmLastModified
      }
      User {
        id
        username
        identityId
        idAffiliation
        idOrganization
        email
        emailVerificationCode
        emailVerificationDate
        emailVerified
        displayName
        firstName
        lastName
        gender
        birthDate
        birthPlace
        roles
        mobile
        mobileVerificationCode
        mobileVerificationDate
        mobileVerified
        country
        state
        city
        zipCode
        address1
        taxCode
        avatarUrl
        declaredOrigin
        discountCode
        acptProfiling
        updtProfiling
        acptMarketing
        updtMarketing
        acptNewsletter
        updtNewsletter
        blockProcessing
        updtProcessing
        acptStatute
        updtStatute
        subscribedUntil
        subscribedSince
        stripeCustomerId
        firstLoginDate
        lastLoginDate
        createdDate
        createdBy
        modifiedDate
        modifiedBy
        crmId
        crmLastModified
        toBeDeletedAfter
      }
      createdDate
      createdBy
      modifiedDate
      modifiedBy
      crmId
      crmLastModified
    }
  }
`;
export const updatePayment = /* GraphQL */ `
  mutation UpdatePayment($updatePaymentInput: UpdatePaymentInput!) {
    updatePayment(updatePaymentInput: $updatePaymentInput) {
      id
      idUser
      stripeSessionId
      paymentTime
      amount_subtotal
      amount_total
      currency
      payment_status
      mode
      stripeCustomerId
      stripeSubscriptionId
      Subscriptions {
        id
        idUser
        stripeSubscriptionId
        stripeCustomerId
        startDate
        endDate
        status
        cancel_at_period_end
        canceledAt
        stripeProductId
        stripePriceId
        amount
        createdDate
        createdBy
        modifiedDate
        modifiedBy
        crmId
        crmLastModified
      }
      User {
        id
        username
        identityId
        idAffiliation
        idOrganization
        email
        emailVerificationCode
        emailVerificationDate
        emailVerified
        displayName
        firstName
        lastName
        gender
        birthDate
        birthPlace
        roles
        mobile
        mobileVerificationCode
        mobileVerificationDate
        mobileVerified
        country
        state
        city
        zipCode
        address1
        taxCode
        avatarUrl
        declaredOrigin
        discountCode
        acptProfiling
        updtProfiling
        acptMarketing
        updtMarketing
        acptNewsletter
        updtNewsletter
        blockProcessing
        updtProcessing
        acptStatute
        updtStatute
        subscribedUntil
        subscribedSince
        stripeCustomerId
        firstLoginDate
        lastLoginDate
        createdDate
        createdBy
        modifiedDate
        modifiedBy
        crmId
        crmLastModified
        toBeDeletedAfter
      }
      createdDate
      createdBy
      modifiedDate
      modifiedBy
      crmId
      crmLastModified
    }
  }
`;
export const deleteReseller = /* GraphQL */ `
  mutation DeleteReseller($id: String!) {
    deleteReseller(id: $id) {
      id
      idBuyingGroup
      idReseller
      BuyingGroup {
        id
        idOrganization
        name
        description
        uidCMS
        pubblicationStartDate
        registrationStartDate
        participationStartDate
        choosingStartDate
        registrationEndDate
        confirmationStartDate
        resellerFlowStartDate
        pubblicationEndDate
        participationEndDate
        choosingEndDate
        confirmationEndDate
        resellerFlowEndDate
        maxChoosableVariants
        uniteChooseAndConfirm
        declination
        templateSuffix
        isOnlyUpselling
        createdDate
        createdBy
        modifiedDate
        modifiedBy
        crmId
        crmLastModified
      }
      Organization {
        id
        name
        organizationType
        isLocation
        isPlaceHolder
        country
        state
        city
        zipCode
        address1
        VATNumber
        SDICode
        PECEmail
        brands
        notificationsEmail
        notifyOnEachSell
        createdDate
        createdBy
        modifiedDate
        modifiedBy
        crmId
        crmLastModified
      }
      createdDate
      createdBy
      modifiedDate
      modifiedBy
      crmId
      crmLastModified
    }
  }
`;
export const createReseller = /* GraphQL */ `
  mutation CreateReseller($createResellerInput: CreateResellerInput!) {
    createReseller(createResellerInput: $createResellerInput) {
      id
      idBuyingGroup
      idReseller
      BuyingGroup {
        id
        idOrganization
        name
        description
        uidCMS
        pubblicationStartDate
        registrationStartDate
        participationStartDate
        choosingStartDate
        registrationEndDate
        confirmationStartDate
        resellerFlowStartDate
        pubblicationEndDate
        participationEndDate
        choosingEndDate
        confirmationEndDate
        resellerFlowEndDate
        maxChoosableVariants
        uniteChooseAndConfirm
        declination
        templateSuffix
        isOnlyUpselling
        createdDate
        createdBy
        modifiedDate
        modifiedBy
        crmId
        crmLastModified
      }
      Organization {
        id
        name
        organizationType
        isLocation
        isPlaceHolder
        country
        state
        city
        zipCode
        address1
        VATNumber
        SDICode
        PECEmail
        brands
        notificationsEmail
        notifyOnEachSell
        createdDate
        createdBy
        modifiedDate
        modifiedBy
        crmId
        crmLastModified
      }
      createdDate
      createdBy
      modifiedDate
      modifiedBy
      crmId
      crmLastModified
    }
  }
`;
export const updateReseller = /* GraphQL */ `
  mutation UpdateReseller($updateResellerInput: UpdateResellerInput!) {
    updateReseller(updateResellerInput: $updateResellerInput) {
      id
      idBuyingGroup
      idReseller
      BuyingGroup {
        id
        idOrganization
        name
        description
        uidCMS
        pubblicationStartDate
        registrationStartDate
        participationStartDate
        choosingStartDate
        registrationEndDate
        confirmationStartDate
        resellerFlowStartDate
        pubblicationEndDate
        participationEndDate
        choosingEndDate
        confirmationEndDate
        resellerFlowEndDate
        maxChoosableVariants
        uniteChooseAndConfirm
        declination
        templateSuffix
        isOnlyUpselling
        createdDate
        createdBy
        modifiedDate
        modifiedBy
        crmId
        crmLastModified
      }
      Organization {
        id
        name
        organizationType
        isLocation
        isPlaceHolder
        country
        state
        city
        zipCode
        address1
        VATNumber
        SDICode
        PECEmail
        brands
        notificationsEmail
        notifyOnEachSell
        createdDate
        createdBy
        modifiedDate
        modifiedBy
        crmId
        crmLastModified
      }
      createdDate
      createdBy
      modifiedDate
      modifiedBy
      crmId
      crmLastModified
    }
  }
`;
export const deleteResellerHasVariant = /* GraphQL */ `
  mutation DeleteResellerHasVariant($id: String!) {
    deleteReseller_has_variant(id: $id) {
      id
      idReseller
      idVariant
      createdDate
      createdBy
      modifiedDate
      modifiedBy
      crmId
      crmLastModified
    }
  }
`;
export const createResellerHasVariant = /* GraphQL */ `
  mutation CreateResellerHasVariant(
    $createReseller_has_variantInput: CreateReseller_has_variantInput!
  ) {
    createReseller_has_variant(
      createReseller_has_variantInput: $createReseller_has_variantInput
    ) {
      id
      idReseller
      idVariant
      createdDate
      createdBy
      modifiedDate
      modifiedBy
      crmId
      crmLastModified
    }
  }
`;
export const updateResellerHasVariant = /* GraphQL */ `
  mutation UpdateResellerHasVariant(
    $updateReseller_has_variantInput: UpdateReseller_has_variantInput!
  ) {
    updateReseller_has_variant(
      updateReseller_has_variantInput: $updateReseller_has_variantInput
    ) {
      id
      idReseller
      idVariant
      createdDate
      createdBy
      modifiedDate
      modifiedBy
      crmId
      crmLastModified
    }
  }
`;
export const deleteSubscription = /* GraphQL */ `
  mutation DeleteSubscription($id: String!) {
    deleteSubscription(id: $id) {
      id
      idUser
      stripeSubscriptionId
      stripeCustomerId
      startDate
      endDate
      status
      cancel_at_period_end
      canceledAt
      stripeProductId
      stripePriceId
      amount
      Payments {
        id
        idUser
        stripeSessionId
        paymentTime
        amount_subtotal
        amount_total
        currency
        payment_status
        mode
        stripeCustomerId
        stripeSubscriptionId
        createdDate
        createdBy
        modifiedDate
        modifiedBy
        crmId
        crmLastModified
      }
      User {
        id
        username
        identityId
        idAffiliation
        idOrganization
        email
        emailVerificationCode
        emailVerificationDate
        emailVerified
        displayName
        firstName
        lastName
        gender
        birthDate
        birthPlace
        roles
        mobile
        mobileVerificationCode
        mobileVerificationDate
        mobileVerified
        country
        state
        city
        zipCode
        address1
        taxCode
        avatarUrl
        declaredOrigin
        discountCode
        acptProfiling
        updtProfiling
        acptMarketing
        updtMarketing
        acptNewsletter
        updtNewsletter
        blockProcessing
        updtProcessing
        acptStatute
        updtStatute
        subscribedUntil
        subscribedSince
        stripeCustomerId
        firstLoginDate
        lastLoginDate
        createdDate
        createdBy
        modifiedDate
        modifiedBy
        crmId
        crmLastModified
        toBeDeletedAfter
      }
      createdDate
      createdBy
      modifiedDate
      modifiedBy
      crmId
      crmLastModified
    }
  }
`;
export const createSubscription = /* GraphQL */ `
  mutation CreateSubscription(
    $createSubscriptionInput: CreateSubscriptionInput!
  ) {
    createSubscription(createSubscriptionInput: $createSubscriptionInput) {
      id
      idUser
      stripeSubscriptionId
      stripeCustomerId
      startDate
      endDate
      status
      cancel_at_period_end
      canceledAt
      stripeProductId
      stripePriceId
      amount
      Payments {
        id
        idUser
        stripeSessionId
        paymentTime
        amount_subtotal
        amount_total
        currency
        payment_status
        mode
        stripeCustomerId
        stripeSubscriptionId
        createdDate
        createdBy
        modifiedDate
        modifiedBy
        crmId
        crmLastModified
      }
      User {
        id
        username
        identityId
        idAffiliation
        idOrganization
        email
        emailVerificationCode
        emailVerificationDate
        emailVerified
        displayName
        firstName
        lastName
        gender
        birthDate
        birthPlace
        roles
        mobile
        mobileVerificationCode
        mobileVerificationDate
        mobileVerified
        country
        state
        city
        zipCode
        address1
        taxCode
        avatarUrl
        declaredOrigin
        discountCode
        acptProfiling
        updtProfiling
        acptMarketing
        updtMarketing
        acptNewsletter
        updtNewsletter
        blockProcessing
        updtProcessing
        acptStatute
        updtStatute
        subscribedUntil
        subscribedSince
        stripeCustomerId
        firstLoginDate
        lastLoginDate
        createdDate
        createdBy
        modifiedDate
        modifiedBy
        crmId
        crmLastModified
        toBeDeletedAfter
      }
      createdDate
      createdBy
      modifiedDate
      modifiedBy
      crmId
      crmLastModified
    }
  }
`;
export const updateSubscription = /* GraphQL */ `
  mutation UpdateSubscription(
    $updateSubscriptionInput: UpdateSubscriptionInput!
  ) {
    updateSubscription(updateSubscriptionInput: $updateSubscriptionInput) {
      id
      idUser
      stripeSubscriptionId
      stripeCustomerId
      startDate
      endDate
      status
      cancel_at_period_end
      canceledAt
      stripeProductId
      stripePriceId
      amount
      Payments {
        id
        idUser
        stripeSessionId
        paymentTime
        amount_subtotal
        amount_total
        currency
        payment_status
        mode
        stripeCustomerId
        stripeSubscriptionId
        createdDate
        createdBy
        modifiedDate
        modifiedBy
        crmId
        crmLastModified
      }
      User {
        id
        username
        identityId
        idAffiliation
        idOrganization
        email
        emailVerificationCode
        emailVerificationDate
        emailVerified
        displayName
        firstName
        lastName
        gender
        birthDate
        birthPlace
        roles
        mobile
        mobileVerificationCode
        mobileVerificationDate
        mobileVerified
        country
        state
        city
        zipCode
        address1
        taxCode
        avatarUrl
        declaredOrigin
        discountCode
        acptProfiling
        updtProfiling
        acptMarketing
        updtMarketing
        acptNewsletter
        updtNewsletter
        blockProcessing
        updtProcessing
        acptStatute
        updtStatute
        subscribedUntil
        subscribedSince
        stripeCustomerId
        firstLoginDate
        lastLoginDate
        createdDate
        createdBy
        modifiedDate
        modifiedBy
        crmId
        crmLastModified
        toBeDeletedAfter
      }
      createdDate
      createdBy
      modifiedDate
      modifiedBy
      crmId
      crmLastModified
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser($id: String!) {
    deleteUser(id: $id) {
      id
      username
      identityId
      idAffiliation
      idOrganization
      Organization {
        id
        name
        organizationType
        isLocation
        isPlaceHolder
        country
        state
        city
        zipCode
        address1
        VATNumber
        SDICode
        PECEmail
        brands
        notificationsEmail
        notifyOnEachSell
        createdDate
        createdBy
        modifiedDate
        modifiedBy
        crmId
        crmLastModified
      }
      email
      emailVerificationCode
      emailVerificationDate
      emailVerified
      displayName
      firstName
      lastName
      gender
      birthDate
      birthPlace
      roles
      mobile
      mobileVerificationCode
      mobileVerificationDate
      mobileVerified
      country
      state
      city
      zipCode
      address1
      taxCode
      avatarUrl
      declaredOrigin
      discountCode
      acptProfiling
      updtProfiling
      acptMarketing
      updtMarketing
      acptNewsletter
      updtNewsletter
      blockProcessing
      updtProcessing
      acptStatute
      updtStatute
      subscribedUntil
      subscribedSince
      stripeCustomerId
      firstLoginDate
      lastLoginDate
      UserInBuyingGroups {
        id
        idBuyingGroup
        idUser
        registrationDate
        participationDate
        choosingDate
        notInterestInBuying
        confirmationDate
        definitelyNotInterestedInBuying
        lastEmaiNotificationDate
        disableNotifications
        createdDate
        createdBy
        modifiedDate
        modifiedBy
        crmId
        crmLastModified
      }
      ChosedVariants {
        id
        idBuyingGroup
        idUser
        idVariant
        idReseller
        quantity
        dateChosed
        userChoosingCustomValues
        dateConfirmed
        userConfirmationCustomValues
        dateFinalization
        resellerSellingCustomValues
        totalSoldAmount
        dateCanceled
        cancellationPhase
        cancellationReason
        dateDelivered
        dateBilled
        commisionAmount
        createdDate
        createdBy
        modifiedDate
        modifiedBy
        crmId
        crmLastModified
      }
      Payments {
        id
        idUser
        stripeSessionId
        paymentTime
        amount_subtotal
        amount_total
        currency
        payment_status
        mode
        stripeCustomerId
        stripeSubscriptionId
        createdDate
        createdBy
        modifiedDate
        modifiedBy
        crmId
        crmLastModified
      }
      Subscriptions {
        id
        idUser
        stripeSubscriptionId
        stripeCustomerId
        startDate
        endDate
        status
        cancel_at_period_end
        canceledAt
        stripeProductId
        stripePriceId
        amount
        createdDate
        createdBy
        modifiedDate
        modifiedBy
        crmId
        crmLastModified
      }
      createdDate
      createdBy
      modifiedDate
      modifiedBy
      crmId
      crmLastModified
      toBeDeletedAfter
    }
  }
`;
export const createUser = /* GraphQL */ `
  mutation CreateUser($createUserInput: CreateUserInput!) {
    createUser(createUserInput: $createUserInput) {
      id
      username
      identityId
      idAffiliation
      idOrganization
      Organization {
        id
        name
        organizationType
        isLocation
        isPlaceHolder
        country
        state
        city
        zipCode
        address1
        VATNumber
        SDICode
        PECEmail
        brands
        notificationsEmail
        notifyOnEachSell
        createdDate
        createdBy
        modifiedDate
        modifiedBy
        crmId
        crmLastModified
      }
      email
      emailVerificationCode
      emailVerificationDate
      emailVerified
      displayName
      firstName
      lastName
      gender
      birthDate
      birthPlace
      roles
      mobile
      mobileVerificationCode
      mobileVerificationDate
      mobileVerified
      country
      state
      city
      zipCode
      address1
      taxCode
      avatarUrl
      declaredOrigin
      discountCode
      acptProfiling
      updtProfiling
      acptMarketing
      updtMarketing
      acptNewsletter
      updtNewsletter
      blockProcessing
      updtProcessing
      acptStatute
      updtStatute
      subscribedUntil
      subscribedSince
      stripeCustomerId
      firstLoginDate
      lastLoginDate
      UserInBuyingGroups {
        id
        idBuyingGroup
        idUser
        registrationDate
        participationDate
        choosingDate
        notInterestInBuying
        confirmationDate
        definitelyNotInterestedInBuying
        lastEmaiNotificationDate
        disableNotifications
        createdDate
        createdBy
        modifiedDate
        modifiedBy
        crmId
        crmLastModified
      }
      ChosedVariants {
        id
        idBuyingGroup
        idUser
        idVariant
        idReseller
        quantity
        dateChosed
        userChoosingCustomValues
        dateConfirmed
        userConfirmationCustomValues
        dateFinalization
        resellerSellingCustomValues
        totalSoldAmount
        dateCanceled
        cancellationPhase
        cancellationReason
        dateDelivered
        dateBilled
        commisionAmount
        createdDate
        createdBy
        modifiedDate
        modifiedBy
        crmId
        crmLastModified
      }
      Payments {
        id
        idUser
        stripeSessionId
        paymentTime
        amount_subtotal
        amount_total
        currency
        payment_status
        mode
        stripeCustomerId
        stripeSubscriptionId
        createdDate
        createdBy
        modifiedDate
        modifiedBy
        crmId
        crmLastModified
      }
      Subscriptions {
        id
        idUser
        stripeSubscriptionId
        stripeCustomerId
        startDate
        endDate
        status
        cancel_at_period_end
        canceledAt
        stripeProductId
        stripePriceId
        amount
        createdDate
        createdBy
        modifiedDate
        modifiedBy
        crmId
        crmLastModified
      }
      createdDate
      createdBy
      modifiedDate
      modifiedBy
      crmId
      crmLastModified
      toBeDeletedAfter
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser($updateUserInput: UpdateUserInput!) {
    updateUser(updateUserInput: $updateUserInput) {
      id
      username
      identityId
      idAffiliation
      idOrganization
      Organization {
        id
        name
        organizationType
        isLocation
        isPlaceHolder
        country
        state
        city
        zipCode
        address1
        VATNumber
        SDICode
        PECEmail
        brands
        notificationsEmail
        notifyOnEachSell
        createdDate
        createdBy
        modifiedDate
        modifiedBy
        crmId
        crmLastModified
      }
      email
      emailVerificationCode
      emailVerificationDate
      emailVerified
      displayName
      firstName
      lastName
      gender
      birthDate
      birthPlace
      roles
      mobile
      mobileVerificationCode
      mobileVerificationDate
      mobileVerified
      country
      state
      city
      zipCode
      address1
      taxCode
      avatarUrl
      declaredOrigin
      discountCode
      acptProfiling
      updtProfiling
      acptMarketing
      updtMarketing
      acptNewsletter
      updtNewsletter
      blockProcessing
      updtProcessing
      acptStatute
      updtStatute
      subscribedUntil
      subscribedSince
      stripeCustomerId
      firstLoginDate
      lastLoginDate
      UserInBuyingGroups {
        id
        idBuyingGroup
        idUser
        registrationDate
        participationDate
        choosingDate
        notInterestInBuying
        confirmationDate
        definitelyNotInterestedInBuying
        lastEmaiNotificationDate
        disableNotifications
        createdDate
        createdBy
        modifiedDate
        modifiedBy
        crmId
        crmLastModified
      }
      ChosedVariants {
        id
        idBuyingGroup
        idUser
        idVariant
        idReseller
        quantity
        dateChosed
        userChoosingCustomValues
        dateConfirmed
        userConfirmationCustomValues
        dateFinalization
        resellerSellingCustomValues
        totalSoldAmount
        dateCanceled
        cancellationPhase
        cancellationReason
        dateDelivered
        dateBilled
        commisionAmount
        createdDate
        createdBy
        modifiedDate
        modifiedBy
        crmId
        crmLastModified
      }
      Payments {
        id
        idUser
        stripeSessionId
        paymentTime
        amount_subtotal
        amount_total
        currency
        payment_status
        mode
        stripeCustomerId
        stripeSubscriptionId
        createdDate
        createdBy
        modifiedDate
        modifiedBy
        crmId
        crmLastModified
      }
      Subscriptions {
        id
        idUser
        stripeSubscriptionId
        stripeCustomerId
        startDate
        endDate
        status
        cancel_at_period_end
        canceledAt
        stripeProductId
        stripePriceId
        amount
        createdDate
        createdBy
        modifiedDate
        modifiedBy
        crmId
        crmLastModified
      }
      createdDate
      createdBy
      modifiedDate
      modifiedBy
      crmId
      crmLastModified
      toBeDeletedAfter
    }
  }
`;
export const deleteUserInBuyingGroup = /* GraphQL */ `
  mutation DeleteUserInBuyingGroup($id: String!) {
    deleteUser_in_buying_group(id: $id) {
      id
      idBuyingGroup
      idUser
      User {
        id
        username
        identityId
        idAffiliation
        idOrganization
        email
        emailVerificationCode
        emailVerificationDate
        emailVerified
        displayName
        firstName
        lastName
        gender
        birthDate
        birthPlace
        roles
        mobile
        mobileVerificationCode
        mobileVerificationDate
        mobileVerified
        country
        state
        city
        zipCode
        address1
        taxCode
        avatarUrl
        declaredOrigin
        discountCode
        acptProfiling
        updtProfiling
        acptMarketing
        updtMarketing
        acptNewsletter
        updtNewsletter
        blockProcessing
        updtProcessing
        acptStatute
        updtStatute
        subscribedUntil
        subscribedSince
        stripeCustomerId
        firstLoginDate
        lastLoginDate
        createdDate
        createdBy
        modifiedDate
        modifiedBy
        crmId
        crmLastModified
        toBeDeletedAfter
      }
      BuyingGroup {
        id
        idOrganization
        name
        description
        uidCMS
        pubblicationStartDate
        registrationStartDate
        participationStartDate
        choosingStartDate
        registrationEndDate
        confirmationStartDate
        resellerFlowStartDate
        pubblicationEndDate
        participationEndDate
        choosingEndDate
        confirmationEndDate
        resellerFlowEndDate
        maxChoosableVariants
        uniteChooseAndConfirm
        declination
        templateSuffix
        isOnlyUpselling
        createdDate
        createdBy
        modifiedDate
        modifiedBy
        crmId
        crmLastModified
      }
      registrationDate
      participationDate
      choosingDate
      notInterestInBuying
      confirmationDate
      definitelyNotInterestedInBuying
      lastEmaiNotificationDate
      disableNotifications
      ChosedVariants {
        id
        idBuyingGroup
        idUser
        idVariant
        idReseller
        quantity
        dateChosed
        userChoosingCustomValues
        dateConfirmed
        userConfirmationCustomValues
        dateFinalization
        resellerSellingCustomValues
        totalSoldAmount
        dateCanceled
        cancellationPhase
        cancellationReason
        dateDelivered
        dateBilled
        commisionAmount
        createdDate
        createdBy
        modifiedDate
        modifiedBy
        crmId
        crmLastModified
      }
      createdDate
      createdBy
      modifiedDate
      modifiedBy
      crmId
      crmLastModified
    }
  }
`;
export const createUserInBuyingGroup = /* GraphQL */ `
  mutation CreateUserInBuyingGroup(
    $createUser_in_buying_groupInput: CreateUser_in_buying_groupInput!
  ) {
    createUser_in_buying_group(
      createUser_in_buying_groupInput: $createUser_in_buying_groupInput
    ) {
      id
      idBuyingGroup
      idUser
      User {
        id
        username
        identityId
        idAffiliation
        idOrganization
        email
        emailVerificationCode
        emailVerificationDate
        emailVerified
        displayName
        firstName
        lastName
        gender
        birthDate
        birthPlace
        roles
        mobile
        mobileVerificationCode
        mobileVerificationDate
        mobileVerified
        country
        state
        city
        zipCode
        address1
        taxCode
        avatarUrl
        declaredOrigin
        discountCode
        acptProfiling
        updtProfiling
        acptMarketing
        updtMarketing
        acptNewsletter
        updtNewsletter
        blockProcessing
        updtProcessing
        acptStatute
        updtStatute
        subscribedUntil
        subscribedSince
        stripeCustomerId
        firstLoginDate
        lastLoginDate
        createdDate
        createdBy
        modifiedDate
        modifiedBy
        crmId
        crmLastModified
        toBeDeletedAfter
      }
      BuyingGroup {
        id
        idOrganization
        name
        description
        uidCMS
        pubblicationStartDate
        registrationStartDate
        participationStartDate
        choosingStartDate
        registrationEndDate
        confirmationStartDate
        resellerFlowStartDate
        pubblicationEndDate
        participationEndDate
        choosingEndDate
        confirmationEndDate
        resellerFlowEndDate
        maxChoosableVariants
        uniteChooseAndConfirm
        declination
        templateSuffix
        isOnlyUpselling
        createdDate
        createdBy
        modifiedDate
        modifiedBy
        crmId
        crmLastModified
      }
      registrationDate
      participationDate
      choosingDate
      notInterestInBuying
      confirmationDate
      definitelyNotInterestedInBuying
      lastEmaiNotificationDate
      disableNotifications
      ChosedVariants {
        id
        idBuyingGroup
        idUser
        idVariant
        idReseller
        quantity
        dateChosed
        userChoosingCustomValues
        dateConfirmed
        userConfirmationCustomValues
        dateFinalization
        resellerSellingCustomValues
        totalSoldAmount
        dateCanceled
        cancellationPhase
        cancellationReason
        dateDelivered
        dateBilled
        commisionAmount
        createdDate
        createdBy
        modifiedDate
        modifiedBy
        crmId
        crmLastModified
      }
      createdDate
      createdBy
      modifiedDate
      modifiedBy
      crmId
      crmLastModified
    }
  }
`;
export const updateUserInBuyingGroup = /* GraphQL */ `
  mutation UpdateUserInBuyingGroup(
    $updateUser_in_buying_groupInput: UpdateUser_in_buying_groupInput!
  ) {
    updateUser_in_buying_group(
      updateUser_in_buying_groupInput: $updateUser_in_buying_groupInput
    ) {
      id
      idBuyingGroup
      idUser
      User {
        id
        username
        identityId
        idAffiliation
        idOrganization
        email
        emailVerificationCode
        emailVerificationDate
        emailVerified
        displayName
        firstName
        lastName
        gender
        birthDate
        birthPlace
        roles
        mobile
        mobileVerificationCode
        mobileVerificationDate
        mobileVerified
        country
        state
        city
        zipCode
        address1
        taxCode
        avatarUrl
        declaredOrigin
        discountCode
        acptProfiling
        updtProfiling
        acptMarketing
        updtMarketing
        acptNewsletter
        updtNewsletter
        blockProcessing
        updtProcessing
        acptStatute
        updtStatute
        subscribedUntil
        subscribedSince
        stripeCustomerId
        firstLoginDate
        lastLoginDate
        createdDate
        createdBy
        modifiedDate
        modifiedBy
        crmId
        crmLastModified
        toBeDeletedAfter
      }
      BuyingGroup {
        id
        idOrganization
        name
        description
        uidCMS
        pubblicationStartDate
        registrationStartDate
        participationStartDate
        choosingStartDate
        registrationEndDate
        confirmationStartDate
        resellerFlowStartDate
        pubblicationEndDate
        participationEndDate
        choosingEndDate
        confirmationEndDate
        resellerFlowEndDate
        maxChoosableVariants
        uniteChooseAndConfirm
        declination
        templateSuffix
        isOnlyUpselling
        createdDate
        createdBy
        modifiedDate
        modifiedBy
        crmId
        crmLastModified
      }
      registrationDate
      participationDate
      choosingDate
      notInterestInBuying
      confirmationDate
      definitelyNotInterestedInBuying
      lastEmaiNotificationDate
      disableNotifications
      ChosedVariants {
        id
        idBuyingGroup
        idUser
        idVariant
        idReseller
        quantity
        dateChosed
        userChoosingCustomValues
        dateConfirmed
        userConfirmationCustomValues
        dateFinalization
        resellerSellingCustomValues
        totalSoldAmount
        dateCanceled
        cancellationPhase
        cancellationReason
        dateDelivered
        dateBilled
        commisionAmount
        createdDate
        createdBy
        modifiedDate
        modifiedBy
        crmId
        crmLastModified
      }
      createdDate
      createdBy
      modifiedDate
      modifiedBy
      crmId
      crmLastModified
    }
  }
`;
export const deleteVariant = /* GraphQL */ `
  mutation DeleteVariant($id: String!) {
    deleteVariant(id: $id) {
      id
      idGoodOrService
      idBuyingGroup
      BuyingGroup {
        id
        idOrganization
        name
        description
        uidCMS
        pubblicationStartDate
        registrationStartDate
        participationStartDate
        choosingStartDate
        registrationEndDate
        confirmationStartDate
        resellerFlowStartDate
        pubblicationEndDate
        participationEndDate
        choosingEndDate
        confirmationEndDate
        resellerFlowEndDate
        maxChoosableVariants
        uniteChooseAndConfirm
        declination
        templateSuffix
        isOnlyUpselling
        createdDate
        createdBy
        modifiedDate
        modifiedBy
        crmId
        crmLastModified
      }
      GoodOrService {
        id
        idGoodOrServiceType
        name
        slug
        description
        idVendor
        createdDate
        createdBy
        modifiedDate
        modifiedBy
        crmId
        crmLastModified
      }
      code
      name
      description
      maxQuantity
      discountAmountEach
      commissionAmountEach
      discountPercent
      commissionPercent
      createdDate
      createdBy
      modifiedDate
      modifiedBy
      crmId
      crmLastModified
    }
  }
`;
export const createVariant = /* GraphQL */ `
  mutation CreateVariant($createVariantInput: CreateVariantInput!) {
    createVariant(createVariantInput: $createVariantInput) {
      id
      idGoodOrService
      idBuyingGroup
      BuyingGroup {
        id
        idOrganization
        name
        description
        uidCMS
        pubblicationStartDate
        registrationStartDate
        participationStartDate
        choosingStartDate
        registrationEndDate
        confirmationStartDate
        resellerFlowStartDate
        pubblicationEndDate
        participationEndDate
        choosingEndDate
        confirmationEndDate
        resellerFlowEndDate
        maxChoosableVariants
        uniteChooseAndConfirm
        declination
        templateSuffix
        isOnlyUpselling
        createdDate
        createdBy
        modifiedDate
        modifiedBy
        crmId
        crmLastModified
      }
      GoodOrService {
        id
        idGoodOrServiceType
        name
        slug
        description
        idVendor
        createdDate
        createdBy
        modifiedDate
        modifiedBy
        crmId
        crmLastModified
      }
      code
      name
      description
      maxQuantity
      discountAmountEach
      commissionAmountEach
      discountPercent
      commissionPercent
      createdDate
      createdBy
      modifiedDate
      modifiedBy
      crmId
      crmLastModified
    }
  }
`;
export const updateVariant = /* GraphQL */ `
  mutation UpdateVariant($updateVariantInput: UpdateVariantInput!) {
    updateVariant(updateVariantInput: $updateVariantInput) {
      id
      idGoodOrService
      idBuyingGroup
      BuyingGroup {
        id
        idOrganization
        name
        description
        uidCMS
        pubblicationStartDate
        registrationStartDate
        participationStartDate
        choosingStartDate
        registrationEndDate
        confirmationStartDate
        resellerFlowStartDate
        pubblicationEndDate
        participationEndDate
        choosingEndDate
        confirmationEndDate
        resellerFlowEndDate
        maxChoosableVariants
        uniteChooseAndConfirm
        declination
        templateSuffix
        isOnlyUpselling
        createdDate
        createdBy
        modifiedDate
        modifiedBy
        crmId
        crmLastModified
      }
      GoodOrService {
        id
        idGoodOrServiceType
        name
        slug
        description
        idVendor
        createdDate
        createdBy
        modifiedDate
        modifiedBy
        crmId
        crmLastModified
      }
      code
      name
      description
      maxQuantity
      discountAmountEach
      commissionAmountEach
      discountPercent
      commissionPercent
      createdDate
      createdBy
      modifiedDate
      modifiedBy
      crmId
      crmLastModified
    }
  }
`;
export const deleteVariantHasUpselling = /* GraphQL */ `
  mutation DeleteVariantHasUpselling($id: String!) {
    deleteVariant_has_upselling(id: $id) {
      id
      idVariant
      idBuyingGroup
      specificVariants
      createdDate
      createdBy
      modifiedDate
      modifiedBy
      crmId
      crmLastModified
    }
  }
`;
export const createVariantHasUpselling = /* GraphQL */ `
  mutation CreateVariantHasUpselling(
    $createVariant_has_upsellingInput: CreateVariant_has_upsellingInput!
  ) {
    createVariant_has_upselling(
      createVariant_has_upsellingInput: $createVariant_has_upsellingInput
    ) {
      id
      idVariant
      idBuyingGroup
      specificVariants
      createdDate
      createdBy
      modifiedDate
      modifiedBy
      crmId
      crmLastModified
    }
  }
`;
export const updateVariantHasUpselling = /* GraphQL */ `
  mutation UpdateVariantHasUpselling(
    $updateVariant_has_upsellingInput: UpdateVariant_has_upsellingInput!
  ) {
    updateVariant_has_upselling(
      updateVariant_has_upsellingInput: $updateVariant_has_upsellingInput
    ) {
      id
      idVariant
      idBuyingGroup
      specificVariants
      createdDate
      createdBy
      modifiedDate
      modifiedBy
      crmId
      crmLastModified
    }
  }
`;
export const createEmailEvent = /* GraphQL */ `
  mutation CreateEmailEvent(
    $input: CreateEmailEventInput!
    $condition: ModelEmailEventConditionInput
  ) {
    createEmailEvent(input: $input, condition: $condition) {
      id
      client_id
      address
      message_id
      type
      date
      feedback
      subject
      createdAt
      updatedAt
    }
  }
`;
export const updateEmailEvent = /* GraphQL */ `
  mutation UpdateEmailEvent(
    $input: UpdateEmailEventInput!
    $condition: ModelEmailEventConditionInput
  ) {
    updateEmailEvent(input: $input, condition: $condition) {
      id
      client_id
      address
      message_id
      type
      date
      feedback
      subject
      createdAt
      updatedAt
    }
  }
`;
export const deleteEmailEvent = /* GraphQL */ `
  mutation DeleteEmailEvent(
    $input: DeleteEmailEventInput!
    $condition: ModelEmailEventConditionInput
  ) {
    deleteEmailEvent(input: $input, condition: $condition) {
      id
      client_id
      address
      message_id
      type
      date
      feedback
      subject
      createdAt
      updatedAt
    }
  }
`;
