import React, { useState } from "react"

export const Tabs = (props: { tabs: string[]; className?: string; onChange?: Function; selected?: number }) => {
  const [activeId, setActiveId] = useState(props.selected || 0)

  const changeActive = (id) => {
    setActiveId(id)
    if (props.onChange) props.onChange(id)
  }

  return (
    <div className={"tabs__nav" + (props.className ? " " + props.className : "")}>
      <ul className="tabs__nav__list">
        {props.tabs.map((title, idx) => (
          <li
            key={idx}
            className={"tabs__nav__item" + (activeId == idx ? " tabs__nav__item--active" : "")}
            onClick={() => {
              changeActive(idx)
            }}
          >
            {title}
          </li>
        ))}
      </ul>
    </div>
  )
}

export default Tabs
