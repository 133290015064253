// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-app-tsx": () => import("./../../../src/pages/app.tsx" /* webpackChunkName: "component---src-pages-app-tsx" */),
  "component---src-pages-auto-tsx": () => import("./../../../src/pages/auto.tsx" /* webpackChunkName: "component---src-pages-auto-tsx" */),
  "component---src-pages-blog-tsx": () => import("./../../../src/pages/blog.tsx" /* webpackChunkName: "component---src-pages-blog-tsx" */),
  "component---src-pages-faq-tsx": () => import("./../../../src/pages/faq.tsx" /* webpackChunkName: "component---src-pages-faq-tsx" */),
  "component---src-pages-logout-tsx": () => import("./../../../src/pages/logout.tsx" /* webpackChunkName: "component---src-pages-logout-tsx" */),
  "component---src-pages-preferences-tsx": () => import("./../../../src/pages/preferences.tsx" /* webpackChunkName: "component---src-pages-preferences-tsx" */),
  "component---src-pages-rassegna-stampa-tsx": () => import("./../../../src/pages/rassegna-stampa.tsx" /* webpackChunkName: "component---src-pages-rassegna-stampa-tsx" */),
  "component---src-pages-redirect-tsx": () => import("./../../../src/pages/redirect.tsx" /* webpackChunkName: "component---src-pages-redirect-tsx" */),
  "component---src-pages-testimonianze-tsx": () => import("./../../../src/pages/testimonianze.tsx" /* webpackChunkName: "component---src-pages-testimonianze-tsx" */),
  "component---src-pages-video-tsx": () => import("./../../../src/pages/video.tsx" /* webpackChunkName: "component---src-pages-video-tsx" */),
  "component---src-templates-crm-wp-buying-group-tsx": () => import("./../../../src/templates/crmWpBuyingGroup.tsx" /* webpackChunkName: "component---src-templates-crm-wp-buying-group-tsx" */),
  "component---src-templates-wp-blog-category-tsx": () => import("./../../../src/templates/wpBlogCategory.tsx" /* webpackChunkName: "component---src-templates-wp-blog-category-tsx" */),
  "component---src-templates-wp-blog-tag-tsx": () => import("./../../../src/templates/wpBlogTag.tsx" /* webpackChunkName: "component---src-templates-wp-blog-tag-tsx" */),
  "component---src-templates-wp-car-tsx": () => import("./../../../src/templates/wpCar.tsx" /* webpackChunkName: "component---src-templates-wp-car-tsx" */),
  "component---src-templates-wp-page-tsx": () => import("./../../../src/templates/wpPage.tsx" /* webpackChunkName: "component---src-templates-wp-page-tsx" */),
  "component---src-templates-wp-post-tsx": () => import("./../../../src/templates/wpPost.tsx" /* webpackChunkName: "component---src-templates-wp-post-tsx" */)
}

