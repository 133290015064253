import { createStore, combineReducers, applyMiddleware } from "redux"

import authReducer from "./reducers/auth"
import layoutReducer from "./reducers/layout"
import carsReducer from "./reducers/cars"

import logger from "./middlewares/logger"

import { composeWithDevTools } from "redux-devtools-extension/logOnlyInProduction"

import thunk from "redux-thunk"

const rootReducer = combineReducers({
  auth: authReducer,
  layout: layoutReducer,
  cars: carsReducer,
})

const composeEnhancers = composeWithDevTools({
  // options like actionSanitizer, stateSanitizer
})

const store = createStore(
  rootReducer,
  /* preloadedState, */ composeEnhancers(
    applyMiddleware(/*logger,*/ thunk)
    // other store enhancers if any
  )
)

export default store
