import React from "react"
import { withPrefix, WrapPageElementBrowserArgs } from "gatsby"
// @ts-ignore
import browserLang from "browser-lang"
import { I18NextContext, LANGUAGE_KEY, PageContext, PluginOptions } from "../types"
import i18next, { i18n as I18n } from "i18next"
import { I18nextProvider } from "react-i18next"
import { I18nextContext } from "../i18nextContext"
import intervalPlural from "i18next-intervalplural-postprocessor"

import pluginOptions from "../options"

const i18n = i18next.createInstance()

const withI18next = (i18n: I18n, context: I18NextContext) => (children: any) => {
  return (
    <I18nextProvider i18n={i18n}>
      <I18nextContext.Provider value={context}>{children}</I18nextContext.Provider>
    </I18nextProvider>
  )
}

export const i18nWrapPageElement = ({ element, props }: WrapPageElementBrowserArgs<any, PageContext>) => {
  const { i18nextOptions = {}, redirect = true, siteUrl } = pluginOptions

  if (!props) return
  const { pageContext, location } = props
  let {
    routed,
    language,
    languages,
    originalPath,
    defaultLanguage,
    ignoreLanguage,
    resources,
    path,
    uid,
    uidPagesMap,
    uidPagesMapStringified,
  } = pageContext.i18n
  let detected
  const isRedirect = redirect && !routed

  if (isRedirect) {
    const { search } = location

    // Skip build, Browsers only
    if (typeof window !== "undefined") {
      detected =
        window.localStorage.getItem(LANGUAGE_KEY) ||
        browserLang({
          languages,
          fallback: language,
        })

      // Skip if ignoreLanguage is true: do not redirect and do not change current language
      if (!ignoreLanguage) {
        if (!languages.includes(detected)) {
          detected = language
        }

        window.localStorage.setItem(LANGUAGE_KEY, detected)

        if (detected !== defaultLanguage) {
          const to = uid || originalPath || location.pathname
          const queryParams = search || ""
          let newUrl = ""
          // Handle redirect with pagesMap if uid present
          if (uidPagesMap[to]) {
            // redirect to correct language or to home if doesn't exists
            newUrl = withPrefix((uidPagesMap[to][detected] || "/") + queryParams)
          } else {
            newUrl = withPrefix(`/${detected}${location.pathname}${queryParams}`)
          }
          window.location.replace(newUrl)
          return null
        }
      }
    }
  }
  // Update language for ignoreLanguage pages to use detected language
  language = ignoreLanguage && detected != undefined ? detected : language

  if (!i18n.isInitialized) {
    i18n.use(intervalPlural).init({
      ...i18nextOptions,
      lng: language,
      fallbackLng: defaultLanguage,
      resources,
      react: {
        useSuspense: false,
      },
    })
  }

  Object.keys(resources[language]).map((ns) => {
    if (!i18n.hasResourceBundle(language, ns)) {
      i18n.addResourceBundle(language, ns, resources[language][ns])
    }
  })

  if (i18n.language !== language) {
    i18n.changeLanguage(language)
  }

  const context = {
    routed,
    language,
    languages,
    originalPath,
    defaultLanguage,
    siteUrl,
    path,
    uid,
    uidPagesMap,
    uidPagesMapStringified,
  }

  return withI18next(i18n, context)(element)
}
