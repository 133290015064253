import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { useI18next } from "."
import { Link as GatsbyLink, GatsbyLinkProps } from "gatsby"
import { LANGUAGE_KEY } from "./types"
import { useMatch, useLocation } from "@reach/router"

export type LinkProps = GatsbyLinkProps<any> & { language?: string; onClickDoNothing?: boolean; finalLinkUpdateCallback?: Function }

export const Link: React.FC<LinkProps> = ({
  language,
  to,
  onClick,
  target,
  children,
  partiallyActive,
  onClickDoNothing,
  className,
  finalLinkUpdateCallback,
  ...rest
}) => {
  const i18n = useI18next()
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          wordpress {
            baseUrl
            uploadsUrl
            prefixesMap
          }
        }
      }
    }
  `)

  const wordPressUrl = data.site.siteMetadata.wordpress.baseUrl
  const uploadsUrl = data.site.siteMetadata.wordpress.uploadsUrl
  const prefixesMap = JSON.parse(data.site.siteMetadata.wordpress.prefixesMap)
  const urlLanguage = language || i18n.language

  // If we have a link with http(s):// and then a string without dots (.)
  // then is the cms that added the http(s) but it's an UID an not an FQDN
  const checkForWrongHttp = to ? to.match(/^https?:\/\/([^./]+)$/) : null
  if (Array.isArray(checkForWrongHttp)) to = checkForWrongHttp[1]

  // Check if an url is pointing to a Wordpress CMS URL (NOT to the uploads folder)
  // and try to convert it based on language, posttype and slug
  if (to && to.indexOf(wordPressUrl) != -1 && to.indexOf(uploadsUrl) == -1) {
    const [url, language, postType, slug] = to.match(/https?:\/\/[^/]+\/([^/]+)\/([^/]+)\/([^/]+)(\/)?/)
    const prefix = prefixesMap[postType]

    //console.log("URL: ", language, postType, slug)
    const matchingKey = Object.keys(i18n.uidPagesMap).find((key) => {
      // Prefix matches and there are no other ":" in the key
      if (key.indexOf(prefix) == 0 && key.substr(prefix.length).indexOf(":") == -1) {
        return i18n.uidPagesMap[key][language] && i18n.uidPagesMap[key][language].indexOf(slug) != -1
      }
    })

    // If there is a match just use the uid, later it will be resolved ;-=
    if (matchingKey) to = matchingKey
  }

  const link = i18n.resolveUrl(to, urlLanguage)
  if (finalLinkUpdateCallback) finalLinkUpdateCallback(link)

  if (onClickDoNothing) {
    const match = useMatch(link)
    const location = useLocation()
    // console.log("Link match? ", location.pathname, link, location.pathname.indexOf(link), match)
    return (
      <div
        className={className + (partiallyActive ? (location.pathname.indexOf(link) == 0 ? " active" : "") : match != null ? " active" : "")}
        onClick={(e) => {
          onClick(e)
        }}
      >
        {children}
      </div>
    )
  }

  if (target || link.indexOf("://") != -1)
    return (
      <a
        {...rest}
        className={className}
        href={link}
        hrefLang={urlLanguage}
        onClick={(e) => {
          if (language) {
            localStorage.setItem(LANGUAGE_KEY, language)
          }
          if (onClick) {
            onClick(e)
          }
        }}
        target={target || "_blank"}
      >
        {children}
      </a>
    )

  return (
    // @ts-ignore
    <GatsbyLink
      {...rest}
      className={className}
      to={link}
      hrefLang={urlLanguage}
      onClick={(e) => {
        if (language) {
          localStorage.setItem(LANGUAGE_KEY, language)
        }
        if (onClick) {
          onClick(e)
        }
      }}
      activeClassName="active"
      partiallyActive={partiallyActive == undefined ? true : partiallyActive}
    >
      {children}
    </GatsbyLink>
  )
}
