import React from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image/withIEPolyfill"
import { GatsbyImageProps } from "gatsby-image"
import { IconData, LinkData } from "../../rows/DynamicRow"
import Icon from "./Icon"
import { Link } from "../../i18n"

export type VideoTypes =
  | "fullwidth"
  | "square-styled"
  | "cover"
  | "contain"
  | "inside-arrow"
  | "inside-arrow-styled"
  | "inside-arrow-full-styled"
  | "above-arrow-square-styled"
  | "below-arrow-styled"
// https://www.gatsbyjs.com/plugins/gatsby-image/#gatsby-image-props

export type VideoProps = {
  cdnVideo?: string
  youtubeVideo?: string
  style?: any
  className?: string
}

export const Video: React.FC<VideoProps> = (props) => {
  if (props.cdnVideo) {
    return (
      // <div className={props.className} style={{ ...props.style, width: "100%", paddingTop: "56%", position: "relative" }}>
      <video className={props.className} style={{ ...props.style, width: "100%", position: "relative" }} autoPlay={false} controls key={props.cdnVideo}>
        <source src={props.cdnVideo} type="video/mp4"></source>
        Your browser does not support the video tag
      </video>
      // </div>
    )
  } else {
    return (
      <div className={props.className} style={{ ...props.style, width: "100%", paddingTop: "56%", position: "relative" }}>
        <iframe
          src={props.youtubeVideo}
          // title={videoTitle}
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          frameBorder="0"
          allowFullScreen
          style={{ position: "absolute", top: "0", width: "100%", height: "100%" }}
        />
      </div>
    )
  }
}

export default Video
