export function accessibleRouteChangeHandler() {
  return window.setTimeout(() => {
    const mainContainer = document.getElementById("primary-app-container")
    if (mainContainer) {
      mainContainer.focus()
    }
  }, 50)
}

export const checkSingleRequirement = (requiredRole: string, isLoggedIn: boolean, roles: any): boolean => {
  // Logged in or not checks
  if (requiredRole === "AUTH") return isLoggedIn
  if (requiredRole === "NOAUTH") return !isLoggedIn
  // Check simple roles
  if (roles.indexOf(requiredRole) !== -1) return true
  // If Admin always true except for SuperAdmin
  if (requiredRole !== "SuperAdmin" && roles.indexOf("Admin") !== -1) return true
  // SuperAdmin always true
  if (roles.indexOf("SuperAdmin") !== -1) return true

  return false
}

/**
 * Given a required role (or a list of them), a boolean indicating if the user is logged in, and his roles
 * determine if the user is authorized
 * @param requires
 * @param isLoggedIn
 * @param roles
 */
export const checkIfAuthorized = (
  requires: undefined | string | string[],
  isLoggedIn: boolean,
  roles: any
): boolean => {
  const toBeRendered = requires
    ? Array.isArray(requires)
      ? requires.reduce((val, req) => val || checkSingleRequirement(req, isLoggedIn, roles), false)
      : checkSingleRequirement(requires, isLoggedIn, roles)
    : true

  return toBeRendered
}

var htmlEntities = {
  nbsp: " ",
  cent: "¢",
  pound: "£",
  yen: "¥",
  euro: "€",
  copy: "©",
  reg: "®",
  lt: "<",
  gt: ">",
  quot: '"',
  amp: "&",
  apos: "'",
}

export const decodeHTMLEntities = (str) => {
  return str.replace(/\&([^;]+);/g, function (entity, entityCode) {
    var match

    if (entityCode in htmlEntities) {
      return htmlEntities[entityCode]
      /*eslint no-cond-assign: 0*/
    } else if ((match = entityCode.match(/^#x([\da-fA-F]+)$/))) {
      return String.fromCharCode(parseInt(match[1], 16))
      /*eslint no-cond-assign: 0*/
    } else if ((match = entityCode.match(/^#(\d+)$/))) {
      return String.fromCharCode(~~match[1])
    } else {
      return entity
    }
  })
}
