import React, { useContext } from "react"
import { useStaticQuery, graphql } from "gatsby"
import { Helmet, useI18next } from "../i18n"
import { SeoContext } from "./"

import { helmetJsonLdProp } from "react-schemaorg"

/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

export const Seo = (props: {
  title: string
  description?: string
  image?: string
  author?: string
  isArticle?: boolean
  noindex?: boolean
}) => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            siteUrl
          }
        }
      }
    `
  )

  const { schema } = useContext(SeoContext)
  const { path } = useI18next()

  props = {
    author: site.siteMetadata.author,
    isArticle: false,
    ...props,
  }

  const metaDescription = props.description || site.siteMetadata.description

  const siteUrl = site.siteMetadata.siteUrl
  const pageUrl = site.siteMetadata.siteUrl + path

  // TODO: implement schema.org
  // reference: https://schema.org/
  // reference: https://github.com/jlengstorf/gatsby-theme-jason-blog/blob/master/src/components/SEO/SEO.js
  // reference: https://yoast.com/structured-data-schema-ultimate-guide/#utm_source=yoast-seo&utm_medium=software&utm_term=info-schema-metabox&utm_content=schema-settings&utm_campaign=wordpress-general

  // Add " - Ecoverso" to title if missing
  props.title = props.title.indexOf("- Ecoverso") == -1 ? props.title + " - Ecoverso" : props.title

  //console.log("Helmet:", schema)

  return (
    <React.Fragment>
      <Helmet
        script={[
          // Uniamo tutti i JsonLd come se fossero un array anziché creare diversi tag <script...
          Object.keys(schema)
            .map((key) => helmetJsonLdProp(schema[key]))
            .reduce(
              (prev, curr) => {
                return {
                  type: "application/ld+json",
                  innerHTML: (
                    prev.innerHTML.substr(0, prev.innerHTML.length - 1) +
                    (prev.innerHTML.length > 2 ? "," : "") +
                    curr.innerHTML +
                    "]"
                  )
                    .replace(/\{siteUrl\}/g, siteUrl)
                    .replace(/\{pageUrl\}/g, pageUrl),
                }
              },
              { type: "application/ld+json", innerHTML: "[]" }
            ),
        ]}
        // titleTemplate={`%s | ${site.siteMetadata.title}`}
      >
        {/* General tags */}
        <title>{props.title}</title>
        {props.description ? <meta name="description" content={props.description} /> : null}
        {props.image ? <meta name="image" content={props.image} /> : null}

        {/* OpenGraph tags */}
        <meta property="og:type" content={props.isArticle ? "article" : "website"} />
        <meta property="og:title" content={props.title} />
        {props.description ? <meta property="og:description" content={props.description} /> : null}
        {props.image ? <meta property="og:image" content={props.image} /> : null}

        {/* Twitter Card tags */}
        <meta name="twitter:card" content={props.image ? "summary_large_image" : "summary"} />
        <meta name="twitter:creator" content={props.author} />
        <meta name="twitter:title" content={props.title} />
        {props.description ? <meta name="twitter:description" content={props.description} /> : null}
        {props.image ? <meta name="twitter:image" content={props.image} /> : null}

        {props.noindex ? <meta name="robots" content="noindex" /> : null}
      </Helmet>
    </React.Fragment>
  )
}

export default Seo
