import React, { useEffect, useRef } from "react"

// a custom hook for setting the page title
export function useDocumentTitle(title: string) {
  useEffect(() => {
    const originalTitle = document.title
    document.title = title

    return () => {
      document.title = originalTitle
    }
  }, [title])
}

export const useComponentWillMount = (func) => {
  const willMount = useRef(true)

  if (willMount.current) {
    func()
  }

  willMount.current = false
}
