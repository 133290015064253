import { Storage } from "aws-amplify"
import { format } from "date-fns"

// Resolve object property dinamically
// https://stackoverflow.com/questions/4244896/dynamically-access-object-property-using-variable
export function resolve(path, obj) {
  return path.split(".").reduce(function (prev, curr) {
    return prev ? prev[curr] : null
  }, obj)
}

export const handleUploadsAndSubstituteToURLs = async (values: any, setProgress: Function) => {
  let filesTotalSize = 0
  let filesUploadedSize = 0
  const arrayOfPromises = []

  const parseObject = async (obj, justCalculate = true) => {
    const now = format(new Date(), "yyyyMMddHHmmss")

    const keys = Object.keys(obj)
    for (let i = 0; i < keys.length; i++) {
      let key = keys[i]
      //console.log(key, obj[key], typeof obj[key])
      if (Array.isArray(obj[key])) {
        // could be files?
        for (let j = 0; j < obj[key].length; j++) {
          let possibleFile = obj[key][j]
          if (possibleFile instanceof File) {
            if (justCalculate) {
              filesTotalSize += possibleFile.size
            } else {
              let fileSize = possibleFile.size
              // Upload file to users folder in AWS S3
              obj[key][j] = await Storage.put(now + "/" + possibleFile.name, possibleFile, {
                progressCallback(progress) {
                  setProgress(Math.ceil(((filesUploadedSize + progress.loaded) / filesTotalSize) * 100))
                },
                level: "protected",
                contentType: possibleFile.type,
              })
              filesUploadedSize += fileSize
            }
          }
        }
      } else if (typeof obj[key] == "object" && obj[key] != null) await parseObject(obj[key], justCalculate)
    }
  }

  await parseObject(values)
  //console.log("Total to upload: ", filesTotalSize)
  await parseObject(values, false)
  return values
}

export const capitalizeWords = (text: string) => (text ? text.toLowerCase().replace(/\b\w/g, (l) => l.toUpperCase()) : "")
