import React from "react"

import fetch from "cross-fetch"

// AWS Amplify
import { Auth } from "aws-amplify"
import awsconfig from "./aws-exports"

// Apollo client & libraries
import { createAuthLink, AUTH_TYPE } from "aws-appsync-auth-link"
import { createSubscriptionHandshakeLink } from "aws-appsync-subscription-link"

// Dirty fix from: https://github.com/awslabs/aws-mobile-appsync-sdk-js/issues/525#issuecomment-625145739
import { ApolloLink as WrongApolloLink } from "apollo-link"

import { ApolloClient, ApolloLink, createHttpLink, InMemoryCache, ApolloProvider } from "@apollo/client"

let publicAppsyncClient: any
let privateAppsyncClient: any

export const ApolloWrapper = ({ children }) => {
  // --------------------------------------------------------
  // Setup Apollo AWSAppSync Client
  // https://github.com/awslabs/aws-mobile-appsync-sdk-js#using-authorization-and-subscription-links-with-apollo-client-no-offline-support
  // https://github.com/awslabs/aws-mobile-appsync-sdk-js#readme

  const url = awsconfig.aws_appsync_graphqlEndpoint
  const region = awsconfig.aws_appsync_region
  const auth = {
    type: AUTH_TYPE.AMAZON_COGNITO_USER_POOLS,
    jwtToken: async () => (await Auth.currentSession()).getIdToken().getJwtToken(),
  }

  //const wssUrl = url.replace("http", "wss").replace("appsync-api", "appsync-realtime-api")
  const httpLink = createHttpLink({ uri: url })

  const link = ApolloLink.from([createAuthLink({ url, region, auth }), createSubscriptionHandshakeLink(url, httpLink)])

  privateAppsyncClient = new ApolloClient({
    link,
    cache: new InMemoryCache(),
  })

  // --------------------------------------------------------

  return <ApolloProvider client={privateAppsyncClient}>{children}</ApolloProvider>
}

export default ApolloWrapper

//------------------------------------------------------------------------------------------------------
// Secondary client with IAM Authentication
// Necessary for unauthenticated access to API via IAM -> UnauthRole
// Read: https://aws.amazon.com/blogs/mobile/using-multiple-authorization-types-with-aws-appsync-graphql-apis/

const createApolloSecondaryIAMClient = () => {
  const url = awsconfig.aws_appsync_graphqlEndpoint
  const region = awsconfig.aws_appsync_region
  const auth = {
    type: AUTH_TYPE.AWS_IAM,
    credentials: async () => await Auth.currentCredentials(),
  }

  //const wssUrl = url.replace("http", "wss").replace("appsync-api", "appsync-realtime-api")
  const httpLink = createHttpLink({ uri: url })

  const link = ApolloLink.from([createAuthLink({ url, region, auth }), createSubscriptionHandshakeLink(url, httpLink)])

  publicAppsyncClient = new ApolloClient({
    link,
    cache: new InMemoryCache(),
  })

  // --------------------------------------------------------

  return publicAppsyncClient
}

export const PublicAppsyncContext = React.createContext(createApolloSecondaryIAMClient())

//------------------------------------------------------------------------------------------------------
// Context that can return both clients depending on logged in status

export const getAppSyncClientContext = React.createContext((isLoggedIn: boolean) => {
  return isLoggedIn ? privateAppsyncClient : publicAppsyncClient
})

//------------------------------------------------------------------------------------------------------
// Other client to Wordpress
// Authorization: "Basic ZWNvdmVyc286NjNLeWdPJllOdW5pUEg0VyZlOHUjIzE2", // "Basic ecoverso:63KygO&YNuniPH4W&e8u##16"

const wordpressClient = new ApolloClient({
  link: createHttpLink({
    uri: "https://cms.ecoverso.org/graphql",
    headers: {
      //Authorization: "Basic ZWNvdmVyc286NjNLeWdPJllOdW5pUEg0VyZlOHUjIzE2",
    },
    fetch,
  }),
  cache: new InMemoryCache(),
})

export const WPContext = React.createContext(wordpressClient)
