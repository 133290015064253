import React, { useState } from "react"

import RealAnimatedNumber from "react-animated-numbers"
import { InView } from "react-intersection-observer"

type props = {
  number: number
  prefix?: string
  suffix?: string
  className?: string
  style?: any
}

const AnimatedNumber: React.FC<props> = (props) => {
  const [isVisible, setIsVisible] = useState(false)

  return (
    <InView>
      {({ inView, ref, entry }) => {
        if (inView && !isVisible) setIsVisible(true)
        return (
          <div className="card__numbers-title mb-xs" ref={ref}>
            {props.prefix}
            {isVisible ? (
              <RealAnimatedNumber
                // fontStyle={{ fontFamily: "Beatrice", fontSize: "6.4rem", color: "#163df7", lineHeight: "7rem" }}
                animateToNumber={props.number}
                // includeComma
                config={{ tension: 89, friction: 40 }}
                // onStart={() => console.log("onStart")}
                // onFinish={() => console.log("onFinish")}
                animationType={"calm"}
              />
            ) : null}
            <span className="card__numbers-title-suffix">{props.suffix}</span>
          </div>
        )
      }}
    </InView>
  )
}

export default AnimatedNumber
