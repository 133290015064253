import Amplify, { Analytics } from "aws-amplify"
import currentEnv from "../../currentEnv"

export type EndpointLocation = {
  Latitude?: number
  Longitude?: number
  PostalCode?: string
  City?: string
  Region?: string
  Country?: string
}

export type EndpointDemographic = {
  Make?: string
  Model?: string
  ModelVersion?: string
  Timezone?: string
  Locale?: string
  AppVersion?: string
  Platform?: string
  PlatformVersion?: string
}

export type EndpointRequest = {
  ChannelType?: "EMAIL" | "SMS"
  Address?: string
  EndpointStatus?: "ACTIVE" | "INACTIVE"
  OptOut?: "ALL" | "NONE"
  RequestId?: string
  Location?: EndpointLocation
  Demographic?: EndpointDemographic
  EffectiveDate?: string
  Attributes?: {
    [k: string]: string[]
  }
  Metrics?: {}
  UserId?: string
  UserAttributes?: {
    [k: string]: string[]
  }
  ApplicationId?: string
  Id?: string
  CohortId?: string
  CreationDate?: string
}

/**
 * https://docs.amplify.aws/lib/analytics/autotrack/q/platform/js
 */
export const analyicsSetup = () => {
  // Session tracking enabled by default

  // Save current environment
  Analytics.updateEndpoint({ attributes: { env: [currentEnv] } }).catch((e) => {
    console.log("Pinpoint error:", e)
  })

  /**
   * Page View Tracking
   * If you want to track which page/url in your webapp is the most frequently viewed one, you can use this feature.
   * It will automatically send events containing url information when the page is visited.
   */
  Analytics.autoTrack("pageView", {
    // REQUIRED, turn on/off the auto tracking
    enable: true,
    // OPTIONAL, the event name, by default is 'pageView'
    eventName: "pageView",
    // OPTIONAL, the attributes of the event, you can either pass an object or a function
    // which allows you to define dynamic attributes
    // attributes: {
    //     attr: 'attr'
    // },
    // when using function
    // attributes: () => {
    //    const attr = somewhere();
    //    return {
    //        myAttr: attr
    //    }
    // },
    // OPTIONAL, by default is 'multiPageApp'
    // you need to change it to 'SPA' if your app is a single-page app like React
    type: "SPA",
    // OPTIONAL, the service provider, by default is the Amazon Pinpoint
    provider: "AWSPinpoint",
    // OPTIONAL, to get the current page url
    // getUrl: () => {
    //    // the default function
    //     return window.location.origin + window.location.pathname;
    // }
  })

  /**
   * Page Event Tracking
   * If you want to track user interactions with elements on the page, you can use this feature.
   * All you need to do is attach the specified selectors to your dom element and turn on the auto tracking.
   *
   * <!-- you want to track this button and send an event when it is clicked -->
   * <button
   *    data-amplify-analytics-on='click'
   *    data-amplify-analytics-name='click'
   *    data-amplify-analytics-attrs='attr1:attr1_value,attr2:attr2_value'
   * />
   *
   * When the button above is clicked, an event will be sent automatically and this is equivalent to do:
   * <script>
   *    var sendEvent = function() {
   *      Analytics.record({
   *        name: 'click',
   *        attributes: {
   *          attr: 'attr', // the default ones
   *          attr1: attr1_value, // defined in the button component
   *          attr2: attr2_value, // defined in the button component
   *        }
   *      });
   *    }
   * </script>
   * <button onclick="sendEvent()"/>
   */
  // Analytics.autoTrack("event", {
  //   // REQUIRED, turn on/off the auto tracking
  //   enable: true,
  //   // OPTIONAL, events you want to track, by default is 'click'
  //   events: ["click"],
  //   // OPTIONAL, the prefix of the selectors, by default is 'data-amplify-analytics-'
  //   // in order to avoid collision with the user agent, according to https://www.w3schools.com/tags/att_global_data.asp
  //   // always put 'data' as the first prefix
  //   selectorPrefix: "data-amplify-analytics-",
  //   // OPTIONAL, the service provider, by default is the Amazon Pinpoint
  //   provider: "AWSPinpoint",
  //   // OPTIONAL, the default attributes of the event, you can either pass an object or a function
  //   // which allows you to define dynamic attributes
  //   attributes: {
  //     attr: "attr",
  //   },
  //   // when using function
  //   // attributes: () => {
  //   //    const attr = somewhere();
  //   //    return {
  //   //        myAttr: attr
  //   //    }
  //   // }
  // })
}
