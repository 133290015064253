import { Namespace, useTranslation, UseTranslationOptions } from "react-i18next"
import { useContext } from "react"
import { navigate as gatsbyNavigate } from "gatsby"
import { I18nextContext } from "./i18nextContext"
import { NavigateOptions } from "@reach/router"
import { LANGUAGE_KEY } from "./types"

declare var __BASE_PATH__: string | undefined
declare var __PATH_PREFIX__: string | undefined

export const useI18next = (ns?: Namespace, options?: UseTranslationOptions) => {
  const { i18n, t, ready } = useTranslation(ns, options)
  const context = useContext(I18nextContext)

  const { routed, defaultLanguage } = context

  const getLanguagePath = (language: string) => {
    return language !== defaultLanguage ? `/${language}` : ""
  }

  const removePrefix = (pathname: string) => {
    const base = typeof __BASE_PATH__ !== `undefined` ? __BASE_PATH__ : __PATH_PREFIX__
    if (base && pathname.indexOf(base) === 0) {
      pathname = pathname.slice(base.length)
    }
    return pathname
  }

  const removeLocalePart = (pathname: string) => {
    if (!routed) return pathname
    const i = pathname.indexOf(`/`, 1)
    return pathname.substring(i)
  }

  const navigate = (to: string, options?: NavigateOptions<{}>) => {
    // const languagePath = getLanguagePath(context.language)
    // const link = routed ? `${languagePath}${to}` : `${to}`
    const link = resolveUrl(to)
    return gatsbyNavigate(link, options)
  }

  /**
   * Resolves a destination with i18n
   * @param to URL or UID (if null = current page)
   * @param language desired language (if null = current language)
   */
  const resolveUrl = (to?: string, language?: string) => {
    to = to || context.uid || context.originalPath || removeLocalePart(removePrefix(window?.location.pathname))
    language = language || context.language

    const [url, anchor] = to.split("#", 2)
    const [realTo, query] = url.split("?", 2)

    // console.log(to, url, realTo, query, anchor)

    if (context.uidPagesMap[realTo]) {
      return (
        (context.uidPagesMap[realTo][language] || context.uidPagesMap[realTo][context.defaultLanguage]) +
        (query ? "?" + query : "") +
        (anchor ? "#" + anchor : "")
      )
    } else {
      return getLanguagePath(language) + to
    }
  }

  const changeLanguage = (language: string, to?: string, options?: NavigateOptions<{}>) => {
    // const languagePath = getLanguagePath(language)
    // const pathname = to || removeLocalePart(removePrefix(window.location.pathname))
    // let link = `${languagePath}${pathname}`

    // // Check current page has an UID
    // if (context.uid && context.uidPagesMap[context.uid]) {
    //   link = context.uidPagesMap[context.uid][language] || context.uidPagesMap[context.uid][context.defaultLanguage]
    // }

    // // Add query string
    // link = link + window.location.search

    localStorage.setItem(LANGUAGE_KEY, language)
    return gatsbyNavigate(resolveUrl(to, language) + window.location.search, options)
  }

  return {
    ...context,
    i18n,
    t,
    ready,
    resolveUrl,
    navigate,
    changeLanguage,
  }
}
