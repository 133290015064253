import React from "react"
import { state } from "../forms/fields/user"

type Props = {
  price: number | string
  currency?: string
  decPlaces?: number
  decSep?: string
  thouSep?: string
  className?: string
  style?: any
  onMouseEnter?: any
  onMouseLeave?: any
  onFocus?: any
  onClick?: any
}

export const FormatPrice: React.FC<Props> = ({ price, className, currency, decPlaces, decSep, thouSep, ...props }) => {
  currency = currency || "€"
  decPlaces = decPlaces == undefined ? 2 : decPlaces
  decSep = decSep || ","
  thouSep = thouSep || "."

  return (
    <span className={"formattedPrice " + className} {...props}>
      {formatPrice(price, decPlaces, decSep, thouSep)}
      {currency}
    </span>
  )
}

export const formatPrice = (number, decPlaces?: number, decSep?: string, thouSep?: string) => {
  decPlaces = decPlaces == undefined ? 2 : decPlaces
  decSep = decSep || ","
  thouSep = thouSep || "."
  var sign = number < 0 ? "-" : ""
  var i = String(parseInt((number = Math.abs(Number(number) || 0).toFixed(decPlaces))))
  var j = (j = i.length) > 3 ? j % 3 : 0

  return (
    sign +
    (j ? i.substr(0, j) + thouSep : "") +
    i.substr(j).replace(/(\decSep{3})(?=\decSep)/g, "$1" + thouSep) +
    (decPlaces
      ? decSep +
        Math.abs(number - i)
          .toFixed(decPlaces)
          .slice(2)
      : "")
  )
}

export default FormatPrice
