/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */
import React, { Suspense } from "react"
// import "./src/i18n"
import { Provider } from "react-redux"

import store from "./src/store"
import { checkIfLoggedIn } from "./src/store/actions"

// Stripe.js
import "@stripe/stripe-js"

// AWS Amplify
import Amplify from "aws-amplify"
import awsconfig from "./src/aws-exports"
import * as MyAmplify from "./src/amplify"

import ApolloWrapper from "./src/ApolloWrapper"

// Internationalization
import { i18nWrapPageElement } from "./src/i18n/plugin/wrapPageElement"

// Layout
import "./src/Layout/fontAwesomeLibrary"
import { Loading } from "./src/Layout/Components"

// Setup Amplify with Fixed & Cleaned awsconfig
Amplify.configure(MyAmplify.configFix(awsconfig))
MyAmplify.analyicsSetup()

store.dispatch(checkIfLoggedIn())

// eslint-disable-next-line react/display-name,react/prop-types
export const wrapRootElement = ({ element }) => {
  // Instantiating store in `wrapRootElement` handler ensures:
  //  - there is fresh store for each SSR page
  //  - it will be called only once in browser, when React mounts
  return (
    <Suspense fallback={<Loading fixed />}>
      <ApolloWrapper>
        <Provider store={store}>{element}</Provider>
      </ApolloWrapper>
    </Suspense>
  )
}

export const wrapPageElement = i18nWrapPageElement

// export const onRouteUpdate = (state, page, pages) => {
//   checkLogin(store.dispatch)
//   store.dispatch(checkIfLoggedIn())
// }
