import React, { useReducer, createContext } from "react"
import { Thing, WithContext } from "schema-dts"

export type structuredDataType = WithContext<Thing>
export type structuredDataArrayType = structuredDataType[]

type seoContextType = {
  schema: { [x: number]: WithContext<Thing> | { property: string; data: Thing } }
  addData: React.Dispatch<any>
}

export const SeoContext = createContext<seoContextType>({ schema: {}, addData: () => {} })

const seoReducer = (state, newValue) => {
  return { ...state, ...newValue }
}

export const SeoContextProvider: React.FC<{ children: any }> = ({ children }) => {
  const [schema, addData] = useReducer(seoReducer, [])

  return <SeoContext.Provider value={{ schema, addData }}>{children}</SeoContext.Provider>
}

export default SeoContextProvider
