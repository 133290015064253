import * as actionType from "../actions/actionTypes"

const initialState: {
  lastStatus: string
  lastLoginEmail: string
  isLoggedIn: boolean
  error: any
  errorMessage: string
  errorCode: string
  token: string
  payload: any
  cognitoUser: any
} = {
  lastStatus: "",
  lastLoginEmail: "",
  isLoggedIn: false,
  error: null,
  errorMessage: "",
  errorCode: "",
  token: "",
  payload: {},
  cognitoUser: null,
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionType.AUTH_RESET:
      return initialState
    // Save current action type as lastStatus
    case actionType.AUTH_START:
      return {
        ...initialState,
        lastStatus: action.type,
        lastLoginEmail: action.email,
      }
    case actionType.AUTH_CHALLENGE:
    case actionType.AUTH_CHALLENGE_START:
    case actionType.AUTH_CHALLENGE_FAIL:
      return {
        ...state,
        lastStatus: action.type,
        errorMessage: action.errorMessage || "",
      }
    case actionType.AUTH_SUCCESS:
      return {
        ...state,
        lastStatus: action.type,
        isLoggedIn: true,
        token: action.token,
        payload: action.payload,
      }
    case actionType.AUTH_FAIL:
      return {
        ...state,
        lastStatus: action.type,
        error: action.error,
        errorMessage: action.errorMessage,
        isLoggedIn: false,
      }
    case actionType.AUTH_END:
      return {
        ...initialState,
        lastStatus: action.type,
      }
    case actionType.REGISTRATION_START:
      return {
        ...state,
        lastStatus: action.type,
        lastLoginEmail: action.email,
      }
    case actionType.REGISTRATION_FAIL:
      return {
        ...state,
        lastStatus: action.type,
        error: action.error || "",
        errorCode: action.errorCode || "",
        errorMessage: action.errorMessage || "",
      }
  }

  return state
}

export default reducer
