import * as actionTypes from "./actionTypes"
import { INotification } from "../../Layout/Notifications"

// Synchronous Action Creators

export const loadingOn = () => ({
  type: actionTypes.LOADING_ON,
})

export const loadingOff = () => ({
  type: actionTypes.LOADING_OFF,
})

export const setLoginModalVisibility = (visible: boolean) => ({
  type: actionTypes.LOGIN_MODAL_VISIBILITY,
  visible,
})

export const notificationAdd = (notification: INotification) => ({
  type: actionTypes.NOTIFICATION_ADD,
  notification,
})

export const notificationEmpty = () => ({
  type: actionTypes.NOTIFICATION_EMPTY,
})
