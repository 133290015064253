import React, { useContext } from "react"
import { I18nextContext } from "./i18nextContext"
import { format as originalFormat, parse, parseISO } from "date-fns"

import { enUS, it } from "date-fns/locale"
const locales = { it, en: enUS }

/**
 * Format the date (also with i18n)
 * For formats see: https://date-fns.org/v2.16.1/docs/format
 * For parsing see: https://date-fns.org/v2.16.1/docs/parse
 *
 * @param props
 */

export const FormatDate = (props: {
  date: Date | string | undefined
  format?: string
  parseFormat?: string
  language?: string
  ifNotValid?: string
  prefix?: string
  postfix?: string
  className?: string
  style?: any
}) => {
  const context = useContext(I18nextContext)

  props = {
    format: "d MMMM yyyy", // https://date-fns.org/v2.16.1/docs/format
    parseFormat: null, // https://date-fns.org/v2.16.1/docs/parse
    language: context.language,
    ifNotValid: "-",
    prefix: "",
    postfix: "",
    ...props,
  }

  const inputDate = props.date
  let formattedDate = props.ifNotValid

  try {
    switch (typeof props.date) {
      case "string":
        props.date = props.parseFormat ? parse(props.date, props.parseFormat, new Date()) : parseISO(props.date)
      case "object":
        if (props.date !== null) {
          formattedDate = props.prefix + originalFormat(props.date, props.format, { locale: locales[props.language] }) + props.postfix
        }
    }
  } catch (e) {
    //console.log("Format date error: ", props.date, inputDate)
  }

  return (
    <span className={"formattedDate " + props.className} style={props.style}>
      {formattedDate}
    </span>
  )
}

export default FormatDate
