import React, { useEffect, useContext } from "react"

import { useStaticQuery, graphql } from "gatsby"

import { BreadcrumbList, WithContext } from "schema-dts"
import { Link, useI18next } from "../../i18n"
import { StructuredData } from "../../seo"
import { decodeHTMLEntities } from "../utils/utils"

type Props = {
  data: { to?: string; label: string }[]
}

export const Breadcrumbs: React.FC<Props> = ({ data }) => {
  const i18n = useI18next()

  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            siteUrl
          }
        }
      }
    `
  )

  const structuredData: WithContext<BreadcrumbList> = {
    "@context": "https://schema.org",
    "@type": "BreadcrumbList",
    itemListElement: data.map((breadcrumb, i) => {
      return {
        "@type": "ListItem",
        position: i,
        name: breadcrumb.label,
        item: site.siteMetadata.siteUrl + i18n.resolveUrl(breadcrumb.to),
      }
    }),
  }

  return (
    <div className="row row--full breadcrumbs">
      <div className="row row--center row--no-grid">
        <StructuredData data={structuredData}>
          {data.map((breadcrumb, i) => (
            <Link to={breadcrumb.to} partiallyActive={false} key={i}>
              {decodeHTMLEntities(breadcrumb.label)}
            </Link>
          ))}
          {/* <StructuredData property="itemListElement" data={{ "@type": "ListItem", position: 4, name: "test" }} />
          <StructuredData property="other" data={{ "@type": "ListItem", position: 4, name: "test" }} />
          <StructuredData property="other" data={{ "@type": "ListItem", position: 5, name: "test5" }} /> */}
        </StructuredData>
      </div>
    </div>
  )
}

export default Breadcrumbs
