import * as actionType from "../actions/actionTypes"
import { INotification } from "../../Layout/Notifications"
import { BrandData, SegmentsData, TechnologyData } from "../../pages/auto"

const initialState: {
  selectedBrands: BrandData[]
  selectedSegments: SegmentsData[]
  selectedTechnologies: TechnologyData[]
  selectedPriceRange: [number, number]
} = {
  selectedBrands: [],
  selectedSegments: [],
  selectedTechnologies: [],
  selectedPriceRange: [0, actionType.SET_SELECTED_PRICE_RANGE_MAX],
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionType.SET_SELECTED_BRANDS:
      return {
        ...state,
        selectedBrands: action.selectedBrands,
      }
    case actionType.SET_SELECTED_SEGMENTS:
      return {
        ...state,
        selectedSegments: action.selectedSegments,
      }
    case actionType.SET_SELECTED_TECHNOLOGIES:
      return {
        ...state,
        selectedTechnologies: action.selectedTechnologies,
      }
    case actionType.SET_SELECTED_PRICE_RANGE:
      return {
        ...state,
        selectedPriceRange: action.selectedPriceRange,
      }
  }

  return state
}

export default reducer
