import currentEnv from "../../currentEnv"

export const configFix = (config) => {
  // Filter redirectSignIn/Out URLs depending on hostname
  // config.oauth.redirectSignIn = config.oauth.redirectSignIn
  //   .split(",")
  //   .reduce((finalUrl, url) => (url.indexOf(window.location.hostname) !== -1 ? url : finalUrl), "")
  // config.oauth.redirectSignOut = config.oauth.redirectSignOut
  //   .split(",")
  //   .reduce((finalUrl, url) => (url.indexOf(window.location.hostname) !== -1 ? url : finalUrl), "")

  // Change Auth URL to custom domain
  // const customDomains = [
  //   { env: "dev", domain: "auth.dev.ecoverso.org" },
  //   { env: "prod", domain: "auth.ecoverso.org" },
  // ]

  // customDomains.forEach(({ env, domain }) => {
  //   if (config.oauth.domain.indexOf(env) !== -1) config.oauth.domain = domain
  // })

  // Manually add the same Pinpoint
  config["aws_mobile_analytics_app_id"] = currentEnv == "prod" ? "7dcd43afe9874cfd8e19a4c786402b03" : "523232ba03984308ad7b7bdd00480710"
  config["aws_mobile_analytics_app_region"] = "eu-west-1"

  return config
}

export * from "./analytics"
