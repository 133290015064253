import React, { useContext, useEffect, useState } from "react"

import { useTranslation } from "../../i18n"
import { useSelector, useDispatch } from "react-redux"
import * as actions from "../../store/actions"
import { useMutation } from "@apollo/client"
import gql from "graphql-tag"

import { getAppSyncClientContext } from "../../ApolloWrapper"

import FormGenerator from "../Forms/FormGenerator"
import { Loading } from "."
import { createFormSubmission } from "../../graphql/mutations"

import {
  firstName,
  lastName,
  email,
  mobile,
  message,
  declaredOrigin,
  discountCode,
  readPrivacy,
  newsletterInfo,
  acptNewsletter,
  marketingInfo,
  acptMarketing,
  profilingInfo,
  acptProfiling,
} from "../../forms/fields/user"

let subscribeFormSetValues = null
let subscribeFormReset = null

type Props = {
  fields?: string[]
  formName?: string
  submitButtonText?: string
  defaultToTrue?: {
    acptNewsletter?: boolean
    acptMarketing?: boolean
    acptProfiling?: boolean
  }
}

export const Form: React.FC<Props> = (props) => {
  props = {
    formName: "newsletter",
    submitButtonText: "Richiedi informazioni",
    defaultToTrue: {
      acptNewsletter: false,
      acptMarketing: false,
      acptProfiling: false,
    },
    fields: ["firstName", "email", "readPrivacy"],
    ...props,
  }

  const [t] = useTranslation()
  const dispatch = useDispatch()

  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn)
  const storedEmail = useSelector((state) => state.auth.payload.userData?.email)
  const storedFirstName = useSelector((state) => state.auth.payload.userData?.firstName)
  const storedLastName = useSelector((state) => state.auth.payload.userData?.lastName)

  const [loading, setLoading] = useState(false)

  // --- NEW formSubmission ---------------------------------------------------------------------------------------------
  const getAppsyncClient = useContext(getAppSyncClientContext)
  const [submitForm, { error: formSubmissionError }] = useMutation(gql(createFormSubmission), {
    client: getAppsyncClient(isLoggedIn),
  })

  if (formSubmissionError) {
    dispatch(actions.notificationAdd({ message: t("Form Submission Error"), variant: "DangerIcon" }))
    console.log("Form Submission Error", formSubmissionError)
    return null
  }

  // --- BUILD FORM -----------------------------------------------------------------------------------------------------

  const subscribeForm = {}

  if (props.fields.includes("lastName")) {
    subscribeForm["row1"] = {
      type: "row",
      properties: {
        firstName: firstName,
        lastName: lastName,
      },
    }
  } else {
    subscribeForm["firstName"] = firstName
  }
  subscribeForm["email"] = email
  if (props.fields.includes("mobile")) subscribeForm["mobile"] = mobile
  if (props.fields.includes("declaredOrigin")) subscribeForm["declaredOrigin"] = declaredOrigin
  if (props.fields.includes("discountCode")) subscribeForm["discountCode"] = discountCode
  if (props.fields.includes("message")) subscribeForm["message"] = message
  subscribeForm["readPrivacy"] = readPrivacy
  if (props.fields.includes("acptNewsletter") && !props.defaultToTrue.acptNewsletter) {
    subscribeForm["newsletterInfo"] = newsletterInfo
    subscribeForm["acptNewsletter"] = acptNewsletter
  }
  if (props.fields.includes("acptMarketing") && !props.defaultToTrue.acptMarketing) {
    subscribeForm["marketingInfo"] = marketingInfo
    subscribeForm["acptMarketing"] = acptMarketing
  }
  if (props.fields.includes("acptProfiling") && !props.defaultToTrue.acptProfiling) {
    subscribeForm["profilingInfo"] = profilingInfo
    subscribeForm["acptProfiling"] = acptProfiling
  }

  // --------------------------------------------------------------------------------------------------------------------

  const onSubmit = (values, setSubmitting) => {
    // Set default values to true
    Object.keys(props.defaultToTrue).forEach((key) => {
      if (props.defaultToTrue[key]) values[key] = 1
    })

    if (values["acptNewsletter"] != undefined) values["acptNewsletter"] = values["acptNewsletter"] ? 1 : 0
    if (values["acptMarketing"] != undefined) values["acptMarketing"] = values["acptMarketing"] ? 1 : 0
    if (values["acptProfiling"] != undefined) values["acptProfiling"] = values["acptProfiling"] ? 1 : 0

    console.log("Form submission: ", values)
    submitForm({
      variables: { createForm_submissionInput: { jsonData: JSON.stringify(values), formName: props.formName } },
    }).then(() => {
      dispatch(
        actions.notificationAdd({
          message:
            "Dati salvati con successo. Se è la prima volta che compili un nostro form, a breve riceverai una mail con un link su cui cliccare per confermare i tuoi dati.",
          variant: "SmileIcon",
          duration: 30,
        })
      )
      setSubmitting(false)
      subscribeFormReset()

      // Send event to GTM
      window?.dataLayer?.push({
        event: "form-submission",
        formName: props.formName,
        acptNewsletter: values["acptNewsletter"],
        acptMarketing: values["acptMarketing"],
        acptProfiling: values["acptProfiling"],
      })
    })
  }

  return (
    <FormGenerator
      className="btn--fullwidth"
      properties={subscribeForm}
      values={{ firstName: storedFirstName, lastName: storedLastName, email: storedEmail }}
      hideCancelButton
      submitButtonText={props.submitButtonText}
      onSubmit={onSubmit}
      bindSetValues={(callback) => (subscribeFormSetValues = callback)}
      bindResertForm={(callback) => (subscribeFormReset = callback)}
      // size="small"
    />
  )
}

export default Form
